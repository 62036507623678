import { API } from 'aws-amplify';

import {
	getAuthHeaders,
	FETCH_NI_PRODUCTION_DATA,
	RESET_NI_PRODUCTION_DATA,
	FETCH_NI_PRODUCTION_DATA_ERR,
	FETCH_NI_PSEUDOGROUP_SMELTERS,
	FETCH_NI_PSEUDOGROUP_SMELTERS_ERR,
	FETCH_NI_PRODUCTION_SMELTERS_DATA,
	FETCH_NI_PRODUCTION_SMELTERS_DATA_ERR,
	SELECT_NI_PROD_SMELTER,
	UNSELECT_NI_PROD_SMELTER,
	RESET_NI_PROD_SMELTERS
} from '../';
import config from '../../config';
import { replacer } from '../../utils';

export const fetchNiProductionData =
	({ regions, ute = '', capAdd = '', period, startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niProduction, {
				regions,
				ute,
				capAdd,
				period,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_NI_PRODUCTION_DATA,
					payload: { [period]: response }
				}),
			(err) =>
				dispatch({
					type: FETCH_NI_PRODUCTION_DATA_ERR,
					payload: err
				})
		);
	};

export const resetNiProductionData = () => {
	return {
		type: RESET_NI_PRODUCTION_DATA
	};
};

export const fetchNiPseudoGroupSmelters =
	({ pg, startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niPseudoGroupSmelters, {
				pg,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_PSEUDOGROUP_SMELTERS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_NI_PSEUDOGROUP_SMELTERS_ERR,
					payload: err
				})
		);
	};

export const resetNiPseudoGroupSmelters = () => ({
	type: FETCH_NI_PSEUDOGROUP_SMELTERS,
	payload: []
});

export const fetchNiProductionSmeltersData =
	({ smelterIds, period, ute = '', startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niProductionSmelters, {
				smelterIds,
				period,
				ute,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_NI_PRODUCTION_SMELTERS_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_NI_PRODUCTION_SMELTERS_DATA_ERR,
					payload: err
				})
		);
	};

export const resetNiProductionSmeltersData = () => ({
	type: FETCH_NI_PRODUCTION_SMELTERS_DATA,
	payload: {}
});

export const selectNiProdSmelter = (key) => ({
	type: SELECT_NI_PROD_SMELTER,
	payload: key
});

export const unselectNiProdSmelter = (key) => ({
	type: UNSELECT_NI_PROD_SMELTER,
	payload: key
});

export const resetNiProdSmelters = () => ({
	type: RESET_NI_PROD_SMELTERS
});
