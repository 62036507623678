import {
	FETCH_NI_PRODUCTION_DATA,
	RESET_NI_PRODUCTION_DATA
} from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_NI_PRODUCTION_DATA:
			return { ...state, ...action.payload };
		case RESET_NI_PRODUCTION_DATA:
			return {};
		default:
			return state;
	}
};
