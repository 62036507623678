import { API } from 'aws-amplify';

import {
	getAuthHeaders,
	FETCH_STEEL_PSEUDOGROUPS,
	FETCH_STEEL_PSEUDOGROUPS_ERR
} from '../';
import config from '../../config';

export const fetchSteelPseudoGroups = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.steelPseudoGroups, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_PSEUDOGROUPS,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_PSEUDOGROUPS_ERR,
				payload: err
			})
	);
};
