import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {
	Card,
	CardBody,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Progress,
	Row,
	UncontrolledDropdown
} from 'reactstrap';
import { Line } from 'react-chartjs-2';
import {
	Cartesian3,
	Color,
	SceneMode,
	ScreenSpaceEventType,
	WebMercatorProjection
} from 'cesium';
import {
	CameraFlyTo,
	Entity,
	Globe,
	ImageryLayer,
	PointGraphics,
	Scene,
	ScreenSpaceCameraController,
	ScreenSpaceEvent,
	ScreenSpaceEventHandler,
	Viewer
} from 'resium';
import ReactGA from 'react-ga';
import moment from 'moment';

import { bingImageryProvider } from 'components';
import { Crumbs } from 'containers';
import {
	getAuthenticatedUserGroups,
	fetchNiSmelterAllMapData,
	fetchNiSmelterData,
	resetNiSmelterData,
	fetchNiSmelterRecentUpdates
} from 'actions';
import { chartStyle, chartOptions } from './smelterDashletChartConfig';
import {
	isUserFree,
	isUserHigherThanFree,
	isUserEnterpriseOrSales
} from '../../../utils';

import '../../Home/Home.scss';
import colours from '../../../_colours.scss';

import dashboardMap from 'images/dashboard_map.png';

const store = require('store');

// Camera view for smelter map
const smelterMapCamera = Cartesian3.fromDegrees(11, 26, 40200000);

const mapProjection = new WebMercatorProjection();

class NiHome extends Component {
	state = {
		isHigerThanFree: false,
		isEnterpriseOrSales: false,
		isFree: false
	};

	async componentDidMount() {
		ReactGA.pageview('/ni/home', null, 'Ni Dashboard');

		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();
		const isEnterpriseOrSales = isUserEnterpriseOrSales(authGroups, 'ni');
		const isHigherThanFree = isUserHigherThanFree(authGroups, 'ni');
		const isFree = isUserFree(authGroups, 'ni');

		this.setState({
			isEnterpriseOrSales,
			isHigherThanFree,
			isFree
		});

		//date ranges per user group are handled by the API side if no dates params given
		if (isFree || isHigherThanFree) {
			//fetch indices only if user has rights for at least on of 'ni' groups
			this.props.fetchNiSmelterData({
				regions: 'global'
			});
		}

		if (isEnterpriseOrSales) {
			this.props.fetchNiSmelterAllMapData();
		}

		if (isHigherThanFree) {
			this.props.fetchNiSmelterRecentUpdates();
		}
	}

	componentWillUnmount() {
		// Set the visual for the home screen copy
		store.set('home-copy-show', true);
		this.props.resetNiSmelterData();
	}

	onInputSelect = ({ target: { id, value } }) => {
		this.setState({
			[id]: value
		});
	};

	buildLoadingMessage() {
		return (
			<Card className="mb-3">
				<CardBody>
					<p className="h6 dash-header">Chart data loading...</p>
					<Progress animated value="100" />
				</CardBody>
			</Card>
		);
	}

	renderSmelterData() {
		const { niSmelterData = {} } = this.props;

		if (!niSmelterData.combine) {
			return this.buildLoadingMessage();
		}

		let datasets = [
			{
				label: 'Global Inactive Capacity Index',
				data: [],
				...chartStyle,
				borderColor: '#FFA500',
				backgroundColor: '#FFA500',
				pointBackgroundColor: '#FFA500'
			}
		];
		niSmelterData.combine.forEach(({ t, f }) => {
			datasets[0].data.push({
				t: moment.utc(t).format('YYYY-MM-DD'),
				y: f
			});
		});

		return (
			<Card className="mb-3">
				<CardBody className="p-2">
					<p className="h6 dash-header">Recent smelter activity</p>
					<NavLink
						to="/ni/smelters"
						className="btn btn-light smaller pull-right"
					>
						Show more
						<i className="fa fa-external-link pl-2" />
					</NavLink>
					<Line data={{ datasets }} options={chartOptions} height={200} />
				</CardBody>
			</Card>
		);
	}

	buildSmelterEntities(niSmelterMapData) {
		if (!niSmelterMapData || !niSmelterMapData.length) {
			return;
		}

		return niSmelterMapData.map(({ name, lon, lat, state, end_date }) => {
			const smelterId = name.replace(/ /g, '').toLowerCase();
			const activityColour = end_date
				? colours.savantSmelterIdentify
				: state === '1'
				? colours.savantSmelterHot
				: colours.savantSmelterCold;
			const colour = Color.fromCssColorString(activityColour);

			return (
				<Entity
					key={smelterId}
					name={name}
					position={Cartesian3.fromDegrees(parseFloat(lon), parseFloat(lat), 1)}
				>
					<PointGraphics color={colour} pixelSize={12} />
				</Entity>
			);
		});
	}

	renderSmelterMap() {
		const { niSmelterAllMapData: smelterMapData = [] } = this.props;

		return (
			<Card className="mb-3 smelter-map-dashlet">
				<CardBody className="p-2">
					{(!smelterMapData || smelterMapData.length === 0) && (
						<div className="no-map text-center">
							<i className="fa fa-map-o fa-5x" />
						</div>
					)}
					{smelterMapData && smelterMapData.length !== 0 && (
						<Fragment>
							<p className="h6 mb-3 dash-header">World smelters</p>

							<NavLink
								to="/ni/smeltersMap"
								className="btn btn-light smaller pull-right ml-2"
							>
								Show more
								<i className="fa fa-external-link pl-2" />
							</NavLink>

							<UncontrolledDropdown className="d-inline">
								<DropdownToggle
									caret
									color="light"
									className="smaller pull-right"
								>
									Legend
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem header className="small">
										<span className="circle active mr-2" /> Active
									</DropdownItem>
									<DropdownItem header className="small">
										<span className="circle inactive mr-2" /> Inactive
									</DropdownItem>
									<DropdownItem header className="small">
										<span className="circle shutdown mr-2" /> Shutdown
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
							<Viewer
								id="viewer"
								requestRenderMode={true}
								maximumRenderTimeChange={Infinity}
								imageryProvider={false}
								sceneMode={SceneMode.SCENE2D}
								sceneModePicker={false}
								animation={false}
								vrButton={false}
								geocoder={false}
								homeButton={false}
								fullscreenButton={false}
								baseLayerPicker={false}
								infoBox={true}
								navigationHelpButton={false}
								timeline={false}
								mapProjection={mapProjection}
								className="map-viewer"
							>
								<ScreenSpaceEventHandler useDefault>
									<ScreenSpaceEvent type={ScreenSpaceEventType.LEFT_CLICK} />
									<ScreenSpaceEvent
										type={ScreenSpaceEventType.LEFT_DOUBLE_CLICK}
									/>
								</ScreenSpaceEventHandler>

								<ScreenSpaceCameraController
									maximumZoomDistance={41000000}
									minimumZoomDistance={41000000}
								/>
								<CameraFlyTo duration={0} destination={smelterMapCamera} />

								{this.buildSmelterEntities(smelterMapData)}
								<Scene
									fxaa={false}
									sunBloom={false}
									shadowMap={false}
									shadows={false}
								/>
								<Globe tileCacheSize={10000} enableLighting={false} />
								<ImageryLayer
									imageryProvider={bingImageryProvider}
									gamma={0.5}
									brightness={1.3}
								/>
							</Viewer>
						</Fragment>
					)}
				</CardBody>
			</Card>
		);
	}

	renderSmelterMapPicture() {
		return (
			<Card className="mb-3 smelter-map-dashlet">
				<CardBody className="p-2">
					<Fragment>
						<p className="h6 mb-3 dash-header">World smelters</p>
						<p className="mb-0">
							<img
								src={dashboardMap}
								className="map-viewer"
								alt="Smelter locations"
							/>
						</p>
					</Fragment>
				</CardBody>
			</Card>
		);
	}

	renderRecentData() {
		const { niSmelterRecentUpdates = [] } = this.props;
		const { isEnterpriseOrSales } = this.state;
		const recents = niSmelterRecentUpdates.map(
			({ name, capture_date, end_date, state, prev_state, region }, idx) => (
				<div className="flex-row" role="rowgroup" key={`capture${idx}`}>
					<div className="flex-cell" role="cell">
						<div className="date-wrapper">
							<span>
								<i className="fa fa-calendar mr-2" />
							</span>
							<span>{moment.utc(capture_date).format('D MMM')}</span>
						</div>
					</div>
					<div className="flex-cell pl-3 pr-2" role="cell">
						{isEnterpriseOrSales && (
							<NavLink to={`/ni/smeltersMap/${region}`}>{name}</NavLink>
						)}
						{!isEnterpriseOrSales && <span>{name}</span>}
					</div>
					<div className="flex-cell" role="cell">
						<div className="status-wrapper">
							<span
								className={`status ${
									end_date
										? 'text-light status-shutdown'
										: state === '1'
										? 'text-dark status-active'
										: 'text-light status-inactive'
								}`}
							>
								{`${
									end_date ? 'shutdown' : state === '1' ? 'active' : 'inactive'
								}`}
							</span>
							<span
								className={`${
									state !== prev_state ? 'text-danger' : 'text-muted'
								}`}
							>
								{`${state !== prev_state ? 'change' : 'no change'}`}
							</span>
						</div>
					</div>
				</div>
			)
		);

		return (
			niSmelterRecentUpdates.length > 0 && (
				<Card className="mb-3">
					<CardBody className="p-2">
						<div className="d-flex justify-content-between align-items-center mb-2">
							<p className="h6 dash-header">Recent smelter observations</p>
							{isEnterpriseOrSales && (
								<NavLink
									to="/ni/smeltersMap/asia"
									className="btn btn-light smaller"
								>
									Show smelters
									<i className="fa fa-external-link pl-2" />
								</NavLink>
							)}
						</div>

						<div className="recentCaptures mb-0 mr-n2" role="table">
							<div className="table-body" role="list">
								{recents}
							</div>
						</div>
					</CardBody>
				</Card>
			)
		);
	}

	render() {
		const { isEnterpriseOrSales, isHigherThanFree, isFree } = this.state;

		return (
			<DocumentTitle title="SAVANT | Nickel Dashboard">
				<div className="content-wrapper dashboard">
					<Container fluid>
						<Crumbs type="ni" />
						<Row noGutters>
							<Col xs="5" sm="5" md="5" lg="6" xl="8" className="pr-3">
								{isEnterpriseOrSales && this.renderSmelterMap()}
								{!isEnterpriseOrSales && this.renderSmelterMapPicture()}
							</Col>
							<Col xs="7" sm="7" md="7" lg="6" xl="4">
								{!(isFree || isHigherThanFree) && (
									<div>
										The SAVANT Nickel product will be available soon. Please
										contact Earth-i for more information.
									</div>
								)}
								{(isFree || isHigherThanFree) && this.renderSmelterData()}
								{isHigherThanFree && this.renderRecentData()}
							</Col>
						</Row>
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	niSmelterAllMapData,
	niSmelterData,
	niSmelterRecentUpdates
}) => {
	return {
		niSmelterAllMapData,
		niSmelterData,
		niSmelterRecentUpdates
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchNiSmelterAllMapData: () => dispatch(fetchNiSmelterAllMapData()),
	fetchNiSmelterData: (filterData) => dispatch(fetchNiSmelterData(filterData)),
	resetNiSmelterData: () => dispatch(resetNiSmelterData()),
	fetchNiSmelterRecentUpdates: () => dispatch(fetchNiSmelterRecentUpdates())
});

export default connect(mapStateToProps, mapDispatchToProps)(NiHome);
