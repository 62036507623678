import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Col,
	Container,
	Form,
	FormGroup,
	Label,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row
} from 'reactstrap';
import Select from 'react-select';

import {
	fetchMySmelters,
	resetMySmelters,
	addMyFavSmelters,
	removeMyFavSmelters,
	resetMyFavSmeltersErr,
	getAuthenticatedUserGroups
} from 'actions';
import { multiSelectStyle } from './multiSelectStyle';
import { getEnterpriseTypes } from '../../utils';

class MySmeltersModal extends Component {
	state = {
		authGroups: [],
		shouldResetSmelters: false,
		isSubmitted: false,
		//list of smelters user subscribed for
		optInList: []
	};

	async componentDidMount() {
		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();

		this.setState({
			authGroups
		});
	}

	componentDidUpdate({
		open: prevOpen,
		mySmelterIds: prevMySmelterIds,
		error: prevError
	}) {
		const { open, mySmelterIds, error } = this.props;
		const { shouldResetSmelters } = this.state;

		if (
			(open && open !== prevOpen && mySmelterIds.length) ||
			prevMySmelterIds.length !== mySmelterIds.length ||
			(error !== prevError && !prevError)
		) {
			this.getOptIns();
		}

		if (open && open !== prevOpen && !mySmelterIds.length) {
			this.props.fetchMySmelters();
			this.setState({ shouldResetSmelters: true });
		}

		if (!open && open !== prevOpen && shouldResetSmelters) {
			this.props.resetMySmelters();
			this.setState({ shouldResetSmelters: false });
		}
	}

	getAllSmelterListFlatten = () => {
		return this.getAllSmelterList()
			.map((a) => a.options)
			.flat();
	};

	getAllSmelterList = () => {
		const { allSmelters, smelterRegions, niSmelterRegions } = this.props;
		const { authGroups } = this.state;

		const types = getEnterpriseTypes(authGroups);
		if (
			(smelterRegions.length === 0 && types.includes('cu')) ||
			(niSmelterRegions.length === 0 && types.includes('ni'))
		)
			return [];
		const allSmelterRegions = [
			...new Set([...smelterRegions, ...niSmelterRegions])
		];

		const filteredSmelters = allSmelters.filter(
			({ type, sandbox }) =>
				type !== 'steel' &&
				types.includes(type) &&
				(sandbox === 1 || sandbox === 0)
		);
		const optionsPerRegion = {};
		allSmelterRegions.forEach(
			(region) =>
				(optionsPerRegion[region.name] = { label: region.name, options: [] })
		);
		filteredSmelters.forEach(({ id, region, name, type }) => {
			optionsPerRegion[region].options.push({
				label: `${name} [${type === 'ni' ? 'Ni' : 'Cu'}]`,
				value: id
			});
		});

		let allOptions = [];
		Object.values(optionsPerRegion).forEach(
			(option) => (allOptions = [...allOptions, option])
		);

		return allOptions;
	};

	handleCloseModal = () => {
		this.props.toggle();
	};

	onSelectSmelter = (value) => {
		this.setState({ optInList: value ? value : [] });
	};

	getOptIns = () => {
		const { mySmelterIds, error } = this.props;
		const allSmelters = this.getAllSmelterListFlatten();
		if (error) {
			this.props.resetMyFavSmeltersErr();
		}
		this.setState({
			optInList: allSmelters.filter(({ value }) =>
				mySmelterIds.includes(value)
			),
			isSubmitted: false
		});
	};

	onUserSubmit = (event) => {
		event.preventDefault();

		if (this.state.isSubmitted) return;

		const { optInList } = this.state;
		const { mySmelterIds } = this.props;

		const idsForSub = optInList
			.filter(({ value }) => !mySmelterIds.includes(value))
			.map(({ value }) => value);
		if (idsForSub.length) {
			this.props.addMyFavSmelters({ smelterIds: idsForSub });
		}

		const idsForUnsub = mySmelterIds.filter(
			(id) => !optInList.map(({ value }) => value).includes(id)
		);
		if (idsForUnsub.length) {
			this.props.removeMyFavSmelters({ smelterIds: idsForUnsub });
		}

		if (idsForSub.length || idsForUnsub.length) {
			this.setState({ isSubmitted: true });
		}
	};

	generateWatchList = () => {
		const { mySmelterIds } = this.props;
		const allSmelters = this.getAllSmelterList();

		const watchedSmelters = allSmelters
			.filter(({ options }) =>
				options.some((o) => mySmelterIds.includes(o.value))
			)
			.map(({ label, options }) => {
				const filteredOpts = Object.assign({}, { label, options });
				return {
					label,
					options: filteredOpts.options.filter((o) =>
						mySmelterIds.includes(o.value)
					)
				};
			});

		if (!watchedSmelters.length)
			return (
				<Row className="mt-4">
					<Col xs="12">
						<div className="mx-4">You haven't chosen any smelter yet!</div>
					</Col>
				</Row>
			);

		return (
			<Row className="mt-4">
				<Col xs="12">
					<Card className="mx-1">
						<CardHeader className="px-2 py-1">My Watched Smelters</CardHeader>
						<CardBody className="p-2">
							{watchedSmelters.map((wl, idx) => (
								<Fragment key={wl.label}>
									<strong className={`d-block${idx > 0 && ' mt-3'}`}>
										{wl.label}
									</strong>
									<ListGroup>
										{wl.options.map(({ label, value }) => (
											<ListGroupItem key={value} className="px-2 py-1">
												{label}
											</ListGroupItem>
										))}
									</ListGroup>
								</Fragment>
							))}
						</CardBody>
						<CardFooter className="text-muted small px-2 py-1">
							Users will be notified by email when selected smelters change
							status.
						</CardFooter>
					</Card>
				</Col>
			</Row>
		);
	};

	render() {
		const { open } = this.props;
		const { optInList } = this.state;

		return (
			<Modal isOpen={open} labelledBy="mySmeltersModalLabel" aria-hidden="true">
				<ModalHeader toggle={this.handleCloseModal}>
					<span id="mySmeltersModalLabel">
						<h4 className="mb-0">My Smelters</h4>
					</span>
				</ModalHeader>
				<ModalBody>
					<Container fluid>
						<Row>
							<Col xs="12">
								<Form onSubmit={this.onUserSubmit}>
									<FormGroup>
										<Label for="selectFavSmelters" className="small ml-1">
											Add/Remove smelters from your list of watched smelters:
										</Label>
										<Select
											id="selectFavSmelters"
											isMulti
											options={this.getAllSmelterList()}
											value={optInList}
											onChange={(val) => this.onSelectSmelter(val)}
											className="mb-0 ml-1"
											classNamePrefix="react-select"
											placeholder="Select Smelters..."
											styles={multiSelectStyle}
										/>
									</FormGroup>
									<div className="mr-1">
										<Button
											size="sm"
											color="primary"
											className="mb-2 ml-3 pull-right"
										>
											Save
										</Button>
										<Button
											size="sm"
											color="warning"
											className="mb-1 ml-3 pull-right"
											onClick={this.getOptIns}
										>
											Revert
										</Button>
									</div>
								</Form>
							</Col>
						</Row>
						{this.generateWatchList()}
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.handleCloseModal}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = ({
	mySmelters: allSmelters,
	myFavSmelters: { smelterIds: mySmelterIds },
	myFavSmelters: { error },
	smelterRegions,
	niSmelterRegions
}) => ({
	allSmelters,
	mySmelterIds,
	error,
	smelterRegions,
	niSmelterRegions
});

const mapDispatchToProps = (dispatch) => ({
	fetchMySmelters: () => dispatch(fetchMySmelters()),
	resetMySmelters: () => dispatch(resetMySmelters()),
	addMyFavSmelters: (filterData) => dispatch(addMyFavSmelters(filterData)),
	removeMyFavSmelters: (filterData) =>
		dispatch(removeMyFavSmelters(filterData)),
	resetMyFavSmeltersErr: () => dispatch(resetMyFavSmeltersErr())
});

export default connect(mapStateToProps, mapDispatchToProps)(MySmeltersModal);
