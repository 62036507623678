import {
	FETCH_MY_FAV_SMELTERS,
	ADD_MY_FAV_SMELTERS,
	REMOVE_MY_FAV_SMELTERS,
	UPDATE_MY_FAV_SMELTERS_ERR,
	RESET_MY_FAV_SMELTERS_ERR
} from '../actions';

const INITIAL_STATE = {
	smelterIds: [],
	error: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_MY_FAV_SMELTERS: {
			const favSmelters = [];
			action.payload.forEach(
				({ id, sub }) => sub === 1 && favSmelters.push(id)
			);
			return { ...state, smelterIds: favSmelters, error: '' };
		}

		case ADD_MY_FAV_SMELTERS:
			return {
				...state,
				smelterIds: [...state.smelterIds, ...action.payload],
				error: ''
			};

		case REMOVE_MY_FAV_SMELTERS: {
			const favSmelters = state.smelterIds.filter(
				(id) => !action.payload.includes(id)
			);
			return { ...state, smelterIds: favSmelters, error: '' };
		}

		case UPDATE_MY_FAV_SMELTERS_ERR:
			return { ...state, error: action.payload };

		case RESET_MY_FAV_SMELTERS_ERR:
			return { ...state, error: '' };

		default:
			return state;
	}
};
