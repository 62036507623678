import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import packageJson from '../../../package.json';

const mailto1 = `mailto:savant@earthi.space?subject=SAVANT`;
const mailto2 = `mailto:savantsupport@earthi.space?subject=SAVANT%20support%20-%20App%20version%3A%20${packageJson.version}`;

const ContactModal = ({ open, toggle }) => (
	<Modal isOpen={open} labelledBy="contactModalLabel" aria-hidden="true">
		<ModalHeader toggle={toggle}>
			<span id="contactModalLabel">
				<h4 className="mb-0">Contact Earth-i</h4>
			</span>
		</ModalHeader>
		<ModalBody>
			<h5>We'd be delighted to hear from you</h5>
			<p>
				For product queries, pricing, feedback, requesting a demo or any other
				sales enquiry
			</p>
			<p className="h5 text-center mb-4">
				<a href={mailto1} target="_blank" rel="noopener noreferrer">
					savant@earthi.space
				</a>
			</p>
			<p>For technical support</p>
			<p className="h5 text-center mb-4">
				<a href={mailto2} target="_blank" rel="noopener noreferrer">
					savantsupport@earthi.space
				</a>
			</p>
			<p>To speak to someone immediately</p>
			<p className="h5 text-center mb-4">+44 (0)333 433 0015</p>
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" aria-label="Close" onClick={toggle}>
				Close
			</Button>
		</ModalFooter>
	</Modal>
);

export default ContactModal;
