import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga';
import { Line } from 'react-chartjs-2';
import { toastr } from 'react-redux-toastr';

import { downloadKml, fetchSmelterParamList } from 'actions';
import { StripPlot } from 'components';
import {
	aStripChartStyle,
	rStripChartStyle,
	aStripChartOptions,
	rStripChartOptions
} from '../screens/Smelters/chartConfig';

import './StripPlotContainer.scss';

class StripPlotContainer extends Component {
	constructor(props) {
		super(props);
		this.csvActivityLinkRef = React.createRef();

		this.state = {
			csvActivityData: [],
			showDispersionPlot: false
		};
	}

	componentDidMount() {
		const { title, noDataWarning } = this.props;
		if (noDataWarning) {
			toastr.warning(title, 'No data for chosen date range');
		}
	}

	componentDidUpdate(
		{
			alignedCapacityData: prevAlignedCapacityData,
			alignedActivityData: prevAlignedActivityData
		},
		{ csvActivityData: prevCsvActivityData }
	) {
		const { csvActivityData } = this.state;
		const { title, noDataWarning, alignedCapacityData, alignedActivityData } =
			this.props;

		if (csvActivityData.length > prevCsvActivityData.length) {
			this.csvActivityLinkRef.current.link.click();
		}

		if (
			noDataWarning &&
			(prevAlignedActivityData.length !== alignedActivityData.length ||
				prevAlignedCapacityData.length !== alignedCapacityData.length)
		) {
			toastr.warning(title, 'No data for chosen date range');
		}
	}

	downloadParamList = async () => {
		if (this.state.csvActivityData.length) {
			this.csvActivityLinkRef.current.link.click();
			return;
		}
		const {
			smelterId,
			title,
			type,
			activityData,
			csvDateFormat,
			capacityData
		} = this.props;
		// API call to get data
		try {
			const response = await fetchSmelterParamList({ smelterId, type });
			const aData = activityData.map(({ t, a, r }) => ({
				t: moment.utc(t).format(csvDateFormat),
				a,
				r
			}));

			const pData = response[title].data.map(
				({
					capture_date,
					hotspots,
					low_count,
					medium_count,
					high_count,
					invalid_frac,
					smoke,
					p_data_age,
					s_hotspot,
					data_age
				}) => ({
					t: moment.utc(capture_date).format(csvDateFormat),
					hs: hotspots,
					hc: high_count,
					mc: medium_count,
					lc: low_count,
					if: invalid_frac,
					sm: smoke,
					da: p_data_age,
					shs: s_hotspot,
					daps: data_age
				})
			);

			//we need to know max date from pData in order to fill capacityData with missing values
			const maxDate = moment.utc(pData[pData.length - 1].t).add(1, 'days');
			const extendedCapacityData = [];
			//exclude 'unknown' - each 'unknown' is 'on' or 'off'
			const fCapacityData = capacityData.filter((c) => c.v);

			fCapacityData.forEach((c, i) => {
				const diff =
					(i < fCapacityData.length - 1 &&
						moment.utc(fCapacityData[i + 1].t).diff(c.t, 'days')) ||
					maxDate.diff(c.t, 'days');
				for (let j = 0; j < diff; j++) {
					extendedCapacityData.push({
						t: moment.utc(c.t).add(j, 'days').format(csvDateFormat),
						v: c.v === '1' ? 'on' : 'off'
					});
				}
			});

			// merge extendedCapacityData to pData
			const extendedPData = pData.map((p) => ({
				...extendedCapacityData.find((c) => c.t === p.t && c),
				...p
			}));

			//merge two arrays, pData.length <= aData.length
			//find element in aDate with the same capture_date as the first capture_date in pData and merge data from the portion of aData and pData
			const startIdx = aData.findIndex((a) => a.t === extendedPData[0].t);
			const aData_1 = aData.slice(0, startIdx);
			//aData_2 and pData have the same start date
			const aData_2 = aData.slice(startIdx);
			const mData_2 = aData_2.map(({ t, a, r }, i) =>
				Object.assign({}, { ...{ t, a, r } }, { ...extendedPData[i] })
			);
			const mData = [...aData_1, ...mData_2];
			this.setState({ csvActivityData: mData });
		} catch (e) {
			console.log('Error while downloading param list ', e);
		}
	};

	onDownloadKml = async ({ kmlSelect }) => {
		const { type } = this.props;
		if (kmlSelect) {
			await this.downloadLink(
				await downloadKml({
					body: { fileName: kmlSelect },
					type
				})
			);
		}
	};

	downloadLink = async (url) => {
		if (url) {
			window.open(url, '_blank');
		}
	};

	render() {
		const {
			alignedActivityData,
			capacityData,
			alignedCapacityData,
			csvDateFormat,
			idx,
			maxDate,
			newestDate,
			title,
			sandbox,
			startDate,
			endDate
		} = this.props;

		const reducedTitle = title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		const kmlButtonId = `kmlDownload${idx}`;
		const csvButtonId = `csvDownload${idx}`;
		const csvActivityButtonId = `csvActivityDownload${idx}`;
		const dispersionPlotButtonId = `dispersionPlotButtonId${idx}`;

		const { csvActivityData, showDispersionPlot } = this.state;

		const stripPlotDatasets = [
			{
				data: alignedActivityData.map(({ t, a }) => {
					return {
						t: moment.utc(t).format('YYYY-MM-DD'),
						y: a
					};
				}),
				...aStripChartStyle
			}
		];

		const stripPlotRawDatasets = [
			{
				data: alignedActivityData.map(({ t, r }) => {
					return {
						t: moment.utc(t).format('YYYY-MM-DD'),
						y: r
					};
				}),
				...rStripChartStyle
			}
		];
		return (
			<div className="mb-2" key={`stripPlot${idx}`}>
				<ButtonGroup className="mb-1 mt-2 pull-right">
					<Button
						id={kmlButtonId}
						color="success"
						size="sm"
						onClick={() => this.onDownloadKml({ kmlSelect: reducedTitle })}
					>
						<i className="fa fa-globe" aria-hidden="true" />
						<span className="sr-only">Download kml</span>
					</Button>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={kmlButtonId}
						trigger="click hover"
					>
						<i className="fa fa-download mr-2" aria-hidden="true" /> Download
						this data in KML format.
					</UncontrolledTooltip>

					<Fragment>
						<Button
							id={csvActivityButtonId}
							color="success"
							size="sm"
							onClick={this.downloadParamList}
						>
							<i className="fa fa-line-chart" aria-hidden="true" />
						</Button>
						<CSVLink
							ref={this.csvActivityLinkRef}
							data={csvActivityData}
							headers={[
								{ label: 'Date', key: 't' },
								{ label: 'State', key: 'v' },
								{ label: 'Activity dispersion', key: 'a' },
								{ label: 'Raw index score', key: 'r' },
								{ label: 'Hotspots', key: 'hs' },
								{ label: 'Low count', key: 'lc' },
								{ label: 'Medium count', key: 'mc' },
								{ label: 'High count', key: 'hc' },
								{ label: 'Cloud pct', key: 'if' },
								{ label: 'Smoke', key: 'sm' },
								{ label: 'Data age (prime)', key: 'da' },
								{ label: 'Secondary hotspot', key: 'shs' },
								{ label: 'Data age (prime + secondary)', key: 'daps' }
							]}
							filename={`${title}-activity-dispersion-and-raw-index-score-data.csv`}
							className="d-none"
							onClick={() => {
								ReactGA.event({
									category: 'smeltersMap',
									action:
										'activity dispersion and raw index score data downloaded',
									label: `${title}-activity-dispersion-and-raw-index-score-data-and-related-data.csv`
								});
								return true;
							}}
						/>
					</Fragment>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={csvActivityButtonId}
						trigger="click hover"
					>
						<i className="fa fa-download mr-2" aria-hidden="true" /> Download
						Activity Dispersion, Raw Index Score and related data in CSV format.
					</UncontrolledTooltip>

					<CSVLink
						id={csvButtonId}
						filename={`${title}-inactive-capacity-data.csv`}
						headers={[
							{ label: 'Capture time', key: 't' },
							{ label: 'State', key: 'v' }
						]}
						data={capacityData.map(({ t, v }) => {
							let dspState = 'unknown';
							if (v === '1') {
								dspState = 'on';
							} else if (v === '0') {
								dspState = 'off';
							}
							return { t: moment.utc(t).format(csvDateFormat), v: dspState };
						})}
						className="btn btn-success btn-sm"
						onClick={() => {
							ReactGA.event({
								category: 'smeltersMap',
								action: 'inactive capacity data downloaded',
								label: `${title}-inactive-capacity-data.csv`
							});
							return true;
						}}
					>
						<i className="fa fa-table" aria-hidden="true" />
					</CSVLink>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={csvButtonId}
						trigger="click hover"
					>
						<i className="fa fa-download mr-2" aria-hidden="true" /> Download
						Inactive Capacity Index data in CSV format.
					</UncontrolledTooltip>
					<Button
						id={dispersionPlotButtonId}
						color={showDispersionPlot ? 'secondary' : 'success'}
						size="sm"
						onClick={() =>
							this.setState({ showDispersionPlot: !showDispersionPlot })
						}
					>
						<i className="fa fa-bar-chart" aria-hidden="true" />
						<span className="sr-only">
							Toggle Activity Dispersion Index plot
						</span>
					</Button>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={dispersionPlotButtonId}
						trigger="click hover"
					>
						<i className="fa fa-eye-slash" aria-hidden="true" /> Toggle Activity
						Dispersion plot visibility
					</UncontrolledTooltip>
				</ButtonGroup>
				<div>
					<h6 className="mb-0 mt-3 d-inline-block">
						{sandbox ? (
							<Fragment>
								{title}
								<span className="asterisk">*</span>
							</Fragment>
						) : (
							title
						)}
					</h6>{' '}
					&nbsp;&nbsp;&nbsp;
					<span className="index-dates">
						{startDate ? `First date in index: ${startDate}` : ''}
					</span>
					<span className="index-dates">
						{endDate ? `, Last date in index: ${endDate}` : ''}
					</span>
				</div>
				{showDispersionPlot && (
					<div className="chart-container">
						<Line
							data={{ datasets: stripPlotDatasets }}
							options={aStripChartOptions}
						/>
					</div>
				)}
				<div className="chart-container">
					<Line
						data={{ datasets: stripPlotRawDatasets }}
						options={rStripChartOptions}
					/>
				</div>
				<StripPlot
					id={reducedTitle}
					data={alignedCapacityData}
					maxDate={maxDate}
					newestDate={newestDate}
					minWidth={700}
					plotHeight={40}
					axisHeight={40}
					tickHeight={5}
					hoverHeight={4}
					margin={{
						left: 5,
						top: 0,
						right: 5,
						bottom: 0
					}}
				/>
			</div>
		);
	}
}

export default StripPlotContainer;
