import { FETCH_NI_SMELTER_UPDATES } from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_NI_SMELTER_UPDATES:
			return action.payload;
		default:
			return state;
	}
};
