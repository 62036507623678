import { SELECT_SMELTER, UNSELECT_SMELTER } from '../actions';

export default (state = [], action) => {
	switch (action.type) {
		case SELECT_SMELTER:
			return [...state, action.payload];
		case UNSELECT_SMELTER: {
			return state.filter((item) => item !== action.payload);
		}
		default:
			return state;
	}
};
