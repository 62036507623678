import React from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table
} from 'reactstrap';
import config from '../../config';

const ApiModal = ({ open, toggle }) => (
	<Modal
		size="lg"
		isOpen={open}
		labelledBy="apiUseModalLabel"
		aria-hidden="true"
	>
		<ModalHeader toggle={toggle}>
			<span id="apiUseModalLabel">
				<h4 className="mb-0"> 3rd-party API Access</h4>
			</span>
		</ModalHeader>
		<ModalBody>
			<h5>AWS Cognito</h5>
			<p className="mb-4">
				The SAVANT API is secured using AWS Cognito, which supports the OAuth
				2.0 authorization framework. During the use of this application the
				security authorization is handled automatically. However, should it be
				necessary to extract data directly from the API, a user should follow
				these instructions to authorize access.
			</p>
			<h5>Using an Authorization Header</h5>
			<p className="mb-4">
				By logging in to this application, authorization has already been
				achieved. An API user can copy the <strong>Authorization header</strong>{' '}
				from this application and use it elsewhere for as long as their session
				remains active. The <i className="fa fa-fw fa-shield" />{' '}
				<strong>API Credentials</strong> menu option provides a feature for a
				user to copy the Authorization header. An https request to any of the
				api endpoints, with the Authorization request header set to the copied
				value, will allow access.
			</p>
			<h5>Programmatic access</h5>
			<p>
				A user developing their own solution that needs access to the SAVANT
				API, may wish to perform a Cognito login within that application. There
				are numerous AWS articles that explain how this is done on various
				platforms. In addition to a valid username and password, the
				configuration required for this is:
			</p>
			<dl className="mb-5">
				<dt>API URL</dt>
				<dd>{config.apiGateway.URL}</dd>
				<dt>Region</dt>
				<dd>{config.cognito.REGION}</dd>
				<dt>User Pool ID</dt>
				<dd>{config.cognito.USER_POOL_ID}</dd>
				<dt>Identity Pool ID</dt>
				<dd>{config.cognito.IDENTITY_POOL_ID}</dd>
				<dt>Application ID</dt>
				<dd>{config.cognito.APP_CLIENT_ID}</dd>
			</dl>
			<h5>API Endpoints</h5>
			<p>
				The following endpoints provide SAVANT data. Please note that all data
				access is controlled by your account type and you may not get a result
				from a data call if you have insufficient privileges.
			</p>
			<Table size="sm">
				<thead>
					<tr>
						<th scope="col">Verb</th>
						<th scope="col">Path</th>
						<th scope="col">Query</th>
						<th scope="col">Action</th>
						<th scope="col">Example</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>GET</td>
						<td>/last-upload-date</td>
						<td>
							<strong>type</strong>: Metal type <em>cu</em> or <em>ni</em>
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>Get the most recent date that data has been uploaded</td>
						<td>
							/last-upload-date
							<br />
							<br />
							/last-upload-date?type=ni
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-data</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>regions</strong>: Comma separated list of regions; see
							bottom of the page for details
							<br />
							<strong>startDate</strong>: ISO 8601 date string
							<br />
							<strong>endDate</strong>: ISO 8601 date string
							<br />
							If no dates are given, the default is the last 3 months.
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get calculated Inactive Capacity index (f) and Activity Dispersion
							index (a) data for smelter regions by their names, optionally
							constrained to certain dates.
						</td>
						<td>
							/smelter-data?regions=china,russia,europeafrica
							<br />
							<br />
							/smelter-data?regions=china,namerica
							<br />
							&amp;startDate=20171011&amp;endDate=20171211
							<br />
							<br />
							/smelter-data?type=ni
							<br />
							&amp;regions=chinanpi,indonesianpi,asiaoceania
							<br />
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-data-all-by-smelter</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>smelterIds</strong>: A comma separated list of smelter ids
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get smelter capacity, Activity Dispersion and Raw Index Score for
							all times, for smelters by their ids. Activity Dispersion (a) and
							Raw Index Score (r) are found under 'activity' data.
						</td>
						<td>
							/smelter-data-all-by-smelter?smelterIds=287
							<br />
							<br />
							/smelter-data-all-by-smelter?smelterIds=287,276
							<br />
							<br />
							/smelter-data-all-by-smelter?smelterIds=430,425&amp;type=ni
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-map-data</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>region</strong>: A region; see bottom of the page for
							details
							<br />
							<strong>sandbox</strong>: A flag <em>true</em> (Non-Index
							smelters) or <em>false</em> (Index smelters).
							<br />
							<strong>searchDate</strong>: ISO 8601 date string
							<br />
							If no search date is given, the default is the latest data update
							date.
							<br />
							If no region is given, the default is <em>all</em>.
							<br />
							If no type is given, the default is <em>cu</em>.
							<br />
							If no sandbox is given, Index and Non-Index smelters are returned.
						</td>
						<td>
							Get smelter active/inactive state (1 or 0), latest capture date
							and smelter details such as lat/long and capacity, for all
							smelters in a region on a defined search date.
						</td>
						<td>
							/smelter-map-data?region=europe
							<br />
							&amp;sandbox=true&amp;searchDate=20171011
							<br />
							<br />
							/smelter-map-data?type=ni&amp;region=china
							<br />
							&amp;searchDate=20171011
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-map-data-all</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get the most recent smelter active/inactive state (1 or 0),
							capture date, smelter name and lat/long for all smelters.
						</td>
						<td>
							/smelter-map-data-all
							<br />
							<br />
							/smelter-map-data-all&amp;type=ni
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-map-recent-updates</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get the most recent smelter observation for each smelter including
							capture date, smelter name, previous state, region and current
							state.
						</td>
						<td>
							/smelter-map-recent-updates
							<br />
							<br />
							/smelter-map-recent-updates&amp;type=ni
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-param-list-by-smelter</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>smelterIds</strong>: A comma separated list of smelter ids
							<br />
							<strong>startDate</strong>: ISO 8601 date string
							<br />
							<strong>endDate</strong>: ISO 8601 date string
							<br />
							If no dates are given, the default startDate is '20160101' and the
							default endDate is the latest data update date.
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get the underlying smelter parameters used to calculate the Raw
							Index Score and Activity Dispersion.
						</td>
						<td>
							/smelter-param-list-by-smelter?smelterIds=287,276
							<br />
							&amp;startDate=20171011&amp;endDate=20171211
							<br />
							<br />
							/smelter-param-list-by-smelter?smelterIds=287,276
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-capacity</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>regions</strong>: Comma separated list of regions; see
							bottom of the page for details
							<br />
							<strong>startDate</strong>: ISO 8601 date string
							<br />
							<strong>endDate</strong>: ISO 8601 date string
							<br />
							If no dates are given, the default is the last 2 years.
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get capacity (c) values in kilotonnes for smelter regions by their
							names, optionally constrained to certain dates.
						</td>
						<td>
							/smelter-capacity?regions=china,russia,europeafrica
							<br />
							<br />
							/smelter-capacity?regions=china,namerica
							<br />
							&amp;startDate=20171011&amp;endDate=20171211
							<br />
							<br />
							/smelter-capacity?type=ni
							<br />
							&amp;regions=chinanpi,indonesianpi,asiaoceania
							<br />
						</td>
					</tr>

					<tr>
						<td>GET</td>
						<td>/smelter-production</td>
						<td>
							<strong>type</strong>: Metal type <em>ni</em> or <em>cu</em>
							<br />
							<strong>regions</strong>: Comma separated list of regions; see
							bottom of the page for details
							<br />
							<strong>startDate</strong>: ISO 8601 date string
							<br />
							<strong>period</strong>: <em>month</em>, <em>quarter</em> or{' '}
							<em>year</em>
							<br />
							<strong>ute</strong>: Region utilisation rate - a decimal number
							greater than zero (applies for single region; if multiple regions
							selected, defaults apply)
							<br />
							<strong>capAdd</strong>: (denoting additional capacity percentage)
							- a decimal number greater than zero (applies for single region;
							if multiple regions selected, defaults apply)
							<br />
							If no date is given, the default startDate is '20170101'.
							<br />
							If no period is given, month is set as a default.
							<br />
							If ute or capAdd is not provided, the default values for ute and
							capAdd are used.
							<br />
							If no type is given, the default is <em>cu</em>.
						</td>
						<td>
							Get production (p) values in kilotonnes for smelter regions by
							their names, optionally constrained to certain start date and
							period.
						</td>
						<td>
							/smelter-production?regions=china,chile,australia
							<br />
							<br />
							/smelter-production?regions=china
							<br />
							&amp;startDate=20171011&amp;period=quarter
							<br />
							<br />
							/smelter-production?type=ni
							<br />
							&amp;regions=china&amp;ute=0.89
							<br />
						</td>
					</tr>
				</tbody>
			</Table>
			<div className="mt-2 text-muted small">
				<p>
					<strong>Note</strong>: Examples of region/regions parameter per metal
					type/endpoints are given below:
				</p>
				<ul>
					<li>
						<strong>Cu</strong>:
						<ul>
							<li>
								<strong>
									smelter-data, smelter-capacity, smelter-production
								</strong>
								: (
								<em>
									global, asiaoceania, europeafrica, china, namerica, samerica,
									globalexchina
								</em>
								) or (countries such as{' '}
								<em>australia, belgium, unitedstates</em>) or (chinese regions
								such as <em>northchina, northeastchina, southcentralchina</em>)
								or (<em>captive, custom</em>)
							</li>
							<li>
								<strong>smelter-map-data</strong>:{' '}
								<em>
									africa, asia, europe, northamerica, oceania, southamerica, all
								</em>
							</li>
						</ul>
					</li>
					<li>
						<strong>Ni</strong>:
						<ul>
							<li>
								<strong>
									smelter-data, smelter-capacity, smelter-production
								</strong>
								: (
								<em>
									global, chinanpi, chinaexnpi, indonesianpi, indonesiaexnpi,
									asiaandoceania, europeandafrica, americas
								</em>
								) or (<em>ni, feni, npi</em>) or (countries such as{' '}
								<em>indonesia, finland, canada</em>)
							</li>
							<li>
								<strong>smelter-map-data</strong>:{' '}
								<em>
									africa, asiaexcnidn, china europe, indonesia, northamerica,
									oceania, southamerica, all
								</em>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" aria-label="Close" onClick={toggle}>
				Close
			</Button>
		</ModalFooter>
	</Modal>
);

export default ApiModal;
