import { API } from 'aws-amplify';

import {
	getAuthHeaders,
	FETCH_NI_PSEUDOGROUPS,
	FETCH_NI_PSEUDOGROUPS_ERR
} from '../';
import config from '../../config';
import { replacer } from '../../utils';

export const fetchNiPseudoGroups =
	({ ute }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niPseudoGroups, {
				ute
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_PSEUDOGROUPS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_NI_PSEUDOGROUPS_ERR,
					payload: err
				})
		);
	};

export const resetNiPseudoGroups = () => ({
	type: FETCH_NI_PSEUDOGROUPS,
	payload: []
});
