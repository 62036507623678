import { FETCH_SMELTER_RECENT_UPDATES } from '../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_SMELTER_RECENT_UPDATES:
			return action.payload;
		default:
			return state;
	}
};
