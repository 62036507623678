import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
	ForgotPassword,
	Free,
	Home,
	Login,
	NiHome,
	NiSmelterInfo,
	NiSmelters,
	NiSmeltersMap,
	NiSmeltersCapacity,
	NiSmeltersProduction,
	SteelHome,
	SteelSmelterInfo,
	SteelSmelters,
	SteelSmeltersMap,
	SteelSmeltersCapacity,
	NotFound,
	SignUp,
	SmelterInfo,
	Smelters,
	SmeltersMap,
	SmeltersCapacity,
	SmeltersProduction
} from 'screens';
import { AppliedRoute } from 'components';
import {
	getGroupsPerType,
	getGroupsPremiumHigher,
	getGroupsSalesEnterprise
} from './utils';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute
			path="/"
			exact
			component={Login}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute
			path="/demo"
			exact
			component={Free}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute
			path="/signUp"
			exact
			component={SignUp}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute
			path="/forgotPassword"
			exact
			component={ForgotPassword}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>

		{/* Copper routes */}
		<AppliedRoute path="/home" exact component={Home} props={childProps} />
		<AppliedRoute
			path="/smelterInfo"
			exact
			component={SmelterInfo}
			props={childProps}
			groupProtect={getGroupsPerType('cu')}
		/>
		<AppliedRoute
			path="/smelters"
			exact
			component={Smelters}
			props={childProps}
			groupProtect={getGroupsPerType('cu')}
		/>
		<AppliedRoute
			path="/smeltersCapacity"
			exact
			component={SmeltersCapacity}
			props={childProps}
			groupProtect={getGroupsPremiumHigher('cu')}
		/>
		<AppliedRoute
			path="/smeltersMap/:regionId?"
			exact
			component={SmeltersMap}
			props={childProps}
			groupProtect={getGroupsSalesEnterprise('cu')}
		/>
		<AppliedRoute
			path="/smeltersProduction"
			exact
			component={SmeltersProduction}
			props={childProps}
			groupProtect={getGroupsPremiumHigher('cu')}
		/>

		{/* Nickel routes */}
		<AppliedRoute
			path="/ni/home"
			exact
			component={NiHome}
			props={childProps}
			type="ni"
		/>
		<AppliedRoute
			path="/ni/smelterInfo"
			exact
			component={NiSmelterInfo}
			props={childProps}
			groupProtect={getGroupsPerType('ni')}
			type="ni"
		/>
		<AppliedRoute
			path="/ni/smelters"
			exact
			component={NiSmelters}
			props={childProps}
			groupProtect={getGroupsPerType('ni')}
			type="ni"
		/>
		<AppliedRoute
			path="/ni/smeltersMap/:regionId?"
			exact
			component={NiSmeltersMap}
			props={childProps}
			groupProtect={getGroupsSalesEnterprise('ni')}
			type="ni"
		/>
		<AppliedRoute
			path="/ni/smeltersCapacity"
			exact
			component={NiSmeltersCapacity}
			props={childProps}
			groupProtect={getGroupsPremiumHigher('ni')}
			type="ni"
		/>
		<AppliedRoute
			path="/ni/smeltersProduction"
			exact
			component={NiSmeltersProduction}
			props={childProps}
			groupProtect={getGroupsPremiumHigher('ni')}
			type="ni"
		/>

		{/* Steel routes */}
		<AppliedRoute
			path="/steel/home"
			exact
			component={SteelHome}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smelterInfo"
			exact
			component={SteelSmelterInfo}
			props={childProps}
			groupProtect={getGroupsPerType('steel')}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smelters"
			exact
			component={SteelSmelters}
			props={childProps}
			groupProtect={getGroupsPerType('steel')}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersMap/:regionId?"
			exact
			component={SteelSmeltersMap}
			props={childProps}
			groupProtect={getGroupsSalesEnterprise('steel')}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersCapacity"
			exact
			component={SteelSmeltersCapacity}
			props={childProps}
			groupProtect={getGroupsPremiumHigher('steel')}
			type="steel"
		/>

		<Route component={NotFound} />
	</Switch>
);
