import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { selectNiProdSmelter, unselectNiProdSmelter } from 'actions';

class SmelterList extends Component {
	// Checkbox click for the smelter checkboxes
	onCheckboxBtnClick({ prop, val }) {
		const { niSelectedProdSmelters } = this.props;
		if (niSelectedProdSmelters.includes(val)) {
			this.props.unselectNiProdSmelter(val);
		} else {
			this.props.selectNiProdSmelter(val);
		}
	}
	render() {
		const { niSelectedProdSmelters, niPseudoGroupSmelters } = this.props;
		return (
			<div className="smelter-list pb-1" role="table">
				<div className="flex-row" role="rowheader">
					<div className="flex-col py-1" role="columnheader">
						<i className="fa fa-bar-chart" aria-hidden="true" />
					</div>
					<div className="flex-col pb-1" role="columnheader">
						Smelter
					</div>
					<div id="mtd-header" className="flex-col" role="columnheader">
						MTD
					</div>
					<UncontrolledTooltip
						placement="top"
						delay={{ show: 1000, hide: 500 }}
						target="mtd-header"
					>
						Current month to date production
					</UncontrolledTooltip>
				</div>
				<div className="tb-wrapper">
					<div className="table-body" role="list">
						{niPseudoGroupSmelters.map(({ id, name, p }) => {
							return (
								<div className="flex-row-cells" role="rowgroup" key={id}>
									<div className="flex-cell" role="cell">
										{!niSelectedProdSmelters.includes(id) && (
											<i
												className="fa fa-square-o pointered"
												aria-hidden="true"
												onClick={() =>
													this.onCheckboxBtnClick({
														prop: 'niSelectedProdSmelters',
														val: id
													})
												}
											/>
										)}
										{niSelectedProdSmelters.includes(id) && (
											<i
												className="fa fa-check-square-o pointered"
												aria-hidden="true"
												onClick={() =>
													this.onCheckboxBtnClick({
														prop: 'niSelectedProdSmelters',
														val: id
													})
												}
											/>
										)}
									</div>

									<div
										id={`smelter-${id}`}
										className="flex-cell pr-1"
										role="cell"
									>
										<span className="title-base">{name}</span>
									</div>

									<UncontrolledTooltip
										placement="top"
										delay={{ show: 1000, hide: 500 }}
										target={`smelter-${id}`}
									>
										{name}
									</UncontrolledTooltip>
									<div className="flex-cell pr-1" role="cell">
										{Number.parseFloat(p).toFixed(2)}
									</div>
								</div>
							);
						})}
					</div>
					<div className="table-notes"></div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ niPseudoGroupSmelters, niSelectedProdSmelters }) => {
	return { niPseudoGroupSmelters, niSelectedProdSmelters };
};

const mapDispatchToProps = (dispatch) => ({
	selectNiProdSmelter: (key) => dispatch(selectNiProdSmelter(key)),
	unselectNiProdSmelter: (key) => dispatch(unselectNiProdSmelter(key))
});

export default connect(mapStateToProps, mapDispatchToProps)(SmelterList);
