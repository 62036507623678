import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga';
import IdleTimer from 'react-idle-timer';

import config from './config';
import Routes from './routes';

import {
	getAuthenticatedUserAttributes,
	getAuthenticatedUserGroups
} from 'actions';

import './App.scss';

ReactGA.initialize(config.google.ANALYTICS_ID, {
	titleCase: false,
	testMode: config.google.TEST_MODE
});

class App extends Component {
	constructor(props) {
		super(props);

		this.idleTimer = null;

		this.state = {
			isAuthenticated: false,
			isAuthenticating: true,
			authGroups: []
		};
	}

	async onIdle() {
		const authenticatedUser = await getAuthenticatedUserAttributes();
		if (authenticatedUser) {
			const { username } = authenticatedUser;
			await Auth.signOut();
			ReactGA.event({
				category: 'authorisation',
				action: 'idlelogout',
				label: username
			});
		}
		this.setState({
			isAuthenticated: false,
			isAuthenticating: false,
			authGroups: []
		});
	}

	async componentDidMount() {
		try {
			const { idToken } = await Auth.currentSession();
			if (idToken) {
				const { authenticatedUserGroups: authGroups } =
					await getAuthenticatedUserGroups();
				this.userHasAuthenticated(true, authGroups);
			}
		} catch (e) {
			if (e !== 'No current user') {
				console.error('Unexpected error in App:componentDidMount', e);
			}
		}

		this.setState({
			isAuthenticating: false
		});
	}

	userHasAuthenticated = (isAuthenticated, authGroups) => {
		this.setState({
			isAuthenticated,
			authGroups
		});
	};

	render() {
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			authGroups: this.state.authGroups
		};

		return (
			!this.state.isAuthenticating && (
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					element={document}
					onIdle={this.onIdle.bind(this)}
					timeout={config.idleTimeout}
				>
					<Routes childProps={childProps} />
				</IdleTimer>
			)
		);
	}
}

export default withRouter(App);
