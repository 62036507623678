import moment from 'moment';
import { generateRandomColours } from '../../utils';
export const DATE_MIN = moment.utc('2017-01-01');
export const CURR_CAL_YEAR_START = moment.utc().startOf('y');
export const PREV_CAL_YEAR_START = moment.utc().subtract(1, 'y').startOf('y');
export const PREV_2_CAL_YEARS_START = moment
	.utc()
	.subtract(2, 'y')
	.startOf('y');
export const PREV_3_CAL_YEARS_START = moment
	.utc()
	.subtract(3, 'y')
	.startOf('y');
export const CURR_MONTH_START = moment.utc().startOf('month');

const buildYearColours = () => {
	const colours = generateRandomColours(
		moment.utc().diff('2017-01-01', 'years') + 1
	);
	return colours.map((c, i) => ({ year: `${2017 + i}`, colour: c }));
};
export const YEAR_COLOURS = buildYearColours();

//views
export const MAIN_VIEW = 'view_0';
export const SMELTERS_VIEW = 'view_1';
export const YEARS_VIEW = 'view_2';
