import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import {
	DATE_MIN,
	CURR_CAL_YEAR_START,
	PREV_CAL_YEAR_START,
	PREV_2_CAL_YEARS_START,
	PREV_3_CAL_YEARS_START
} from '../config';

const RangeButtonGroup = ({ groupClass, ids, start, disabled, onClick }) => {
	return (
		<ButtonGroup className={groupClass}>
			<Button
				id={ids[0]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={start === CURR_CAL_YEAR_START}
				aria-label={'Current Year'}
				className="light-active-border"
				disabled={disabled}
			>
				0Y
			</Button>
			<Button
				id={ids[1]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={start === PREV_CAL_YEAR_START}
				aria-label={'1 Year'}
				className="light-active-border"
				disabled={disabled}
			>
				1Y
			</Button>
			<Button
				id={ids[2]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={start === PREV_2_CAL_YEARS_START}
				aria-label={'2 Years'}
				className="light-active-border"
				disabled={disabled}
			>
				2Y
			</Button>
			<Button
				id={ids[3]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={start === PREV_3_CAL_YEARS_START}
				aria-label={'3 Years'}
				className="light-active-border"
				disabled={disabled}
			>
				3Y
			</Button>
			{ids[4] && (
				<Button
					id={ids[4]}
					outline
					size="sm"
					color="secondary"
					onClick={onClick}
					active={start === DATE_MIN}
					aria-label={'All Years'}
					className="light-active-border"
					disabled={disabled}
				>
					All
				</Button>
			)}
		</ButtonGroup>
	);
};

export default RangeButtonGroup;
