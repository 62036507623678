import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, Progress } from 'reactstrap';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';

import { Flag } from 'flag';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import {
	freeChartStyle,
	chartOptions
} from '../Home/smelterDashletChartConfig';
import { fetchSmelterDataFree } from 'actions';

import earthiLogo from 'images/earthi.svg';
import savantLogo from 'images/savant.svg';

export class Free extends Component {
	componentDidMount() {
		this.props.fetchSmelterDataFree();
		ReactGA.pageview('/free', null, 'Free Index');
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<p className="h6 dash-header">Chart data loading...</p>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	renderSmelterData() {
		const { smelterDataFree: smelterData = {} } = this.props;

		if (!smelterData.combine) {
			return this.buildLoadingMessage();
		}

		let datasets = [
			{
				label: 'Global index',
				data: [],
				...freeChartStyle,
				borderColor: '#FFA500',
				backgroundColor: '#FFA500',
				pointBackgroundColor: '#FFA500'
			}
		];
		smelterData.combine.forEach(({ t, f }) => {
			datasets[0].data.push({
				t: moment(t).format('YYYY-MM-DD'),
				y: f
			});
		});

		return <Line data={{ datasets }} options={chartOptions} height={150} />;
	}

	render() {
		return (
			<DocumentTitle title="SAVANT | Login">
				<BodyClassName className="bg-dark">
					<div className="container">
						<Card className="mx-auto mt-5 mb-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={savantLogo}
									className="card-logo"
									svgClassName="savant-card-logo"
								/>
								<h1 className="sr-only">SAVANT</h1>
							</CardHeader>
							<CardBody>
								<h2 className="h5">SAVANT Global Copper Smelting Index</h2>
								<p className="small text-muted">
									Below is the Demo Index, updated monthly. (
									<strong>Sign up</strong> for more options)
								</p>
								{this.renderSmelterData()}
								<div className="text-center mt-3" />
							</CardBody>
							<CardFooter className="text-center">
								<a className="small" href="/">
									Log in
								</a>
								<Flag
									name="features.showSignup"
									render={() => (
										<Fragment>
											<span className="text-muted"> | </span>
											<a className="small" href="/signUp">
												Sign up
											</a>
										</Fragment>
									)}
								/>
							</CardFooter>
						</Card>
					</div>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ smelterDataFree }) => {
	return {
		smelterDataFree
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchSmelterDataFree: () => dispatch(fetchSmelterDataFree())
});

export default connect(mapStateToProps, mapDispatchToProps)(Free);
