import { FETCH_NI_SMELTER_RECENT } from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_NI_SMELTER_RECENT:
			return action.payload;
		default:
			return state;
	}
};
