import { API } from 'aws-amplify';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import {
	getAuthHeaders,
	FETCH_STEEL_SMELTER_ALL_MAP_DATA,
	FETCH_STEEL_SMELTER_ALL_MAP_DATA_ERR,
	FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
	FETCH_STEEL_SMELTER_DATA,
	FETCH_STEEL_SMELTER_DATA_ERR,
	FETCH_STEEL_SMELTER_DATA_FREE,
	FETCH_STEEL_SMELTER_DATA_FREE_ERR,
	FETCH_STEEL_SMELTER_LIST,
	FETCH_STEEL_SMELTER_LIST_ERR,
	FETCH_STEEL_SMELTER_MAP_DATA,
	FETCH_STEEL_SMELTER_MAP_DATA_ERR,
	FETCH_STEEL_NEWEST_DATE,
	FETCH_STEEL_NEWEST_DATE_ERR,
	FETCH_STEEL_SMELTER_RECENT,
	FETCH_STEEL_SMELTER_RECENT_ERR,
	FETCH_STEEL_SMELTER_RECENT_UPDATES,
	FETCH_STEEL_SMELTER_RECENT_UPDATES_ERR,
	FETCH_STEEL_SMELTER_REGIONS,
	FETCH_STEEL_SMELTER_REGIONS_ERR,
	FETCH_STEEL_SMELTER_UPDATES,
	FETCH_STEEL_SMELTER_UPDATES_ERR,
	FETCH_STEEL_SMELTER_CAPACITY,
	FETCH_STEEL_SMELTER_CAPACITY_ERR,
	RESET_STEEL_SMELTER_MAP_DATA,
	RESET_STEEL_NEWEST_DATE,
	SELECT_STEEL_SMELTER,
	UNSELECT_STEEL_SMELTER
} from '../';
import config from '../../config';
import { replacer } from '../../utils';

export const fetchSteelSmelterAllMapData = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterAllMapData,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_ALL_MAP_DATA,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_ALL_MAP_DATA_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterAllTimeBySmelterData =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterAllTimeBySmelter, {
				smelterIds
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchSteelSmelterData =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterData, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_DATA,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchSteelSmelterDataFree = () => async (dispatch) => {
	await axios
		.get(config.apiGateway.URL + config.apiEndpoints.steelSmelterDataFree)
		.then((response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_DATA_FREE,
				payload: response.data
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_DATA_FREE_ERR,
				payload: err
			});
		});
};

export const fetchSteelSmelterMapData =
	({ regionId, searchDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterMapData, {
				regionId,
				searchDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_MAP_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_MAP_DATA_ERR,
					payload: err
				})
		);
	};

export const resetSteelSmelterMapData = () => ({
	type: RESET_STEEL_SMELTER_MAP_DATA
});

export const fetchSteelSmelterList = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.steelSmelterList, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_LIST,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_LIST_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterRecent =
	({ count = 4 }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterRecent, {
				count
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_RECENT,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_RECENT_ERR,
					payload: err
				})
		);
	};

export const fetchSteelSmelterRecentUpdates = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterRecentUpdates,
		{
			headers: await getAuthHeaders()
		}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT_UPDATES,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT_UPDATES_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterRegions = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterRegions,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_REGIONS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_REGIONS_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterUpdates =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterUpdates, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_UPDATES,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_UPDATES_ERR,
					payload: err
				})
		);
	};

export const fetchSteelSmelterCapacity =
({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
async (dispatch) => {
	const request = API.get(
		'savant-api',
		replacer(config.apiEndpoints.steelSmelterCapacity, {
			regions,
			startDate,
			endDate
		}),
		{
			headers: await getAuthHeaders()
		}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_CAPACITY,
				payload: response
			});
			if (response.compare && response.compare.length) {
				response.compare.forEach(({ key, data }) => {
					if (data.length === 0) {
						toastr.warning(`No capacity data found for ${key} `);
					}
				});
			} else if (response.combine && response.combine.length === 0) {
				toastr.warning(`No capacity data found for ${regions} `);
			}
		},
		(err) => {
			const message =
				(err.response && err.response.data && err.response.data.message) ||
				err;
			dispatch({
				type: FETCH_STEEL_SMELTER_CAPACITY_ERR,
				payload: err
			});
			toastr.error(`Get Capacity, ${message} `);
		}
	);
};

export const fetchSteelNewestDate = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.steelNewestDate, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_NEWEST_DATE,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_NEWEST_DATE_ERR,
				payload: err
			})
	);
};

export const resetSteelNewestDate = () => ({
	type: RESET_STEEL_NEWEST_DATE
});

export const resetSteelSmelterAllTimeBySmelterData = () => ({
	type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	payload: {}
});

export const resetSteelSmelterData = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STEEL_SMELTER_DATA, payload: [] });
	};
};

export const resetSteelSmelterCapacity = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STEEL_SMELTER_CAPACITY, payload: {} });
	};
};

export const resetSteelSmelterUpdates = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STEEL_SMELTER_UPDATES, payload: [] });
	};
};

export const selectSteelSmelter = (id) => ({
	type: SELECT_STEEL_SMELTER,
	payload: id
});

export const unselectSteelSmelter = (id) => ({
	type: UNSELECT_STEEL_SMELTER,
	payload: id
});
