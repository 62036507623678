import colours from '../../../_colours.scss';

//style for Inactive Capacity index
const fChartStyle = {
	fill: false,
	lineTension: 0.4,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointBackgroundColor: '#fff',
	pointBorderWidth: 1,
	pointHoverRadius: 2,
	pointHoverBorderColor: 'rgba(86,86,86,1)',
	pointHoverBorderWidth: 1,
	pointRadius: 2,
	pointHitRadius: 5
};

const annotationsStem = {
	events: ['mouseover', 'mouseout'],
	annotations: []
};

const annotationStem = {
	drawTime: 'afterDatasetsDraw',
	type: 'line',
	mode: 'vertical',
	scaleID: 'x-axis-0',
	borderColor: colours.earthiVividGreen,
	borderWidth: 2,
	label: {
		backgroundColor: colours.earthiGrassGreen,
		fontSize: 12,
		fontStyle: 'normal',
		fontColor: colours.earthiWhite,
		cornerRadius: 3,
		enabled: false,
		position: 'bottom',
		yAdjust: 20
	},
	onMouseover: function (e) {
		const element = this;
		for (let k in element.chartInstance.annotation.elements) {
			let anno = element.chartInstance.annotation.elements[k];
			if (anno.id !== this.id) {
				anno.options.borderColor = 'rgba(117, 182, 38, 0.3)';
			}
		}
		element.options.borderColor = colours.earthiGrassGreen;
		element.options.borderWidth = 3;
		element.options.label.enabled = true;
		element.chartInstance.update();
		element.chartInstance.chart.canvas.style.cursor = 'pointer';
	},
	onMouseout: function (e) {
		const element = this;
		setTimeout(() => {
			for (let k in element.chartInstance.annotation.elements) {
				let anno = element.chartInstance.annotation.elements[k];
				if (anno.id !== this.id) {
					anno.options.borderColor = colours.earthiVividGreen;
				}
			}
			element.options.borderColor = colours.earthiVividGreen;
			element.options.borderWidth = 2;
			element.options.label.enabled = false;
			element.chartInstance.update();
		}, 200);
		element.chartInstance.chart.canvas.style.cursor = 'initial';
	}
};

//options for Inactive Capacity index
const fChartOptions = {
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				distribution: 'linear',
				scaleLabel: {
					display: true
					// labelString: 'Time'
				},
				time: {
					displayFormats: {
						hour: 'D MMM YY',
						day: 'D MMM YY',
						week: 'D MMM YY',
						month: 'D MMM YY',
						quarter: 'D MMM YY',
						year: 'YYYY'
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				id: 'inactive-capacity',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Inactive Capacity'
				},
				ticks: {
					beginAtZero: false
					// min: 0
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20,
			filter: (item) => {
				return (
					!item.text.includes('Max') &&
					!item.text.includes('Min') &&
					!item.text.includes('threshold')
				);
			}
		}
	}
};

//capacity Chart options
const cChartOptions = {
	...fChartOptions,
	scales: {
		...fChartOptions.scales,
		yAxes: fChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			id: 'capacity',
			scaleLabel: {
				display: true,
				labelString: 'Capacity (kt)'
			}
		}))
	}
};
//style for Activity Dispersion Index
const aChartStyle = {
	...fChartStyle,
	fill: '-1',
	label: 'Activity Dispersion Index',
	borderColor: colours.earthiGrassGreen,
	pointBackgroundColor: colours.earthiGrassGreen
};

const aChartOptions = {
	...fChartOptions,
	//exclude tooltips from 50% line
	tooltips: {
		filter: function (tooltipItem) {
			return tooltipItem.datasetIndex > 0;
		}
	},
	scales: {
		...fChartOptions.scales,
		yAxes: [
			{
				distribution: 'linear',
				id: 'activity-dispersion',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Activity Dispersion'
				},
				ticks: {
					//beginAtZero: false,
					min: 0,
					max: 100
				}
			}
		]
	}
};

//style for horizontal line at 50% of activity dispersion plot
const aThresholdStyle = {
	fill: false,
	lineTension: 0.4,
	borderWidth: 1,
	borderColor: colours.savantCopper,
	label: 'threshold',
	pointRadius: 0,
	pointHitRadius: 0
};

const genChartStyle = function ({ size, type }) {
	const style = type === 'f' ? fChartStyle : aChartStyle;

	if (size === 'sm') {
		return {
			...style,
			...{
				pointHoverRadius: 3.5,
				pointRadius: 3.5,
				pointHitRadius: 3.5
			}
		};
	} else if (size === 'md') {
		return {
			...style,
			...{
				pointHoverRadius: 2.5,
				pointRadius: 2.5,
				pointHitRadius: 2.5
			}
		};
	} else if (size === 'lg') {
		return {
			...style,
			...{
				pointHoverRadius: 1.5,
				pointRadius: 1.5,
				pointHitRadius: 1.5
			}
		};
	} else if (size === 'xl') {
		return {
			...style,
			...{
				pointHoverRadius: 1,
				pointRadius: 1,
				pointHitRadius: 1
			}
		};
	} else if (size === 'xxl') {
		return {
			...style,
			...{
				pointHoverRadius: 0.5,
				pointRadius: 0,
				pointHitRadius: 0.5
			}
		};
	}

	return {
		...style
	};
};

const aStripChartStyle = {
	fill: false,
	lineTension: 0,
	borderColor: colours.earthiGrassGreen,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointBackgroundColor: '#fff',
	pointRadius: 1,
	pointBorderWidth: 1,
	pointHoverRadius: 2,
	pointHoverBorderWidth: 1,
	pointHoverBorderColor: 'rgba(86,86,86,1)',
	pointHitRadius: 2
};

const rStripChartStyle = {
	...aStripChartStyle,
	borderColor: colours.earthiDeepSea
};

const aStripChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		duration: 0
	},
	layout: {
		padding: {
			left: 6,
			right: 7,
			top: 5,
			bottom: 5
		}
	},
	title: {
		display: false
	},
	legend: {
		display: false
	},

	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem, data) {},

			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel}  ${tooltipItem.xLabel}`;
			}
		}
	},

	scales: {
		xAxes: [
			{
				type: 'time',
				time: {
					unit: 'month'
				},
				ticks: {
					display: false
				},
				gridLines: {
					drawBorder: false,
					zeroLineWidth: 1,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)',
					tickMarkLength: 1
				}
			}
		],
		yAxes: [
			{
				gridLines: {
					drawBorder: false,
					tickMarkLength: 0,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)'
				},
				ticks: {
					display: false,
					min: 0,
					max: 100
				}
			}
		]
	},
	annotation: {
		annotations: [
			{
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: 50,
				borderColor: colours.savantCopper,
				borderWidth: 1
			}
		]
	}
};

const rStripChartOptions = {
	...aStripChartOptions,
	annotation: {},
	scales: {
		...aStripChartOptions.scales,
		yAxes: aStripChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			ticks: {
				display: false,
				min: 0
			}
		}))
	}
};

const plugins = [
	{
		//plugin which adds different coloured shaded area above and below 50 line
		beforeRender: function (chart) {
			const posColour = colours.earthiVividGreenAlpha;
			const negColour = colours.savantSmelterIdentifyAlpha;
			const yScale = chart.scales['activity-dispersion'];
			if (!yScale) return;

			chart.data.datasets.forEach((ds, i) => {
				//skip threshold line and do not shade multiple plots
				if (i === 0 || i > 1 || (ds.data && !ds.data.length)) return;

				const yValues = ds.data.filter((d) => d.y !== null).map((d) => d.y);
				const max = Math.max(...yValues);
				const min = Math.min(...yValues);

				const zero = yScale.getPixelForValue(50);
				const top = max > 50 ? yScale.getPixelForValue(max) : zero;
				const bottom = min < 50 ? yScale.getPixelForValue(min) : zero;
				const ratio = Math.min((zero - top) / (bottom - top), 1);
				const gradientFill = chart.ctx.createLinearGradient(0, top, 0, bottom);
				gradientFill.addColorStop(0, posColour);
				gradientFill.addColorStop(ratio, posColour);
				gradientFill.addColorStop(ratio, negColour);
				gradientFill.addColorStop(1, negColour);

				ds._meta[Object.keys(ds._meta)].dataset._model.backgroundColor =
					gradientFill;
			});
		}
	}
];
export {
	fChartStyle,
	aChartStyle,
	aThresholdStyle,
	fChartOptions,
	aChartOptions,
	cChartOptions,
	genChartStyle,
	annotationsStem,
	annotationStem,
	aStripChartStyle,
	rStripChartStyle,
	aStripChartOptions,
	rStripChartOptions,
	plugins
};
