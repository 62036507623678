import { API } from 'aws-amplify';

import { getAuthHeaders, FETCH_PSEUDOGROUPS, FETCH_PSEUDOGROUPS_ERR } from './';
import config from '../config';
import { replacer } from '../utils';

export const fetchPseudoGroups =
	({ ute }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.pseudoGroups, {
				ute
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_PSEUDOGROUPS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_PSEUDOGROUPS_ERR,
					payload: err
				})
		);
	};

export const resetPseudoGroups = () => ({
	type: FETCH_PSEUDOGROUPS,
	payload: []
});
