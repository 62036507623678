//cognito user groups
const allTypes = ['cu', 'ni', 'steel'];

const cognitoGroups = {
	free: {
		cu: {
			required: 'savant-free',
			excludes: [
				'savant-premium',
				'savant-premium-plus',
				'sales',
				'savant-enterprise'
			]
		},
		ni: {
			required: 'savant-ni-free',
			excludes: [
				'savant-ni-premium',
				'savant-ni-premium-plus',
				'sales-ni',
				'savant-ni-enterprise'
			]
		},
		steel: {
			required: 'savant-steel-free',
			excludes: [
				'savant-steel-premium',
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premium: {
		cu: {
			required: 'savant-premium',
			excludes: ['savant-premium-plus', 'sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium',
			excludes: ['savant-ni-premium-plus', 'sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium',
			excludes: [
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premiumPlus: {
		cu: {
			required: 'savant-premium-plus',
			excludes: ['sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium-plus',
			excludes: ['sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium-plus',
			excludes: ['sales-steel', 'savant-steel-enterprise']
		}
	},

	sales: {
		cu: {
			required: 'sales',
			excludes: ['savant-enterprise']
		},
		ni: {
			required: 'sales-ni',
			excludes: ['savant-ni-enterprise']
		},
		steel: {
			required: 'sales-steel',
			excludes: ['savant-steel-enterprise']
		}
	},

	enterprise: {
		cu: {
			required: 'savant-enterprise',
			excludes: []
		},
		ni: {
			required: 'savant-ni-enterprise',
			excludes: []
		},
		steel: {
			required: 'savant-steel-enterprise',
			excludes: []
		}
	}
};

const production = {
	apiGateway: {
		URL: 'https://savant-api.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_9e4g0FdJV',
		APP_CLIENT_ID: '31p9bp9us2j1g003pj0vl9i85a',
		IDENTITY_POOL_ID: 'eu-west-2:93f85d7c-3901-4f39-ab1e-9ced08f166eb'
	},
	ion: {
		accessToken:
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhY2JlMzU3YS1jYzE5LTRiZmEtODIxZS1mMzJjYzc5ZDRiNTgiLCJpZCI6NzI5OSwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0ODkyOTAzNH0.7A09gCCvMbmfXDHnZteGhPb669375BsKPoR1lpoNK70'
	},
	google: {
		ANALYTICS_ID: 'UA-127386606-1',
		TEST_MODE: false
	},
	idleTimeout: 1000 * 60 * 30
};

const staging = {
	apiGateway: {
		URL: 'https://savant-api-staging.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_9qMuAsGfQ',
		APP_CLIENT_ID: '4dj8gnt3uh3qh5aeqm498po6e0',
		IDENTITY_POOL_ID: 'eu-west-2:4d70eff0-0679-4489-a15d-a76f6f325e87'
	},
	ion: {
		accessToken:
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhY2JlMzU3YS1jYzE5LTRiZmEtODIxZS1mMzJjYzc5ZDRiNTgiLCJpZCI6NzI5OSwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU0ODkyOTAzNH0.7A09gCCvMbmfXDHnZteGhPb669375BsKPoR1lpoNK70'
	},
	google: {
		ANALYTICS_ID: 'UA-127386606-1',
		TEST_MODE: true
	},
	testUser: {
		username: process.env.E2E_USER,
		password: process.env.E2E_PASSWORD
	},
	idleTimeout: 1000 * 60 * 30
};

const config =
	process.env.REACT_APP_STAGE === 'production' ? production : staging;

const apiEndpoints = {
	kmlDownload: '/kml-download?type=${type}', // eslint-disable-line no-template-curly-in-string
	mySmelters: '/my-smelters',
	// 'ni'
	niNewestDate: '/last-upload-date?type=ni',
	niPseudoGroups: '/pseudo-groups?type=ni&ute=${ute}', // eslint-disable-line no-template-curly-in-string
	niSmelterAllMapData: '/smelter-map-data-all?type=ni',
	niSmelterAllTimeBySmelter:
		'/smelter-data-all-by-smelter?smelterIds=${smelterIds}&type=ni', // eslint-disable-line no-template-curly-in-string
	niSmelterData:
		'/smelter-data?type=ni&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	niSmelterDataFree: '/smelter-data-free?type=ni',
	niSmelterList: '/smelter-list?type=ni',
	niSmelterMapData:
		'/smelter-map-data?type=ni&region=${regionId}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	niSmelterRecent: '/smelter-map-data-recent?type=ni&count=${count}', // eslint-disable-line no-template-curly-in-string
	niSmelterRecentUpdates: '/smelter-map-recent-updates?type=ni',
	niSmelterRegions: '/smelter-map-regions?type=ni',
	niSmelterUpdates:
		'/smelter-data-updates?type=ni&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	niSmelterCapacity:
		'/smelter-capacity?type=ni&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	niProduction:
		'/smelter-production?type=ni&regions=${regions}&ute=${ute}&capAdd=${capAdd}&period=${period}&startDate=${startDate}', // eslint-disable-line no-template-curly-in-string
	niPseudoGroupSmelters:
		'/pseudo-group-smelters?type=ni&pg=${pg}&startDate=${startDate}', // eslint-disable-line no-template-curly-in-string
	niProductionSmelters:
		'/smelter-production-by-smelter?type=ni&smelterIds=${smelterIds}&period=${period}&ute=${ute}&startDate=${startDate}', // eslint-disable-line no-template-curly-in-string

	// 'steel'
	steelNewestDate: '/last-upload-date?type=steel',
	steelPseudoGroups: '/pseudo-groups?type=steel',
	steelSmelterAllMapData: '/smelter-map-data-all?type=steel',
	steelSmelterAllTimeBySmelter:
		'/smelter-data-all-by-smelter?smelterIds=${smelterIds}&type=steel', // eslint-disable-line no-template-curly-in-string
	//old way
	// steelSmelterData:
	// 	'/smelter-data?type=steel&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterData:
		'/smelter-data-ferrous?type=steel&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterDataFree: '/smelter-data-free?type=steel',
	steelSmelterList: '/smelter-list?type=steel',
	steelSmelterMapData:
		'/smelter-map-data?type=steel&region=${regionId}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterRecent: '/smelter-map-data-recent?type=steel&count=${count}', // eslint-disable-line no-template-curly-in-string
	steelSmelterRecentUpdates: '/smelter-map-recent-updates?type=steel',
	steelSmelterRegions: '/smelter-map-regions?type=steel',
	steelSmelterUpdates:
		'/smelter-data-updates?type=steel&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterCapacity:
		'/smelter-capacity?type=steel&regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string

	// 'cu'
	newestDate: '/last-upload-date',
	paramListBySmelter:
		'/smelter-param-list-by-smelter?smelterIds=${smelterId}&type=${type}', // eslint-disable-line no-template-curly-in-string
	pseudoGroups: '/pseudo-groups?ute=${ute}', // eslint-disable-line no-template-curly-in-string
	smelterAllMapData: '/smelter-map-data-all',
	smelterAllTimeBySmelter:
		'/smelter-data-all-by-smelter?smelterIds=${smelterIds}', // eslint-disable-line no-template-curly-in-string
	smelterData:
		'/smelter-data?regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	smelterDataFree: '/smelter-data-free',
	smelterList: '/smelter-list',
	smelterMapData:
		'/smelter-map-data?region=${regionId}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	smelterRecent: '/smelter-map-data-recent?count=${count}', // eslint-disable-line no-template-curly-in-string
	smelterRecentUpdates: '/smelter-map-recent-updates',
	smelterRegions: '/smelter-map-regions',
	smelterSubscribeUser: '/subscribe-smelter-updates?smelterIds=${smelterIds}', // eslint-disable-line no-template-curly-in-string
	smelterUnsubscribeUser:
		'/unsubscribe-smelter-updates?smelterIds=${smelterIds}', // eslint-disable-line no-template-curly-in-string
	smelterUpdates:
		'/smelter-data-updates?regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	smelterCapacity:
		'/smelter-capacity?regions=${regions}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	production:
		'/smelter-production?regions=${regions}&ute=${ute}&capAdd=${capAdd}&period=${period}&startDate=${startDate}', // eslint-disable-line no-template-curly-in-string
	pseudoGroupSmelters: '/pseudo-group-smelters?pg=${pg}&startDate=${startDate}', // eslint-disable-line no-template-curly-in-string
	productionSmelters:
		'/smelter-production-by-smelter?smelterIds=${smelterIds}&period=${period}&ute=${ute}&startDate=${startDate}' // eslint-disable-line no-template-curly-in-string
};

export default {
	apiEndpoints,
	allTypes,
	cognitoGroups,
	...config
};
