import { API } from 'aws-amplify';

import {
	getAuthHeaders,
	FETCH_PRODUCTION_DATA,
	RESET_PRODUCTION_DATA,
	FETCH_PRODUCTION_DATA_ERR,
	FETCH_PSEUDOGROUP_SMELTERS,
	FETCH_PSEUDOGROUP_SMELTERS_ERR,
	FETCH_PRODUCTION_SMELTERS_DATA,
	FETCH_PRODUCTION_SMELTERS_DATA_ERR,
	SELECT_PROD_SMELTER,
	UNSELECT_PROD_SMELTER,
	RESET_PROD_SMELTERS
} from './';
import config from '../config';
import { replacer } from '../utils';

export const fetchProductionData =
	({ regions, ute = '', capAdd = '', period, startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.production, {
				regions,
				ute,
				capAdd,
				period,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_PRODUCTION_DATA,
					payload: { [period]: response }
				}),
			(err) =>
				dispatch({
					type: FETCH_PRODUCTION_DATA_ERR,
					payload: err
				})
		);
	};

export const resetProductionData = () => {
	return {
		type: RESET_PRODUCTION_DATA
	};
};

export const fetchPseudoGroupSmelters =
	({ pg, startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.pseudoGroupSmelters, {
				pg,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_PSEUDOGROUP_SMELTERS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_PSEUDOGROUP_SMELTERS_ERR,
					payload: err
				})
		);
	};

export const resetPseudoGroupSmelters = () => ({
	type: FETCH_PSEUDOGROUP_SMELTERS,
	payload: []
});

export const fetchProductionSmeltersData =
	({ smelterIds, period, ute = '', startDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.productionSmelters, {
				smelterIds,
				period,
				ute,
				startDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_PRODUCTION_SMELTERS_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_PRODUCTION_SMELTERS_DATA_ERR,
					payload: err
				})
		);
	};

export const resetProductionSmeltersData = () => ({
	type: FETCH_PRODUCTION_SMELTERS_DATA,
	payload: {}
});

export const selectProdSmelter = (key) => ({
	type: SELECT_PROD_SMELTER,
	payload: key
});

export const unselectProdSmelter = (key) => ({
	type: UNSELECT_PROD_SMELTER,
	payload: key
});

export const resetProdSmelters = () => ({
	type: RESET_PROD_SMELTERS
});
