import { API } from 'aws-amplify';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import {
	getAuthHeaders,
	FETCH_SMELTER_ALL_MAP_DATA,
	FETCH_SMELTER_ALL_MAP_DATA_ERR,
	FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
	FETCH_SMELTER_DATA,
	FETCH_SMELTER_DATA_ERR,
	FETCH_SMELTER_DATA_FREE,
	FETCH_SMELTER_DATA_FREE_ERR,
	FETCH_SMELTER_LIST,
	FETCH_SMELTER_LIST_ERR,
	FETCH_SMELTER_MAP_DATA,
	FETCH_SMELTER_MAP_DATA_ERR,
	FETCH_SMELTER_RECENT,
	FETCH_SMELTER_RECENT_ERR,
	FETCH_SMELTER_RECENT_UPDATES,
	FETCH_SMELTER_RECENT_UPDATES_ERR,
	FETCH_SMELTER_CAPACITY,
	FETCH_SMELTER_CAPACITY_ERR,
	FETCH_SMELTER_REGIONS,
	FETCH_SMELTER_REGIONS_ERR,
	FETCH_SMELTER_UPDATES,
	FETCH_SMELTER_UPDATES_ERR,
	FETCH_MYSMELTERS,
	FETCH_MYSMELTERS_ERR,
	FETCH_MY_FAV_SMELTERS,
	FETCH_NEWEST_DATE,
	FETCH_NEWEST_DATE_ERR,
	RESET_SMELTER_MAP_DATA,
	RESET_NEWEST_DATE,
	SHRINK_SMELTERS_ALL_TIME_MAP_DATA,
	SELECT_SMELTER,
	UNSELECT_SMELTER,
	ADD_MY_FAV_SMELTERS,
	REMOVE_MY_FAV_SMELTERS,
	RESET_MY_FAV_SMELTERS_ERR,
	UPDATE_MY_FAV_SMELTERS_ERR
} from './';
import config from '../config';
import { replacer } from '../utils';

export const fetchMySmelters = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.mySmelters, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_MYSMELTERS,
				payload: response
			});
			dispatch({
				type: FETCH_MY_FAV_SMELTERS,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_MYSMELTERS_ERR,
				payload: err
			})
	);
};

export const resetMySmelters = () => (dispatch) => {
	dispatch({
		type: FETCH_MYSMELTERS,
		payload: []
	});

	dispatch({
		type: FETCH_MY_FAV_SMELTERS,
		payload: []
	});
};

export const selectSmelter = (id) => ({
	type: SELECT_SMELTER,
	payload: id
});

export const unselectSmelter = (id) => ({
	type: UNSELECT_SMELTER,
	payload: id
});

export const fetchSmelterAllMapData = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.smelterAllMapData, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SMELTER_ALL_MAP_DATA,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SMELTER_ALL_MAP_DATA_ERR,
				payload: err
			})
	);
};

export const fetchSmelterAllTimeBySmelterData =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterAllTimeBySmelter, {
				smelterIds
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const shrinkSmelterAllTimeMapData =
	({ smelterData }) =>
	(dispatch) => {
		const { capacity } = smelterData;
		const shrinkedData = {};
		Object.entries(capacity).forEach(([name, { data }]) => {
			shrinkedData[name] = !data.length
				? []
				: data.reduce(
						(result, item, idx) => {
							if (
								idx === data.length - 1 ||
								(idx !== 0 && data[idx].v !== data[idx - 1].v)
							) {
								result.push(item);
							}
							return result;
						},
						[data[0]]
				  );
		});

		dispatch({
			type: SHRINK_SMELTERS_ALL_TIME_MAP_DATA,
			payload: shrinkedData
		});
	};

export const resetShrinkedSmelterAllTimeMapData = () => ({
	type: SHRINK_SMELTERS_ALL_TIME_MAP_DATA,
	payload: {}
});

export const fetchSmelterParamList = async ({ smelterId, type }) => {
	try {
		const response = await API.get(
			'savant-api',
			replacer(config.apiEndpoints.paramListBySmelter, {
				smelterId,
				type
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return response;
	} catch (err) {
		console.log('Error getting smelter param list', err);
		return {};
	}
};

export const fetchSmelterData =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterData, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SMELTER_DATA,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchSmelterDataFree = () => async (dispatch) => {
	await axios
		.get(config.apiGateway.URL + config.apiEndpoints.smelterDataFree)
		.then((response) => {
			dispatch({
				type: FETCH_SMELTER_DATA_FREE,
				payload: response.data
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_SMELTER_DATA_FREE_ERR,
				payload: err
			});
		});
};

export const fetchSmelterMapData =
	({ regionId, searchDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterMapData, {
				regionId,
				searchDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_SMELTER_MAP_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_SMELTER_MAP_DATA_ERR,
					payload: err
				})
		);
	};

export const resetSmelterMapData = () => ({
	type: RESET_SMELTER_MAP_DATA
});

export const fetchSmelterList = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.smelterList, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_SMELTER_LIST,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_SMELTER_LIST_ERR,
				payload: err
			})
	);
};

export const fetchSmelterRecent =
	({ count = 4 }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterRecent, {
				count
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SMELTER_RECENT,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SMELTER_RECENT_ERR,
					payload: err
				})
		);
	};

export const fetchSmelterRecentUpdates = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.smelterRecentUpdates,
		{
			headers: await getAuthHeaders()
		}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_SMELTER_RECENT_UPDATES,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_SMELTER_RECENT_UPDATES_ERR,
				payload: err
			})
	);
};

export const fetchSmelterRegions = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.smelterRegions, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SMELTER_REGIONS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SMELTER_REGIONS_ERR,
				payload: err
			})
	);
};

export const fetchSmelterUpdates =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterUpdates, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SMELTER_UPDATES,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SMELTER_UPDATES_ERR,
					payload: err
				})
		);
	};
export const fetchSmelterCapacity =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.smelterCapacity, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SMELTER_CAPACITY,
					payload: response
				});
				if (response.compare && response.compare.length) {
					response.compare.forEach(({ key, data }) => {
						if (data.length === 0) {
							toastr.warning(`No capacity data found for ${key} `);
						}
					});
				} else if (response.combine && response.combine.length === 0) {
					toastr.warning(`No capacity data found for ${regions} `);
				}
			},
			(err) => {
				const message =
					(err.response && err.response.data && err.response.data.message) ||
					err;
				dispatch({
					type: FETCH_SMELTER_CAPACITY_ERR,
					payload: err
				});
				toastr.error(`Get Capacity, ${message} `);
			}
		);
	};
export const fetchNewestDate = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.newestDate, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_NEWEST_DATE,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_NEWEST_DATE_ERR,
				payload: err
			})
	);
};

export const resetNewestDate = () => ({
	type: RESET_NEWEST_DATE
});

export const resetSmelterAllTimeBySmelterData = () => ({
	type: FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	payload: {}
});

export const resetSmelterData = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SMELTER_DATA, payload: [] });
	};
};

export const resetSmelterUpdates = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SMELTER_UPDATES, payload: [] });
	};
};

export const resetSmelterCapacity = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SMELTER_CAPACITY, payload: {} });
	};
};

export const addMyFavSmelters =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.post(
			'savant-api',
			replacer(config.apiEndpoints.smelterSubscribeUser, {
				smelterIds
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: ADD_MY_FAV_SMELTERS,
					payload: smelterIds
				});
				toastr.success('Add Smelters', 'Successful');
			},
			(err) => {
				const message =
					(err.response && err.response.data && err.response.data.message) ||
					err;
				dispatch({
					type: UPDATE_MY_FAV_SMELTERS_ERR,
					payload: message
				});
				toastr.error('Add Smelters', `${message}`);
			}
		);
	};

export const removeMyFavSmelters =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.del(
			'savant-api',
			replacer(config.apiEndpoints.smelterUnsubscribeUser, {
				smelterIds
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: REMOVE_MY_FAV_SMELTERS,
					payload: smelterIds
				});
				toastr.success('Remove Smelters', 'Successful');
			},
			(err) => {
				const message =
					(err.response && err.response.data && err.response.data.message) ||
					err;
				dispatch({
					type: UPDATE_MY_FAV_SMELTERS_ERR,
					payload: message
				});
				toastr.error(`Remove Smelters, ${message} `);
			}
		);
	};

export const resetMyFavSmeltersErr = () => {
	return (dispatch) => {
		dispatch({ type: RESET_MY_FAV_SMELTERS_ERR });
	};
};
