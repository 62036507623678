import {
	FETCH_NI_SMELTER_MAP_DATA,
	RESET_NI_SMELTER_MAP_DATA
} from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_NI_SMELTER_MAP_DATA:
			return action.payload;
		case RESET_NI_SMELTER_MAP_DATA:
			return [];
		default:
			return state;
	}
};
