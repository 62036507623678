import { FETCH_PSEUDOGROUP_SMELTERS } from '../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_PSEUDOGROUP_SMELTERS:
			return action.payload;

		default:
			return state;
	}
};
