const messages = {
	CodeMismatchException:
		'Invalid verification code provided. Please try again.',
	InternalErrorException: 'An unexpected error has occurred',
	InvalidParameterException: 'Please check your form values and resubmit',
	InvalidPasswordException:
		'A password must be more than 7 characters and include an upper case letter, a lower case letter, a special character and a number',
	LimitExceededException:
		'You have attempted to use the security system too many times. Please wait a few minutes and try again.',
	NotAuthorizedException: 'Log in failed',
	ServiceError: 'There is a problem communicating with the security system',
	TooManyFailedAttemptsException:
		'You have attempted to use the security system too many times. Please wait a few minutes and try again.',
	TooManyRequestsException:
		'You have attempted to use the security system too many times. Please wait a few minutes and try again.',
	UserNotConfirmedException: 'Your security credentials are not correct',
	UserNotFoundException: 'Your security credentials are not correct',
	UsernameExistsException: 'Your username is already in use'
};

export default ({ code, message }) => messages[code] || message;
