import React, { Component, Fragment } from 'react';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';

import { Flag } from 'flag';

import {
	getAuthenticatedUserGroups,
	getAuthenticatedUserAttributes
} from 'actions';
import { cognitoError, LoaderButton } from 'components';

import {
	isSelectorValid,
	getEnterpriseTypes,
	getSalesTypes,
	getPremiumPlusTypes,
	getPremiumTypes
} from '../../utils';

import earthiLogo from 'images/earthi.svg';
import savantLogo from 'images/savant.svg';
import esaLogo from 'images/esa.png';
// import marexSpectronLogo from 'images/marex_spectron.png';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isAutofill: false,
			username: '',
			password: '',
			showPassword: false,
			errorMsg: ''
		};

		this.unRef = React.createRef();
		this.pwdRef = React.createRef();
	}

	componentDidMount() {
		ReactGA.pageview('/', null, 'Login');

		isSelectorValid(':-internal-autofill-selected') &&
			setTimeout(() => {
				if (
					this.unRef.current.matches(':-internal-autofill-selected') &&
					this.pwdRef.current.matches(':-internal-autofill-selected')
				) {
					this.setState({ isAutofill: true });
				}
			}, 650);
	}

	validateForm() {
		return this.state.username.length > 0 && this.state.password.length > 0;
	}

	handleChange = (event) => {
		const { isAutofill } = this.state;
		const newIsAutofill = isAutofill ? !isAutofill : isAutofill;
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: '',
			isAutofill: newIsAutofill
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			await Auth.signIn(this.state.username, this.state.password);
			const { username } = await getAuthenticatedUserAttributes();
			const { authenticatedUserGroups: authGroups } =
				await getAuthenticatedUserGroups();
			await ReactGA.event({
				category: 'authorisation',
				action: 'login',
				label: username
			});
			this.props.userHasAuthenticated(true, authGroups);
			let types = getEnterpriseTypes(authGroups);
			if (!types.length) {
				types = getSalesTypes(authGroups);
			}
			if (!types.length) {
				types = getPremiumPlusTypes(authGroups);
			}
			if (!types.length) {
				types = getPremiumTypes(authGroups);
			}

			if (types.length) {
				this.props.history.push(
					`${types[0] === 'cu' ? '/home' : `${types[0]}/home/`}`
				);
			} else {
				this.props.history.push('/home');
			}
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	toggleShowPassword = () =>
		this.setState({ showPassword: !this.state.showPassword });

	render() {
		return (
			<DocumentTitle title="SAVANT | Login">
				<BodyClassName className="bg-dark">
					<div className="container">
						<Card className="card-login mx-auto mt-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={savantLogo}
									className="card-logo"
									svgClassName="savant-card-logo"
								/>
								<h1 className="sr-only">SAVANT</h1>
							</CardHeader>
							<CardBody>
								<Form onSubmit={this.handleSubmit}>
									<FormGroup>
										<Label for="username">Username or Email</Label>
										<Input
											id="username"
											placeholder="Enter username or email address"
											autoFocus
											value={this.state.username}
											onChange={this.handleChange}
											innerRef={this.unRef}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="password">Password</Label>
										<InputGroup>
											<Input
												id="password"
												type={this.state.showPassword ? 'text' : 'password'}
												placeholder="Enter password"
												value={this.state.password}
												onChange={this.handleChange}
												innerRef={this.pwdRef}
											/>
											<InputGroupAddon addonType="append">
												<Button
													outline
													color="secondary"
													onClick={this.toggleShowPassword}
												>
													<i
														className={
															this.state.showPassword
																? 'fa fa-eye fa-lg'
																: 'fa fa-eye-slash fa-lg'
														}
													/>
													<span className="sr-only">
														Toggle password display
													</span>
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</FormGroup>
									{this.state.errorMsg && (
										<Alert color="warning" className="py-2 px-3">
											{this.state.errorMsg}
										</Alert>
									)}
									<LoaderButton
										block
										color="primary"
										disabled={!this.state.isAutofill && !this.validateForm()}
										type="submit"
										isLoading={this.state.isLoading}
										text="Log in"
										loadingText="Logging in…"
									/>
								</Form>
								<div className="text-center mt-3">
									<Flag
										name="features.showSignup"
										render={() => (
											<Fragment>
												<a className="small" href="/signUp">
													Sign up
												</a>
												<span className="text-muted"> | </span>
											</Fragment>
										)}
									/>
									<a className="small" href="/forgotPassword">
										Forgot password
									</a>
								</div>
								<hr />
								<div className="mt-3 small">
									In association with:
									<br />
									<a
										href="https://www.esa.int/ESA"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={esaLogo}
											className="card-logo esa-card-logo pull-left"
											alt="European Space Agency"
										/>
									</a>
									{/* <a
										href="http://www.marexspectron.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={marexSpectronLogo}
											className="card-logo marex-card-logo pull-right"
											alt="Marex Spectron"
										/>
									</a> */}
								</div>
							</CardBody>
						</Card>
						<Card className="card-login mx-auto mt-2">
							<CardBody>
								<div className="smaller">
									<strong>System access disclaimer:</strong> This is a
									restricted computer system which may be accessed and used only
									by authorised personnel. UNAUTHORISED ACCESS OR USE OF THIS
									COMPUTER SYSTEM IS STRICTLY PROHIBITED AND CONSTITUTES AN
									OFFENCE UNDER THE COMPUTER MISUSE ACT 1990 and may subject
									violators to criminal, civil, and/or administrative action.
									All access and use of this computer system is monitored and
									audited by authorised personnel, therefore there should not be
									any expectations of Right to Privacy on this computer system.
									All activity is subject to being intercepted, recorded, read,
									copied and disclosed by and to authorised personnel for
									official purposes, including criminal investigations. Access
									or use of this computer system by any person, whether
									authorised or unauthorised, constitutes consent to these
									terms.
								</div>
							</CardBody>
						</Card>
					</div>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
