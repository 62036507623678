import {
	SELECT_NI_PROD_SMELTER,
	UNSELECT_NI_PROD_SMELTER,
	RESET_NI_PROD_SMELTERS
} from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case SELECT_NI_PROD_SMELTER:
			return [...state, action.payload];
		case UNSELECT_NI_PROD_SMELTER:
			return state.filter((item) => item !== action.payload);
		case RESET_NI_PROD_SMELTERS:
			return [];

		default:
			return state;
	}
};
