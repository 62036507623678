import React from 'react';

import { InlineHelp } from 'components';

export const smeltersCapacityInlineHelpContent = (
	<InlineHelp
		title="Smelter Capacity Help"
		tooltip="Information about the Smelter Capacity"
		tooltipId="smelterCapacityHelp"
	>
		<h5>How to use the Smelter Capacity feature</h5>
		<p className="small">
			This feature provides the total smelting capacity included in SAVANT
			globally or for each region, country or group (such as nickel metal
			grade). Capacities in regions, countries or groups can be combined,
			although different categories can't be combined, for example, a region and
			a country. The capacities are provided graphically or via download for all
			dates. Capacities for new smelters or lines are added to SAVANT when we
			first detect their activity; capacities are adjusted for expansions or
			shutdowns based on announcements or information received. Most capacity
			information is based on ICSG data (for copper) or INSG data (for nickel).
		</p>
		<p className="small">
			The operation of the dashboard on this page is the same as for the Smelter
			Operations page, so please access the Help for that page for full details.
			The only differences are that the data shown is smelter capacity instead
			of index data, and there is no data smoothing option.
		</p>
	</InlineHelp>
);
