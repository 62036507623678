import {
	FETCH_NI_SMELTER_CAPACITY,
	FETCH_NI_SMELTER_CAPACITY_ERR
} from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_NI_SMELTER_CAPACITY:
			return action.payload;
		case FETCH_NI_SMELTER_CAPACITY_ERR:
			return { combine: [], compare: [] };
		default:
			return state;
	}
};
