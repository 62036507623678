import { API } from 'aws-amplify';

import { getAuthHeaders } from 'actions';
import config from 'config';
import { replacer } from '../utils';

export const downloadKml = async ({ body, type }) => {
	return await API.post(
		'savant-api',
		replacer(config.apiEndpoints.kmlDownload, {
			type
		}),
		{
			headers: await getAuthHeaders(),
			body,
			responseType: 'text'
		}
	);
};
