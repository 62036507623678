import React, { Component, Fragment } from 'react';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import {
	Button,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	Popover,
	PopoverBody,
	UncontrolledTooltip
} from 'reactstrap';

import { jsDate } from '../utils';

const dateDisplayFormat = 'MMMM D, YYYY';

export default class DateSkipper extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.onDatePick = this.onDatePick.bind(this);

		this.state = {
			popoverOpen: false
		};
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	async dayChange({ days }) {
		let { selectedDate } = this.props;
		const newSelectedDate = selectedDate.clone().add(days, 'days');
		this.props.onDateSelected({
			selectedDate: moment(newSelectedDate)
		});
	}

	async onDatePick(selectedDate) {
		this.props.onDateSelected({
			selectedDate: moment(selectedDate)
		});
		setTimeout(async () => {
			this.toggle();
		}, 600);
	}

	render() {
		const {
			id,
			className,
			weekAdv = false,
			monthAdv = false,
			selectedDate,
			maxDate,
			minDate,
			newestDate
		} = this.props;

		const maxDayDiff = maxDate.diff(selectedDate, 'days');
		const minDayDiff = selectedDate.diff(minDate, 'days');

		const monthAdvDownDisabled = !(minDayDiff >= 30);
		const weekAdvDownDisabled = !(minDayDiff >= 7);
		const weekAdvUpDisabled = !(maxDayDiff >= 7);
		const monthAdvUpDisabled = !(maxDayDiff >= 30);

		return (
			<Fragment>
				<Label
					for={`DateSkipper-${id}`}
					className={`date-skipper ${className}`}
				>
					<span className="sr-only">Please select a date</span>
					<InputGroup>
						{monthAdv && (
							<InputGroupAddon addonType="prepend">
								<Button
									id={`DateSkipper-${id}-daydown30`}
									color="secondary"
									outline
									size="sm"
									disabled={monthAdvDownDisabled}
									onClick={() => this.dayChange({ days: -30 })}
								>
									<i className="fa fa-angle-double-left fa-lg" />
								</Button>
								<UncontrolledTooltip
									placement="top"
									delay={500}
									target={`DateSkipper-${id}-daydown30`}
								>
									Back 30 days
								</UncontrolledTooltip>
							</InputGroupAddon>
						)}
						{weekAdv && (
							<InputGroupAddon addonType="prepend">
								<Button
									id={`DateSkipper-${id}-daydown7`}
									color="secondary"
									outline
									size="sm"
									disabled={weekAdvDownDisabled}
									onClick={() => this.dayChange({ days: -7 })}
								>
									<i className="fa fa-angle-left fa-lg" />
								</Button>
								<UncontrolledTooltip
									placement="top"
									delay={500}
									target={`DateSkipper-${id}-daydown7`}
								>
									Back 7 days
								</UncontrolledTooltip>
							</InputGroupAddon>
						)}
						<Input
							type="text"
							id={'DatePicker-' + this.props.id}
							onClick={this.toggle}
							onKeyPress={this.toggle}
							value={
								newestDate !== '' ? selectedDate.format(dateDisplayFormat) : ''
							}
							readOnly
							bsSize="sm"
							style={{ width: 175 }}
						/>

						{weekAdv && (
							<InputGroupAddon addonType="append">
								<Button
									id={`DateSkipper-${id}-dayup7`}
									color="secondary"
									outline
									size="sm"
									disabled={weekAdvUpDisabled}
									onClick={() => this.dayChange({ days: 7 })}
								>
									<i className="fa fa-angle-right fa-lg" />
								</Button>
								<UncontrolledTooltip
									placement="top"
									delay={500}
									target={`DateSkipper-${id}-dayup7`}
								>
									Forward 7 days
								</UncontrolledTooltip>
							</InputGroupAddon>
						)}
						{monthAdv && (
							<InputGroupAddon addonType="append">
								<Button
									id={`DateSkipper-${id}-dayup30`}
									color="secondary"
									outline
									size="sm"
									disabled={monthAdvUpDisabled}
									onClick={() => this.dayChange({ days: 30 })}
								>
									<i className="fa fa-angle-double-right fa-lg" />
								</Button>
								<UncontrolledTooltip
									placement="top"
									delay={500}
									target={`DateSkipper-${id}-dayup30`}
								>
									Forward 30 days
								</UncontrolledTooltip>
							</InputGroupAddon>
						)}
					</InputGroup>
				</Label>
				<Popover
					placement="bottom"
					isOpen={this.state.popoverOpen}
					target={'DatePicker-' + this.props.id}
					toggle={this.toggle}
				>
					<PopoverBody>
						<Calendar
							date={jsDate(selectedDate)}
							firstDayOfWeek={1}
							showSelectionPreview={false}
							showDateDisplay={false}
							minDate={jsDate(minDate)}
							maxDate={jsDate(maxDate)}
							onChange={this.onDatePick}
						/>
					</PopoverBody>
				</Popover>
			</Fragment>
		);
	}
}
