import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {
	Button,
	ButtonGroup,
	Col,
	Container,
	Form,
	Row,
	UncontrolledTooltip
} from 'reactstrap';
import {
	Cartesian3,
	Color,
	Rectangle,
	SceneMode,
	ScreenSpaceEventType,
	WebMercatorProjection
} from 'cesium';
import {
	CameraFlyTo,
	Entity,
	EntityDescription,
	Globe,
	ImageryLayer,
	PointGraphics,
	Scene,
	ScreenSpaceCameraController,
	ScreenSpaceEvent,
	ScreenSpaceEventHandler,
	Viewer
} from 'resium';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga';
import formatcoords from 'formatcoords';

import {
	getAuthenticatedUserGroups,
	fetchMySmelters,
	resetMySmelters,
	fetchNewestDate,
	fetchSmelterAllTimeBySmelterData,
	resetSmelterAllTimeBySmelterData,
	fetchSmelterMapData,
	resetSmelterMapData,
	resetNewestDate,
	shrinkSmelterAllTimeMapData,
	resetShrinkedSmelterAllTimeMapData,
	selectSmelter,
	unselectSmelter
} from 'actions';
import { bingImageryProvider, DateSkipper } from 'components';
import { Crumbs, StripPlotContainer } from 'containers';
import { isUserSales, isUserEnterprise } from '../../utils';

import './SmeltersMap.scss';
import colours from '../../_colours.scss';

const defaultRegion = 'asiaexcn';
const defaultTitle = 'Asia (Ex. CN)';
const apiDateFormat = 'YYYY-MM-DD';
const apiDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
const csvDateFormat = 'YYYY-MM-DD';
const mapProjection = new WebMercatorProjection();

class SmeltersMap extends Component {
	state = {
		dateMin: moment.utc(),
		dateMax: moment.utc(),
		selectedDate: moment.utc(),
		currentRegion: '',
		canDownload: false,
		storedSelectedSmelters: false,
		activeSelecting: false,
		showAllData: false
	};

	constructor(props) {
		super(props);
		this.entityRefs = {};
		this.plotsRef = React.createRef();
	}

	async componentDidMount() {
		ReactGA.pageview('/smeltersMap', null, 'Smelter Map');

		const { selectedSmelters } = this.props;
		if (selectedSmelters.length) {
			this.setState({ storedSelectedSmelters: true });
		}
		// Get my smelters access list
		this.props.fetchMySmelters();
		// Get newest date for map
		this.props.fetchNewestDate();
	}

	componentWillUnmount() {
		this.props.resetShrinkedSmelterAllTimeMapData();
		this.props.resetSmelterAllTimeBySmelterData();
		this.props.resetSmelterMapData();
		this.props.resetMySmelters();
		this.props.resetNewestDate();
	}

	// Updates from region navigation trigger a data request
	async componentDidUpdate(
		{
			match: {
				params: { regionId: prevRegionId }
			},
			newestDate: prevNewestDate,
			selectedSmelters: prevSelectedSmelters
		},
		{ selectedDate: prevSelectedDate, currentRegion: prevCurrentRegion }
	) {
		const { regionId, newestDate, selectedSmelters, smelterRegions } =
			this.props;
		const {
			selectedDate,
			currentRegion,
			storedSelectedSmelters,
			activeSelecting
		} = this.state;

		//Date setting
		if (newestDate !== prevNewestDate) {
			await this.updateDateHandling({ newestDate });
		}

		if (!newestDate) {
			return;
		}

		//handling regions
		if (!currentRegion) {
			if (!smelterRegions.length) return;

			//if no regionId or regionId is unknown, set default regionId
			const region =
				!regionId ||
				!smelterRegions
					.map((r) => r.name.replace(/ /g, '').toLowerCase())
					.includes(regionId)
					? defaultRegion
					: regionId;

			this.setState({ currentRegion: region });
			this.props.history.push(`/smeltersMap/${region}`);
			return;
		}
		// handling regions: by clicking Smelter Map in the menu, you stay in the same view
		if (!regionId && currentRegion) {
			this.props.history.push(`/smeltersMap/${currentRegion}`);
			return;
		}
		//handling regions
		if (currentRegion !== prevCurrentRegion) {
			if (prevCurrentRegion) {
				//only on region switching or toggling Index/Non-Index Smelters
				this.props.history.push(`/smeltersMap/${currentRegion}`);
			}
			this.getMapData();
			return;
		}

		//region has to be set up to this point
		if (!prevSelectedDate.isSame(selectedDate, 'day') && currentRegion) {
			this.getMapData();
			return;
		}

		//only place to fetch data for selected smelters
		if (
			(selectedSmelters.length &&
				selectedSmelters.length !== prevSelectedSmelters.length) ||
			storedSelectedSmelters
		) {
			//since component is already mounted, we're not interested anymore in stored selected smelters
			this.setState({ storedSelectedSmelters: false });
			await this.props.fetchSmelterAllTimeBySmelterData({
				smelterIds: selectedSmelters
			});
			const { smelterAllTimeBySmelter: smelterData } = this.props;
			this.props.shrinkSmelterAllTimeMapData({ smelterData });
			if (
				selectedSmelters.length > prevSelectedSmelters.length &&
				activeSelecting
			) {
				this.plotsRef.current.scrollIntoView();
			}
			return;
		} else if (
			!selectedSmelters.length &&
			selectedSmelters.length < prevSelectedSmelters.length
		) {
			//unselect all smelters
			this.props.resetShrinkedSmelterAllTimeMapData();
			this.props.resetSmelterAllTimeBySmelterData();
		}
	}

	async updateDateHandling({ newestDate }) {
		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();
		const maxDate = moment.utc(newestDate);
		//only 'sales' and  'savant-enterprise' are currently in use
		//for enterprise user and part of state for 'sales'
		let dateState = {
			selectedDate: maxDate,
			dateMin: moment.utc('20160301'),
			dateMax: maxDate
		};
		let downloadState = {
			canDownload: true
		};

		if (isUserSales(authGroups, 'cu')) {
			dateState = {
				...dateState,
				selectedDate: maxDate.clone().subtract(4, 'weeks'),
				dateMax: maxDate.clone().subtract(4, 'weeks')
			};
		} else if (!isUserEnterprise(authGroups, 'cu')) {
			dateState = {};
			downloadState = {};
		}

		this.setState({
			...dateState,
			...downloadState
		});
	}

	async getMapData() {
		const { fetchSmelterMapData } = this.props;
		const { selectedDate, currentRegion } = this.state;

		await fetchSmelterMapData({
			regionId: currentRegion,
			searchDate: selectedDate.format(apiDateFormat)
		});
		if (this.scene) {
			//wait a while before calling requestRender() in order to make sure that all points on the map will be rendered
			setTimeout(() => this.scene.requestRender(), 200);
		}
	}

	// Handle the selection of a region
	onRegionBtnClick({ regionId }) {
		this.setState({ currentRegion: regionId });
		// this.props.resetSmelterMapData();
	}

	// Handle date selection event
	async onDateSelected({ selectedDate }) {
		const dspSelectedDate = selectedDate.format(apiDateFormat);
		await this.setState({
			selectedDate: moment.utc(dspSelectedDate)
		});
	}

	async onStartPlayBackward() {
		await this.setState({
			playBackward: true,
			playForward: false
		});
	}

	async onStopPlay() {
		await this.setState({
			playBackward: false,
			playForward: false
		});
	}

	async onStartPlayForward() {
		await this.setState({
			playBackward: false,
			playForward: true
		});
	}

	// Function to swap the map cursor when over a smelter
	onMapEntHov(enter) {
		if (!this.viewer) {
			this.viewer = document.getElementById('viewer');
		}
		this.viewer.style.cursor = !!enter ? 'pointer' : 'default';
	}

	// Function to provide highlighting of an item on the map
	onHighlightPoint({ key, active }) {
		if (
			this.entityRefs[key] &&
			this.entityRefs[key].current &&
			this.entityRefs[key].current.cesiumElement
		) {
			let entity = this.entityRefs[key].current.cesiumElement;
			if (active) {
				entity.outlineWidth = 3;
				entity.outlineColor = Color.fromCssColorString(
					colours.savantSmelterIdentify
				);
			} else {
				entity.outlineWidth = 0;
				entity.outlineColor = Color.TRANSPARENT;
			}
			if (this.scene) {
				this.scene.requestRender();
			}
		}
	}

	// Checkbox click for the smelter checkboxes
	onCheckboxBtnClick({ prop, val }) {
		const { selectedSmelters } = this.props;
		const { activeSelecting } = this.state;
		if (!activeSelecting) {
			this.setState({ activeSelecting: true });
		}
		if (selectedSmelters.includes(val)) {
			this.props.unselectSmelter(val);
		} else {
			this.props.selectSmelter(val);
		}
	}

	toggleDateRange() {
		this.setState({ showAllData: !this.state.showAllData });
	}

	buildRegionButtons() {
		const { smelterRegions, regionId } = this.props;
		return (
			<ButtonGroup className="mb-2">
				{smelterRegions.map(({ name }) => {
					const key = name.replace(/ /g, '').toLowerCase();
					return (
						<Button
							id={`region_${key}`}
							key={`button_${key}`}
							outline
							size="sm"
							color="secondary"
							onClick={() => this.onRegionBtnClick({ regionId: key })}
							active={regionId === key}
							disabled={false}
							aria-label={name}
						>
							{key === defaultRegion ? defaultTitle : name}
						</Button>
					);
				})}
			</ButtonGroup>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const { smelterMapData, regionId } = this.props;
		const { selectedDate } = this.state;

		// Initialise our csv data
		let csvFileName = `smelter-map-data-${regionId}-${selectedDate.format(
			apiDateFormat
		)}.csv`;
		let csvHeaders = [
			{ label: 'Name', key: 'name' },
			{ label: 'Capacity', key: 'capacity' },
			{ label: 'Capture time', key: 'capture_date' },
			{ label: 'State', key: 'state' }
		];
		let csvData = smelterMapData.map(
			({ name, capacity, capture_date, state }) => {
				let dspState = 'unknown';
				if (state === '1') {
					dspState = 'on';
				} else if (state === '0') {
					dspState = 'off';
				}

				return {
					name,
					capacity,
					capture_date: moment.utc(capture_date).format(csvDateFormat),
					state: dspState
				};
			}
		);

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildCsvDownloadButton() {
		const { canDownload } = this.state;
		const { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();
		if (csvData.length !== 0 && canDownload) {
			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: 'smeltersMap',
							action: 'data downloaded',
							label: csvFileName
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const { selectedDate, dateMin, dateMax } = this.state;
		const { newestDate } = this.props;

		return (
			<Form>
				<Container fluid className="px-0">
					<Row noGutters>
						<Col xs="12" sm="12">
							{this.buildRegionButtons()}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateSkipper
								id="ds1"
								className="mr-3 mb-3 align-middle"
								weekAdv={true}
								monthAdv={true}
								selectedDate={selectedDate}
								maxDate={dateMax}
								minDate={dateMin}
								newestDate={newestDate}
								onDateSelected={this.onDateSelected.bind(this)}
							/>
							{this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	//days since last valid reading
	getElapsedDays(captureDate) {
		const { selectedDate } = this.state;
		const days = selectedDate.diff(captureDate, 'days');
		return days > 0 ? `${days}d` : '0d';
	}

	buildSmelterList() {
		const { smelterMapData, mySmelters, selectedSmelters } = this.props;
		const { currentRegion, selectedDate } = this.state;

		const mySmelterIds = mySmelters.map((s) => s.id);
		const monitoredSmelters = mySmelters.filter(({ type, region, sandbox }) => {
			return (
				type === 'cu' &&
				(region.replace(/ /g, '').toLowerCase() === currentRegion ||
					currentRegion === 'all') &&
				sandbox >= 0
			);
		});
		const isSmelterMapData = smelterMapData && smelterMapData.length > 0;
		const displayNonIndexNote =
			smelterMapData.filter((d) => d.prod_state === 1).length > 0;

		return (
			<div className="smelter-list pb-1" role="table">
				<div className="flex-row" role="rowheader">
					<div className="flex-col py-1" role="columnheader">
						<i className="fa fa-bar-chart" aria-hidden="true" />
					</div>
					<div className="flex-col pb-1" role="columnheader">
						Smelter
					</div>
					<div id="status-header" className="flex-col" role="columnheader">
						Status
					</div>
					<UncontrolledTooltip
						placement="top"
						delay={{ show: 1000, hide: 500 }}
						target="status-header"
					>
						Current status and the days since last reading
					</UncontrolledTooltip>
				</div>
				<div className="tb-wrapper">
					<div className="table-body" role="list">
						{!monitoredSmelters.length ? (
							<div className="no-monitor">
								No info about monitored smelters in this region
							</div>
						) : !isSmelterMapData ? (
							<div className="no-monitor">Still waiting for data</div>
						) : (
							smelterMapData.map(
								({ id, name, prod_state, state, capture_date, end_date }) => {
									const key = name.replace(/ /g, '').toLowerCase();
									const iconClass = `circle ${
										end_date &&
										selectedDate.isSameOrAfter(moment.utc(end_date), 'day')
											? 'shutdown'
											: state === '1'
											? 'active'
											: 'inactive'
									}`;
									const iconSr =
										end_date &&
										selectedDate.isSameOrAfter(moment.utc(end_date), 'day')
											? 'Shutdown'
											: state === '1'
											? 'Active'
											: 'Inactive';
									return (
										<div className="flex-row-cells" role="rowgroup" key={key}>
											<div className="flex-cell" role="cell">
												{!mySmelterIds.includes(id) && (
													<i
														className="fa fa-square-o disabled"
														aria-hidden="true"
													/>
												)}
												{mySmelterIds.includes(id) &&
													!selectedSmelters.includes(id) && (
														<i
															className="fa fa-square-o pointered"
															aria-hidden="true"
															onClick={() =>
																this.onCheckboxBtnClick({
																	prop: 'selectedSmelters',
																	val: id
																})
															}
														/>
													)}
												{mySmelterIds.includes(id) &&
													selectedSmelters.includes(id) && (
														<i
															className="fa fa-check-square-o pointered"
															aria-hidden="true"
															onClick={() =>
																this.onCheckboxBtnClick({
																	prop: 'selectedSmelters',
																	val: id
																})
															}
														/>
													)}
											</div>

											<div
												id={`smelter-${id}`}
												className="flex-cell pr-1"
												role="cell"
												onMouseEnter={() =>
													this.onHighlightPoint({ key, active: true })
												}
												onMouseLeave={() =>
													this.onHighlightPoint({ key, active: false })
												}
											>
												{prod_state ? (
													<Fragment>
														<span className="title-base">{name}</span>
														<span className="asterisk">*</span>
													</Fragment>
												) : (
													<span className="title-base">{name}</span>
												)}
											</div>

											<UncontrolledTooltip
												placement="top"
												delay={{ show: 1000, hide: 500 }}
												target={`smelter-${id}`}
											>
												{name}
											</UncontrolledTooltip>
											<div className="flex-cell" role="cell">
												<div className={`icon ${iconClass}`}>
													<span className="sr-only">{iconSr} </span>
												</div>
												<div className="e-days">
													{this.getElapsedDays(capture_date)}
												</div>
											</div>
										</div>
									);
								}
							)
						)}
					</div>
					{isSmelterMapData && (
						<div className="table-notes text-muted small mx-n3 p-1">
							<div className="mb-0">
								<p className="mb-0">
									<strong>Status</strong> indicates current state and days (d)
									since last reading.
								</p>
								{displayNonIndexNote ? (
									<p className="mt-0">
										<span className="asterisk">*</span> Not included in the
										Index as either not yet operational, not a smelter (e.g. a
										refinery) or the data is not consistent enough.
									</p>
								) : null}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}

	getPointRef(id) {
		// Create a ref for the PointGraphics and add it to the entityRefs collection if it is not already created
		if (!this.entityRefs.hasOwnProperty(id)) {
			this.entityRefs[id] = React.createRef();
		}
		return this.entityRefs[id];
	}

	buildSmelterEntities() {
		const { smelterMapData = [] } = this.props;
		const { selectedDate } = this.state;

		if (!smelterMapData || !smelterMapData.length) {
			return;
		}

		return smelterMapData.map(
			({
				name,
				capacity,
				lon,
				lat,
				state,
				end_date,
				capture_date,
				company = null,
				country = null
			}) => {
				const smelterId = name.replace(/ /g, '').toLowerCase();

				const activityColour =
					end_date && selectedDate.isSameOrAfter(moment.utc(end_date), 'day')
						? colours.savantSmelterIdentify
						: state === '1'
						? colours.savantSmelterHot
						: colours.savantSmelterCold;
				const colour = Color.fromCssColorString(activityColour);
				return (
					<Entity
						key={smelterId}
						name={name}
						position={Cartesian3.fromDegrees(
							parseFloat(lon),
							parseFloat(lat),
							1
						)}
						onMouseEnter={() => this.onMapEntHov(true)}
						onMouseLeave={() => this.onMapEntHov(false)}
					>
						<Fragment>
							<EntityDescription>
								<p>
									<strong>Location:</strong>{' '}
									{formatcoords(lat, lon).format('DD MM X', {
										decimalPlaces: 2
									})}
									{country && (
										<Fragment>
											<br />
											<strong>Country:</strong> {country}
										</Fragment>
									)}
								</p>
								<p>
									{company && (
										<Fragment>
											<strong>Company:</strong> {company}
											<br />
										</Fragment>
									)}
									<strong>Capacity:</strong> {capacity} kT/a
								</p>
								<p>
									<strong>Capture:</strong>{' '}
									{capture_date !== null ? (
										moment.utc(capture_date).format('Do MMMM YYYY')
									) : (
										<em>Unknown</em>
									)}
									<br />
									<strong>State:</strong>{' '}
									{end_date &&
									selectedDate.isSameOrAfter(moment.utc(end_date), 'day')
										? 'Shutdown'
										: state === '1'
										? 'Active'
										: 'Inactive'}
								</p>
							</EntityDescription>
							<PointGraphics
								ref={this.getPointRef(smelterId)}
								color={colour}
								pixelSize={12}
							/>
						</Fragment>
					</Entity>
				);
			}
		);
	}

	buildZoomInfo() {
		return (
			<div className="zoom-label small font-italic">
				Please zoom in if smelters cannot be selected
			</div>
		);
	}

	buildMapFeedback() {
		return (
			<span className="map-feeback">
				<a
					id="mapFeedbackButton"
					href="mailto:savant@earthi.space?subject=SAVANT%20Smelter%20Map%20feedback"
					className="btn btn-light btn-sm"
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fa fa-at mr-1" />
					Feedback
				</a>
				<UncontrolledTooltip
					placement="top"
					delay={{ show: 1000, hide: 500 }}
					target="mapFeedbackButton"
					trigger="click hover"
				>
					Help us update our database of smelter information
				</UncontrolledTooltip>
			</span>
		);
	}

	buildMap() {
		const { smelterRegions, regionId } = this.props;
		const detail = smelterRegions.filter(
			({ name }) => name.replace(/ /g, '').toLowerCase() === regionId
		)[0];

		if (!detail) {
			return (
				<div className="no-map text-center">
					<i className="fas fa-map-marked-alt fa-9x" />
				</div>
			);
		} else {
			const { view_left, view_top, view_right, view_bottom, resolution } =
				detail;

			return (
				<Viewer
					id="viewer"
					requestRenderMode={true}
					maximumRenderTimeChange={Infinity}
					full
					imageryProvider={false}
					sceneMode={SceneMode.SCENE2D}
					sceneModePicker={false}
					animation={false}
					vrButton={false}
					geocoder={false}
					homeButton={false}
					fullscreenButton={false}
					baseLayerPicker={false}
					infoBox={true}
					navigationHelpButton={false}
					timeline={false}
					mapProjection={mapProjection}
					className="map-viewer"
				>
					<Scene
						fxaa={false}
						sunBloom={false}
						shadowMap={false}
						shadows={false}
						resolutionScale={resolution}
						ref={(e) => {
							this.scene = e ? e.cesiumElement : undefined;
						}}
					/>
					<Globe tileCacheSize={10000} enableLighting={false} />
					<ImageryLayer
						imageryProvider={bingImageryProvider}
						gamma={0.5}
						brightness={1.3}
					/>
					<ScreenSpaceEventHandler useDefault>
						<ScreenSpaceEvent type={ScreenSpaceEventType.LEFT_DOUBLE_CLICK} />
					</ScreenSpaceEventHandler>
					<ScreenSpaceCameraController minimumZoomDistance={500000} />
					<CameraFlyTo
						duration={0}
						destination={Rectangle.fromDegrees(
							view_left,
							view_bottom,
							view_right,
							view_top
						)}
					/>

					{this.buildZoomInfo()}
					{this.buildSmelterEntities()}
					{this.buildMapFeedback()}
				</Viewer>
			);
		}
	}

	buildLegend() {
		return (
			<Fragment>
				<div className="circle active align-text-bottom mr-2" /> Active
				<div className="circle inactive align-text-bottom mr-2 ml-4" /> Inactive
				<div className="circle shutdown align-text-bottom mr-2 ml-4" /> Shutdown
			</Fragment>
		);
	}

	getAlignedSmelterdData(capacityData, activityData) {
		const { showAllData, dateMax } = this.state;

		const cData = showAllData
			? capacityData
			: capacityData.filter(({ t }) => dateMax.diff(t, 'years', true) <= 2);

		const aData = showAllData
			? activityData
			: activityData.filter(({ t }) => dateMax.diff(t, 'years', true) <= 2);
		if (!cData.length || !aData.length) {
			return { alignedCapacityData: [], alignedActivityData: [] };
		}
		if (!showAllData) {
			//handling capacity data cut off
			const prevCData = capacityData.filter(
				({ t }) => dateMax.diff(t, 'years', true) > 2
			);
			const prevValidItem = prevCData.length ? prevCData.pop() : null;
			const firstTimestamp = dateMax
				.clone()
				.subtract(2, 'years')
				.format(apiDateTimeFormat);
			if (
				moment.utc(cData[0].t).diff(firstTimestamp, 'days') !== 0 &&
				prevValidItem
			) {
				cData.unshift({ t: firstTimestamp, v: prevValidItem.v });
			}
		}

		let alignedData = [];
		//most likely case
		if (moment.utc(aData[0].t).isBefore(cData[0].t, 'day')) {
			alignedData = aData.filter(
				(ad) =>
					moment.utc(ad.t).isSame(cData[0].t, 'day') ||
					moment.utc(ad.t).isAfter(cData[0].t, 'day')
			);
			return { alignedCapacityData: cData, alignedActivityData: alignedData };
			//least likely case
		} else if (moment.utc(cData[0].t).isBefore(aData[0].t, 'day')) {
			alignedData = cData.filter(
				(cd) =>
					moment.utc(cd.t).isSame(aData[0].t, 'day') ||
					moment.utc(cd.t).isAfter(aData[0].t, 'day')
			);
			if (moment.utc(aData[0].t).isBefore(alignedData[0].t, 'day')) {
				//find the last value for capacity which is cut off
				const indexOrig =
					cData.findIndex((cd) => moment.utc(cd.t).isSame(alignedData[0].t)) -
					1;
				//add missing item
				if (indexOrig >= 0) {
					alignedData = [
						{ t: aData[0].t, v: cData[indexOrig].v },
						...alignedData
					];
				}
			}
			return { alignedCapacityData: alignedData, alignedActivityData: aData };
		}
		return { alignedCapacityData: cData, alignedActivityData: aData };
	}

	buildStripPlots() {
		const {
			mySmelters,
			selectedSmelters: selectedIds,
			smelterAllTimeBySmelter: { capacity = [], activity = [] } = {},
			smelterShrinkedAllTimeData,
			newestDate
		} = this.props;
		const { showAllData, dateMax } = this.state;

		const selectedSmelters = mySmelters.filter(({ id }) =>
			selectedIds.some((sId) => sId === id)
		);

		if (selectedSmelters.length === 0) {
			return null;
		}
		//show strip plots in the order they're selected, the most recent selected at top
		const sortedSelectedSmelters = [];
		selectedIds.forEach((sId) => {
			const idx = selectedSmelters.findIndex(({ id }) => sId === id);
			if (idx > -1) {
				sortedSelectedSmelters.unshift(selectedSmelters[idx]);
			}
		});

		return (
			<Fragment>
				<div
					className="d-flex justify-content-between mb-2"
					ref={this.plotsRef}
				>
					<h5 className="mb-0">
						Detailed Smelter activity -{' '}
						<span className="h6 font-weight-normal text-muted">
							Raw Index Score and Inactive Capacity plots per chosen Smelter
						</span>
					</h5>
					<ButtonGroup className="ml-2 flex-shrink-0 align-self-start">
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={() => this.toggleDateRange()}
							active={!showAllData}
							disabled={false}
							aria-label="Click to display indices for the last two years"
						>
							Last two years
						</Button>
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={() => this.toggleDateRange()}
							active={showAllData}
							disabled={false}
							aria-label="Click to display indices since the beginning of observations"
						>
							All Dates
						</Button>
					</ButtonGroup>
				</div>
				{sortedSelectedSmelters.map(
					({ name, id: smelterId, sandbox, sDate, eDate }, idx) => {
						const title = name;
						const type = 'cu';
						const capacityData = capacity[name] ? capacity[name].data : [];
						const shrinkedCapacityData = smelterShrinkedAllTimeData[name]
							? smelterShrinkedAllTimeData[name]
							: [];
						const activityData = activity[name] ? activity[name].data : [];
						//waiting for data
						if (!activityData.length || !shrinkedCapacityData.length) {
							return null;
						}

						const { alignedCapacityData, alignedActivityData } =
							this.getAlignedSmelterdData(shrinkedCapacityData, activityData);

						// first day in index
						const startDate = sandbox
							? ''
							: sDate && moment.utc(sDate).isAfter('20160301', 'day')
							? moment.utc(sDate).format(apiDateFormat)
							: moment.utc('20160301').format(apiDateFormat);

						//last day in index
						const endDate = sandbox
							? ''
							: eDate
							? moment.utc(eDate).format(apiDateFormat)
							: '';

						const plotProps = {
							activityData,
							alignedActivityData,
							capacityData,
							alignedCapacityData,
							csvDateFormat,
							idx,
							maxDate: dateMax,
							newestDate,
							startDate,
							endDate,
							title,
							sandbox,
							type,
							smelterId,
							noDataWarning:
								!alignedCapacityData.length || !alignedActivityData.length
						};

						return (
							<StripPlotContainer
								{...plotProps}
								key={`spContainer${smelterId}`}
							/>
						);
					}
				)}
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="SAVANT | Smelter Map">
				<div className="content-wrapper">
					<Container fluid>
						<Crumbs path={[{ title: 'Smelter Map' }]} />
						{this.buildFilterForm()}
						<Row noGutters className="map-container">
							<Col xs="3" className="p-3 map-left-panel">
								{this.buildSmelterList()}
							</Col>
							<Col xs="9">{this.buildMap()}</Col>
						</Row>
						<Row noGutters>
							<Col xs="12" className="map-legend py-2 text-center small mb-3">
								{this.buildLegend()}
							</Col>
						</Row>
						<Row noGutters>
							<Col xs="12">{this.buildStripPlots()}</Col>
						</Row>
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = (
	{
		mySmelters,
		selectedSmelters,
		newestDate,
		smelterRegions,
		smelterMapData,
		smelterAllTimeBySmelter,
		smelterShrinkedAllTimeData
	},
	{
		match: {
			params: { regionId }
		}
	}
) => {
	return {
		mySmelters, // redux
		selectedSmelters, //redux
		newestDate, // redux
		smelterMapData, // redux
		smelterRegions, // redux
		smelterAllTimeBySmelter, //redux
		smelterShrinkedAllTimeData, //redux
		regionId // route url param
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchMySmelters: () => dispatch(fetchMySmelters()),
	resetMySmelters: () => dispatch(resetMySmelters()),
	fetchNewestDate: () => dispatch(fetchNewestDate()),
	resetNewestDate: () => dispatch(resetNewestDate()),
	fetchSmelterMapData: (filterData) =>
		dispatch(fetchSmelterMapData(filterData)),
	resetSmelterMapData: () => dispatch(resetSmelterMapData()),
	fetchSmelterAllTimeBySmelterData: (filterData) =>
		dispatch(fetchSmelterAllTimeBySmelterData(filterData)),
	resetSmelterAllTimeBySmelterData: () =>
		dispatch(resetSmelterAllTimeBySmelterData()),
	shrinkSmelterAllTimeMapData: ({ smelterData }) =>
		dispatch(shrinkSmelterAllTimeMapData({ smelterData })),
	resetShrinkedSmelterAllTimeMapData: () =>
		dispatch(resetShrinkedSmelterAllTimeMapData()),
	selectSmelter: (id) => dispatch(selectSmelter(id)),
	unselectSmelter: (id) => dispatch(unselectSmelter(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SmeltersMap);
