import React from 'react';

import { InlineHelp } from 'components';

export const smeltersInlineHelpContent = (
	<InlineHelp
		title="Smelter Operations Index Help"
		tooltip="Information about the Smelter Operations Index"
		tooltipId="smelterHelp"
	>
		<h5>How to use the index</h5>
		<h6>Region, country, smelter type and metal grade choices</h6>
		<p className="small">
			A number of smelter indices are available, each containing data from the
			relevant smelters. The <strong>Global</strong> group contains all
			smelters. Copper has regional groups for <strong>China</strong>,{' '}
			<strong>Asia and Oceania</strong>, <strong>Europe and Africa</strong>,{' '}
			<strong>North America</strong> and <strong>South America</strong>, and
			these combined are equivalent to the global copper group. There is also a{' '}
			<strong>Global (ex. CN)</strong> group which contains all copper smelters
			not in China. Nickel has regional groups for <strong>China NPI</strong>,{' '}
			<strong>China (ex. NPI)</strong>, <strong>Indonesia NPI</strong>,{' '}
			<strong>Indonesia (ex. NPI)</strong>, <strong>Asia and Oceania</strong>,{' '}
			<strong>Europe and Africa</strong> and <strong>Americas</strong>, and the
			combination is equivalent to the global nickel group. Clicking the arrow
			next to the Region filtering box exposes further indices. Copper has
			groups for <strong>country</strong> and <strong>Chinese region</strong>,
			and Nickel has groups for <strong>country</strong> and{' '}
			<strong>metal grade</strong> (either ferronickel, nickel or nickel pig
			iron). Copper also allows the selection of smelter type with options of{' '}
			<strong>Captive</strong> or <strong>Custom</strong>, where captive means
			there is a mine associated with the smelter. Selecting one of these index
			categories will hide the top-level region options and allow the selection
			of relevant choices. You can select multiple values but will not be able
			to mix different categories (for example a region and a country, or a
			region and a metal grade); in particular, the <strong>Global</strong>{' '}
			region is always shown in isolation. When viewing the default set of
			regions, it is possible to click <strong>All</strong> regions or{' '}
			<strong>None</strong> to quickly toggle them all on or off.
		</p>
		<h6>Date selection</h6>
		<p className="small">
			Click on the calendar icon or the dates displayed to the right of the icon
			to open the Date Range Selection panel. On the left side of the panel you
			can choose preset date ranges. On the right side, you can select a custom
			date range. When you have selected a start date <em>and</em> an end date,
			the graph will regenerate for the range you have chosen.
		</p>
		<h6>Combine / Compare</h6>
		<p className="small">
			If you click on <strong>Combine</strong>, a single line graph will be
			shown for the combination of regions you have selected, showing the index
			of activity relating to the total theoretical capacity of all smelters in
			the regions selected.
		</p>
		<p className="small">
			If you click on <strong>Compare</strong>, an individual line graph will be
			shown for each region that you have selected.
		</p>
		<h6>Data smoothing</h6>
		<p className="small">
			The drop-down menu lets you see raw data with no smoothing, or a variety
			of smoothing options which may be helpful for monitoring longer term
			trends. Data smoothing is currently calculated with a trailing average.
			Consequently if, for example, you select a{' '}
			<strong>7-day moving average</strong>, data for the first 7 days of your
			selected date range will not be shown. Select{' '}
			<strong>No data smoothing</strong> to see the latest data.
		</p>
		<h6>Data download</h6>
		<p className="small">
			Click <strong>Download this data</strong> to generate a <em>.csv</em> file
			of data that matches the selection of regions and your chosen date range.
			The .csv file has the following format:
		</p>
		<ul className="small">
			<li>Column 1 Observation date - dd/mm/yyyy.</li>
			<li>
				Column 2 Inactive Capacity Index value for the first region.
				<br />
			</li>
			<li>
				Column 3 Activity Dispersion Index value for the first region.
				<br />
			</li>
			<li>Columns 3+ Indexes for further regions.</li>
			<li>
				Last two columns Inactive Capacity and Activity Dispersion Index value
				for the combination of regions you have selected. This value will match
				the data shown on the line graph if you have chosen to combine the
				displays (as opposed to comparing them).
			</li>
		</ul>
		<p className="small">
			Selecting data smoothing does not affect the downloaded data.
		</p>
		<p className="small">
			<i className="fa fa-exclamation-circle mr-2" aria-hidden="true" />
			<strong>Note:</strong> Not all features will be available to all account
			levels.
		</p>
	</InlineHelp>
);
