import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga';

import {
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Row
} from 'reactstrap';

import { Bar } from 'react-chartjs-2';
import { Crumbs } from 'containers';
import {
	getAuthenticatedUserGroups,
	fetchNiPseudoGroups,
	resetNiPseudoGroups,
	fetchNiProductionData,
	resetNiProductionData,
	fetchNiPseudoGroupSmelters,
	resetNiPseudoGroupSmelters,
	fetchNiProductionSmeltersData,
	resetNiProductionSmeltersData,
	selectNiProdSmelter,
	resetNiProdSmelters
} from 'actions';
import PeriodButtonGroup from './components/PeriodButtonGroup';
import RangeButtonGroup from './components/RangeButtonGroup';
import SmelterList from './components/SmelterList';
import {
	DATE_MIN,
	CURR_CAL_YEAR_START,
	PREV_CAL_YEAR_START,
	PREV_2_CAL_YEARS_START,
	PREV_3_CAL_YEARS_START,
	CURR_MONTH_START,
	YEAR_COLOURS,
	MAIN_VIEW,
	SMELTERS_VIEW,
	YEARS_VIEW
} from './config';
import {
	generateRandomColours,
	groupByKey,
	isUserPremiumPlus
} from '../../../utils';
import colours from '../../../_colours.scss';
import './SmeltersProduction.scss';

const apiDateFormat = 'YYYYMMDD';

class NiSmeltersProduction extends Component {
	state = {
		isUserPremiumPlus: false,
		selectedRegion: 'australia',
		selectedPeriod: 'month',
		startDate: DATE_MIN,
		ute: '',
		isUteEditing: false,
		capAdd: '',
		isCapAddEditing: false,
		rightButtonsSelected: MAIN_VIEW,
		smelterColours: [],
		startDateSmelters: CURR_CAL_YEAR_START,
		selectedPeriodSmelters: 'month',
		showYearsQuarterly: false,
		showStackedYears: false
	};

	constructor(props) {
		super(props);
		this.smeltersRef = React.createRef();
		this.yearsRef = React.createRef();
	}

	async componentDidMount() {
		const { selectedRegion, selectedPeriod, startDate } = this.state;
		this.props.fetchNiProductionData({
			regions: [selectedRegion],
			period: selectedPeriod,
			startDate: startDate.format(apiDateFormat)
		});
		this.props.fetchNiPseudoGroups({ ute: true });

		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();
		this.setState({ isUserPremiumPlus: isUserPremiumPlus(authGroups, 'ni') });
	}

	componentDidUpdate(
		{
			niPseudoGroups: prevNiPseudoGroups,
			niPseudoGroupSmelters: prevNiPseudoGroupSmelters,
			niProduction: prevNiProduction,
			niSelectedProdSmelters: prevNiSelectedProdSmelters
		},
		{
			selectedRegion: prevselectedRegion,
			selectedPeriod: prevSelectedPeriod,
			startDate: prevStartDate,
			isUteEditing: isPrevUteEditing,
			isCapAddEditing: isPrevCapAddEditing,
			selectedPeriodSmelters: prevSelectedPeriodSmelters,
			startDateSmelters: prevStartDateSmelters,
			rightButtonsSelected: prevRightButtonsSelected,
			showYearsQuarterly: prevShowYearsQuarterly
		}
	) {
		const {
			selectedRegion,
			selectedPeriod,
			startDate,
			isUteEditing,
			ute,
			capAdd,
			isCapAddEditing,
			selectedPeriodSmelters,
			startDateSmelters,
			rightButtonsSelected,
			showYearsQuarterly
		} = this.state;
		const {
			niPseudoGroups,
			niPseudoGroupSmelters,
			niProduction,
			niSelectedProdSmelters
		} = this.props;

		if (selectedRegion !== prevselectedRegion) {
			this.setState({
				ute: this.getDefaultUte(),
				capAdd: this.getDefaultCapacityAdd(),
				rightButtonsSelected: MAIN_VIEW,
				showYearsQuarterly: false,
				showStackedYears: false,
				startDate: DATE_MIN,
				selectedPeriod: 'month'
			});
			this.props.resetNiProdSmelters();
			this.props.resetNiProductionSmeltersData();
			this.props.resetNiPseudoGroupSmelters();
			this.props.resetNiProductionData();
		}

		if (
			(!isUteEditing && isUteEditing !== isPrevUteEditing) ||
			(!isCapAddEditing && isCapAddEditing !== isPrevCapAddEditing)
		) {
			this.props.fetchNiProductionData({
				regions: [selectedRegion],
				period: selectedPeriod,
				startDate: startDate.format(apiDateFormat),
				ute,
				capAdd: capAdd / 100
			});
			//we need additional request if YEARS_VIEW is selected
			if (
				rightButtonsSelected === YEARS_VIEW &&
				((!showYearsQuarterly && selectedPeriod !== 'month') ||
					(showYearsQuarterly && selectedPeriod !== 'quarter'))
			) {
				this.props.fetchNiProductionData({
					regions: [selectedRegion],
					period: `${showYearsQuarterly ? 'quarter' : 'month'}`,
					startDate: startDate.format(apiDateFormat),
					ute,
					capAdd: capAdd / 100
				});
			}
			if (rightButtonsSelected === SMELTERS_VIEW && isPrevUteEditing) {
				this.props.fetchNiProductionSmeltersData({
					smelterIds: [...niSelectedProdSmelters],
					ute,
					period: selectedPeriodSmelters,
					startDate: startDateSmelters
				});
			}
		}
		if (
			(Object.keys(prevNiProduction).length !== 0 &&
				Object.keys(niProduction).length === 0) ||
			selectedPeriod !== prevSelectedPeriod ||
			startDate !== prevStartDate
		) {
			this.props.fetchNiProductionData({
				regions: [selectedRegion],
				period: selectedPeriod,
				startDate: startDate.format(apiDateFormat),
				ute,
				capAdd: capAdd / 100
			});
			//we need additional request if YEARS_VIEW is selected
			if (
				rightButtonsSelected === YEARS_VIEW &&
				((!showYearsQuarterly && selectedPeriod !== 'month') ||
					(showYearsQuarterly && selectedPeriod !== 'quarter')) &&
				startDate !== prevStartDate
			) {
				this.props.fetchNiProductionData({
					regions: [selectedRegion],
					period: `${showYearsQuarterly ? 'quarter' : 'month'}`,
					startDate: startDate.format(apiDateFormat),
					ute,
					capAdd: capAdd / 100
				});
			}
		}
		if (niPseudoGroups.length > prevNiPseudoGroups.length) {
			this.setState({
				ute: this.getDefaultUte(),
				capAdd: this.getDefaultCapacityAdd()
			});
		}
		if (
			!prevNiPseudoGroupSmelters.length &&
			niPseudoGroupSmelters.length &&
			!niSelectedProdSmelters.length &&
			!prevNiSelectedProdSmelters.length
		) {
			//initial state
			this.setState({
				smelterColours: this.generateSmelterColours()
			});
			this.props.selectNiProdSmelter(niPseudoGroupSmelters[0].id);
		} else if (
			!niSelectedProdSmelters.length &&
			prevNiSelectedProdSmelters.length
		) {
			this.props.resetNiProductionSmeltersData();
		} else if (
			niSelectedProdSmelters.length !== prevNiSelectedProdSmelters.length ||
			(rightButtonsSelected === SMELTERS_VIEW &&
				(selectedPeriodSmelters !== prevSelectedPeriodSmelters ||
					startDateSmelters !== prevStartDateSmelters))
		) {
			this.props.fetchNiProductionSmeltersData({
				smelterIds: [...niSelectedProdSmelters],
				ute,
				period: selectedPeriodSmelters,
				startDate: startDateSmelters
			});
		}
		if (rightButtonsSelected !== prevRightButtonsSelected) {
			if (rightButtonsSelected === SMELTERS_VIEW) {
				!niSelectedProdSmelters.length &&
					this.props.fetchNiPseudoGroupSmelters({
						pg: selectedRegion,
						startDate: CURR_MONTH_START.format(apiDateFormat)
					});
				niSelectedProdSmelters.length !== 0 &&
					this.props.fetchNiProductionSmeltersData({
						smelterIds: [...niSelectedProdSmelters],
						ute,
						period: selectedPeriodSmelters,
						startDate: startDateSmelters
					});
				this.smeltersRef.current.scrollIntoView();
			} else if (rightButtonsSelected === YEARS_VIEW) {
				this.props.fetchNiProductionData({
					regions: [selectedRegion],
					period: `${showYearsQuarterly ? 'quarter' : 'month'}`,
					startDate: startDate.format(apiDateFormat),
					ute,
					capAdd: capAdd / 100
				});
				this.yearsRef.current.scrollIntoView();
			}
			if (prevRightButtonsSelected === SMELTERS_VIEW) {
				this.setState({
					startDateSmelters: CURR_CAL_YEAR_START,
					selectedPeriodSmelters: 'month'
				});
			} else if (prevRightButtonsSelected === YEARS_VIEW) {
				this.setState({ showStackedYears: false });
			}
		}
		if (showYearsQuarterly !== prevShowYearsQuarterly) {
			this.props.fetchNiProductionData({
				regions: [selectedRegion],
				period: `${showYearsQuarterly ? 'quarter' : 'month'}`,
				startDate: startDate.format(apiDateFormat),
				ute,
				capAdd: capAdd / 100
			});
		}
	}

	componentWillUnmount() {
		this.props.resetNiProductionSmeltersData();
		this.props.resetNiPseudoGroupSmelters();
		this.props.resetNiProductionData();
		this.props.resetNiProdSmelters();
		this.props.resetNiPseudoGroups();
	}

	getDefaultUte = () => {
		const { selectedRegion } = this.state;
		const { niPseudoGroups } = this.props;
		return niPseudoGroups.find(({ key }) => key === selectedRegion).ute;
	};
	getDefaultCapacityAdd = () => {
		const { selectedRegion } = this.state;
		const { niPseudoGroups } = this.props;
		const capacityAdd = niPseudoGroups.find(
			({ key }) => key === selectedRegion
		).capacityAdd;
		return 100 * Number.parseFloat(capacityAdd).toFixed(2);
	};
	onInputSelect = ({ target: { options } }) => {
		const selectedIdx = options.selectedIndex;
		this.setState({
			selectedRegion: options[selectedIdx].getAttribute('data-key')
		});
	};

	toggleUteEditing = () => {
		const { isUteEditing } = this.state;
		this.setState({ isUteEditing: !isUteEditing });
	};

	toggleCapAddEditing = () => {
		const { isCapAddEditing } = this.state;
		this.setState({ isCapAddEditing: !isCapAddEditing });
	};

	onUteChange = ({ target: { value, min, max } }) => {
		const val = Math.max(Number(min), Math.min(Number(max), Number(value)));
		this.setState({ ute: val });
	};
	onUteBlur = () => {
		const { ute } = this.state;
		this.toggleUteEditing();
		ute === 0
			? this.setState({
					ute: this.getDefaultUte()
			  })
			: this.setState({ ute: Number.parseFloat(ute).toFixed(2) });
	};

	onCapAddChange = ({ target: { value, min, max } }) => {
		const val = Math.max(Number(min), Math.min(Number(max), Number(value)));
		this.setState({ capAdd: val });
	};
	onCapAddBlur = () => {
		const { capAdd } = this.state;
		this.toggleCapAddEditing();
		this.setState({ capAdd: Number.parseInt(Math.round(capAdd)) });
	};

	onBtnClick = ({ target: { id } }) => {
		switch (id) {
			case 'main-month':
				this.setState({ selectedPeriod: 'month' });
				break;
			case 'main-quarter':
				this.setState({ selectedPeriod: 'quarter' });
				break;
			case 'main-year':
				this.setState({ selectedPeriod: 'year' });
				break;
			case 'main-0Y':
				this.setState({ startDate: CURR_CAL_YEAR_START });
				break;
			case 'main-1Y':
				this.setState({ startDate: PREV_CAL_YEAR_START });
				break;
			case 'main-2Y':
				this.setState({ startDate: PREV_2_CAL_YEARS_START });
				break;
			case 'main-3Y':
				this.setState({ startDate: PREV_3_CAL_YEARS_START });
				break;
			case 'main-all':
				this.setState({ startDate: DATE_MIN });
				break;
			case 'bottom-month':
				this.setState({ selectedPeriodSmelters: 'month' });
				break;
			case 'bottom-quarter':
				this.setState({ selectedPeriodSmelters: 'quarter' });
				break;
			case 'bottom-year':
				this.setState({ selectedPeriodSmelters: 'year' });
				break;
			case 'bottom-0Y':
				this.setState({ startDateSmelters: CURR_CAL_YEAR_START });
				break;
			case 'bottom-1Y':
				this.setState({ startDateSmelters: PREV_CAL_YEAR_START });
				break;
			case 'bottom-2Y':
				this.setState({ startDateSmelters: PREV_2_CAL_YEARS_START });
				break;
			case 'bottom-3Y':
				this.setState({ startDateSmelters: PREV_3_CAL_YEARS_START });
				break;
			default:
				return false;
		}
	};

	toggleView = (view) => {
		const { rightButtonsSelected } = this.state;
		if (rightButtonsSelected !== view) {
			this.setState({ rightButtonsSelected: view });
		} else {
			this.setState({ rightButtonsSelected: MAIN_VIEW });
		}
	};

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const { niProduction, niPseudoGroups } = this.props;
		const { selectedRegion, selectedPeriod, startDate, ute, capAdd } =
			this.state;

		if (!this.regionDataAvailable()) {
			return {
				csvFileName: '',
				csvHeaders: [],
				csvData: []
			};
		}

		// Initialise our csv data
		const csvFileName = `ni-production-${selectedRegion}-${selectedPeriod}-from-${startDate.format(
			'YYYY'
		)}.csv`;
		const csvHeaders = [
			{
				label: `${selectedPeriod[0].toUpperCase()}${selectedPeriod.slice(1)}`,
				key: 't'
			},
			{ label: 'Production (kt)', key: 'p' },
			{ label: `UTE: ${ute}`, key: '' },
			{ label: `Extra Capacity: ${capAdd}%`, key: '' }
		];

		const csvData = niProduction[selectedPeriod][
			niPseudoGroups.find(({ key }) => key === selectedRegion).name
		].data.map(({ t, p }) => ({ t, p }));

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildCsvDownloadButton() {
		const { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();
		const { isUteEditing, isCapAddEditing } = this.state;
		if (csvData.length !== 0 && !isUteEditing && !isCapAddEditing) {
			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: 'niProduction',
							action: 'data downloaded',
							label: csvFileName
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="btn btn-success mb-3 pull-right btn-sm"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	buildFilterForm() {
		const {
			selectedRegion,
			ute,
			capAdd,
			rightButtonsSelected,
			startDate,
			selectedPeriod,
			isUserPremiumPlus
		} = this.state;
		const { niPseudoGroups } = this.props;
		if (!niPseudoGroups.length) return;

		const groupedPGs = groupByKey(niPseudoGroups, 'type');

		const { country: countryPGs, ...pgs } = groupedPGs;

		const otherPGs = []
			.concat(...Object.values(pgs))
			.filter(({ key }) => !countryPGs.map(({ key }) => key).includes(key));

		return (
			<Form
				className="mb-3"
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Row noGutters className="align-top">
					<Col xs="6" xl="9">
						<Row noGutters>
							<Col xs="12" xl="6">
								<Row noGutters>
									<Col xs="3">
										<Label for="region">
											<span className="sr-only">Region filtering</span>
											<Input
												id="region"
												name="region"
												type="select"
												bsSize="sm"
												className="pointered"
												value={
													[...countryPGs, ...otherPGs].find(
														({ key }) => key === selectedRegion
													).name || 'Australia'
												}
												onChange={this.onInputSelect}
											>
												<option
													key="country"
													value="country"
													disabled
													className="text-uppercase font-weight-bold small"
												>
													Country
												</option>
												{countryPGs.map(({ key, name }) => (
													<option key={key} data-key={key} value={name}>
														{name}
													</option>
												))}
												{otherPGs.length && (
													<option
														key="other"
														value="Other Regions"
														disabled
														className="text-uppercase font-weight-bold small"
													>
														Other Regions
													</option>
												)}
												{otherPGs.map(({ key, name }) => (
													<option key={key} data-key={key} value={name}>
														{name}
													</option>
												))}
											</Input>
										</Label>
									</Col>
									<Col xs="9" className="pl-3">
										<InputGroup size="sm">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													UTE ({this.getDefaultUte()})
												</InputGroupText>
											</InputGroupAddon>
											<Input
												style={{ maxWidth: '4rem' }}
												id="ute"
												type="number"
												name="ute"
												value={ute}
												max="99"
												min="0"
												step="0.01"
												placeholder="0.00"
												onFocus={this.toggleUteEditing}
												onChange={this.onUteChange}
												onBlur={this.onUteBlur}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														e.target.blur();
													}
												}}
											/>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													XTRA ({this.getDefaultCapacityAdd()}%)
												</InputGroupText>
											</InputGroupAddon>
											<Input
												style={{ maxWidth: '4rem' }}
												id="capAdd"
												type="number"
												name="capAdd"
												value={capAdd}
												min="0"
												max="999"
												step="1"
												placeholder="0"
												onFocus={this.toggleCapAddEditing}
												onChange={this.onCapAddChange}
												onBlur={this.onCapAddBlur}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														e.target.blur();
													}
												}}
											/>
										</InputGroup>
									</Col>
								</Row>
							</Col>

							<Col xs="12" xl="6">
								<Row noGutters>
									<Col xs="6">
										<PeriodButtonGroup
											groupClass="mt-xs-3 mt-xl-0"
											ids={['main-month', 'main-quarter', 'main-year']}
											period={selectedPeriod}
											onClick={this.onBtnClick}
										/>
									</Col>
									<Col xs="6">
										<RangeButtonGroup
											groupClass=""
											ids={[
												'main-0Y',
												'main-1Y',
												'main-2Y',
												'main-3Y',
												'main-all'
											]}
											start={startDate}
											onClick={this.onBtnClick}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs="6" xl="3" className="text-right">
						<Row noGutters>
							<Col xs="12">
								<ButtonGroup>
									<Button
										id="buttonSmelters"
										outline
										size="sm"
										color="secondary"
										onClick={() => this.toggleView(SMELTERS_VIEW)}
										active={rightButtonsSelected === SMELTERS_VIEW}
										disabled={isUserPremiumPlus}
										aria-label="Click to show production by smelters"
									>
										Smelters
									</Button>
									<Button
										id="buttonYears"
										outline
										size="sm"
										color="secondary"
										onClick={() => this.toggleView(YEARS_VIEW)}
										active={rightButtonsSelected === YEARS_VIEW}
										aria-label="Click to show production by smelters"
									>
										Compare
									</Button>
									<Button
										id="buttonCompare"
										outline
										size="sm"
										color="secondary"
										// onClick={() => this.toggleSmelters()}
										disabled={true}
										aria-label="Click to show production by smelters"
									>
										Prediction
									</Button>
								</ButtonGroup>
							</Col>
							<Col xs="12" className="mt-2">
								{this.buildCsvDownloadButton()}
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		);
	}
	buildCardHeader() {
		const title = 'Production data for selected region';

		return (
			<CardHeader>
				<i className="fa fa-bar-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	getDefaultChartOptions() {
		return {
			responsive: true,
			hover: { mode: null },
			scales: {
				yAxes: [
					{
						distribution: 'linear',
						position: 'left',
						scaleLabel: {
							display: true,
							labelString: 'Production (kt)'
						},
						ticks: {
							beginAtZero: true
						}
					}
				]
			}
		};
	}
	buildMainView() {
		const { niProduction, niPseudoGroups } = this.props;
		const { selectedRegion, selectedPeriod } = this.state;

		if (!this.regionDataAvailable()) return;

		const options = this.getDefaultChartOptions();

		const regionData =
			niProduction[selectedPeriod][
				niPseudoGroups.find(({ key }) => key === selectedRegion).name
			].data;

		const ptData = regionData.map(({ p }) => p);
		const labels = regionData.map(({ t }) => t);
		const pg = niPseudoGroups.find(({ key }) => key === selectedRegion);

		const barData = {
			labels,
			datasets: [
				{
					label: pg.name,
					data: ptData,
					backgroundColor: colours.savantCopper
				}
			]
		};

		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container fluid>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Bar data={{ ...barData }} options={options} />
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	buildSmelterCardHeader() {
		const { selectedRegion, ute } = this.state;
		const { niPseudoGroups } = this.props;
		const name = niPseudoGroups.find(({ key }) => key === selectedRegion).name;
		const title = `Production data for ${name} smelters, UTE:${ute}`;

		return (
			<CardHeader>
				<i className="fa fa-bar-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	generateSmelterColours = () => {
		const { niPseudoGroupSmelters } = this.props;
		return generateRandomColours(niPseudoGroupSmelters.length);
	};

	buildSmelterBarChart() {
		const { niProductionSmelters, niPseudoGroupSmelters } = this.props;
		const { smelterColours } = this.state;

		const options = this.getDefaultChartOptions();

		const labels = Object.keys(niProductionSmelters);
		const datasets = Object.values(niProductionSmelters).map(({ data }, i) => ({
			label: labels[i],
			data: data.map(({ p }) => p),
			backgroundColor:
				smelterColours[
					niPseudoGroupSmelters.findIndex(({ name }) => name === labels[i])
				]
		}));
		const barData = {
			//all arrays are of the same length
			labels: Object.values(niProductionSmelters)
				.map(({ data }) => data)[0]
				.map(({ t }) => t),
			datasets
		};

		return <Bar data={{ ...barData }} options={options} />;
	}
	buildSmelterView() {
		const { niProductionSmelters } = this.props;
		const { startDateSmelters, selectedPeriodSmelters } = this.state;

		const smeltersDataAvailable =
			Object.keys(niProductionSmelters).length !== 0;

		return (
			<Card className="mb-3" innerRef={this.smeltersRef}>
				{this.buildSmelterCardHeader()}
				<CardBody>
					<Container fluid className="smelter-container">
						<Row noGutters className="mb-2 mt-2 justify-content-end">
							<PeriodButtonGroup
								groupClass="mr-2"
								ids={[
									'bottom-month',
									'bottom-quarter',
									'bottom-year',
									'bottom-3Y'
								]}
								period={selectedPeriodSmelters}
								disabled={!smeltersDataAvailable}
								onClick={this.onBtnClick}
							/>
							<RangeButtonGroup
								groupClass="mr-2"
								ids={['bottom-0Y', 'bottom-1Y', 'bottom-2Y', 'bottom-3Y']}
								start={startDateSmelters}
								onClick={this.onBtnClick}
								disabled={!smeltersDataAvailable}
							/>
						</Row>
						<Row noGutters className="mt-0">
							<Col xs="3" xl="2" className="smelter-list-panel">
								<SmelterList />
							</Col>
							<Col xs="9" xl="10" className="pl-3">
								{smeltersDataAvailable && this.buildSmelterBarChart()}
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	toggleYearsChartView = () => {
		const { showStackedYears } = this.state;
		this.setState({ showStackedYears: !showStackedYears });
	};
	toggleYearsScope = () => {
		const { showYearsQuarterly } = this.state;
		this.setState({ showYearsQuarterly: !showYearsQuarterly });
	};
	buildYearsCardHeader() {
		const { selectedRegion, showYearsQuarterly, ute, capAdd } = this.state;
		const { niPseudoGroups } = this.props;
		const name = niPseudoGroups.find(({ key }) => key === selectedRegion).name;
		const title = `Production ${
			showYearsQuarterly ? 'quarterly' : 'monthly'
		} comparison for ${name}, UTE:${ute}, Extra Capacity: ${capAdd}% `;

		return (
			<CardHeader>
				<i className="fa fa-bar-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}
	regionDataAvailable = (period = '') => {
		const { selectedRegion, selectedPeriod } = this.state;
		const { niPseudoGroups, niProduction } = this.props;
		const givenPeriod = period ? period : selectedPeriod;
		return (
			Object.keys(niProduction).length !== 0 &&
			niProduction.hasOwnProperty(givenPeriod) &&
			niPseudoGroups.length !== 0 &&
			niProduction[givenPeriod].hasOwnProperty(
				niPseudoGroups.find(({ key }) => key === selectedRegion).name
			)
		);
	};
	buildYearsView = () => {
		const { selectedRegion, showYearsQuarterly, showStackedYears, startDate } =
			this.state;
		const { niPseudoGroups, niProduction } = this.props;

		if (
			(!showYearsQuarterly && !this.regionDataAvailable('month')) ||
			(showYearsQuarterly && !this.regionDataAvailable('quarter'))
		)
			return;

		const period = `${showYearsQuarterly ? 'quarter' : 'month'}`;
		const data = niProduction[period][
			niPseudoGroups.find(({ key }) => key === selectedRegion).name
		].data.map(({ t, p }) => ({
			year: showYearsQuarterly ? t.substr(0, 4) : moment.utc(t).format('YYYY'),
			month: !showYearsQuarterly ? moment.utc(t).format('MMM') : '',
			quarter: showYearsQuarterly ? t.substr(5, 7) : '',
			p
		}));
		const datasets = groupByKey(data, 'year');
		const years = Object.keys(datasets);

		const yearData = Object.values(datasets);

		//labels for each dataset are identical
		const labels = !showYearsQuarterly
			? yearData[0].map(({ month }) => month)
			: yearData[0].map(({ quarter }) => quarter);

		const chartDatasets = yearData.map((yd, i) => ({
			label: years[i],
			data: yd.map(({ p }) => p),
			backgroundColor: YEAR_COLOURS.find(({ year }) => year === years[i]).colour
		}));

		const defaultOptions = this.getDefaultChartOptions();

		const options = {
			...defaultOptions,
			scales: {
				...defaultOptions.scales,
				yAxes: defaultOptions.scales.yAxes.map((ya) => ({
					...ya,
					stacked: showStackedYears ? true : false
				})),
				xAxes: [
					{
						stacked: showStackedYears ? true : false
					}
				]
			}
		};

		const barData = {
			labels,
			datasets: chartDatasets
		};

		return (
			<Card className="mb-3" innerRef={this.yearsRef}>
				{this.buildYearsCardHeader()}
				<CardBody>
					<Container fluid>
						<Row noGutters className="mb-2 mt-2 justify-content-end">
							<Button
								id="chart-type"
								outline
								size="sm"
								color="secondary"
								onClick={this.toggleYearsScope}
								aria-label={'Chart type'}
								className="mr-2 light-active-border"
							>
								{showYearsQuarterly ? 'Monthly' : 'Quarterly'}
							</Button>
							<Button
								id="chart-type"
								outline
								size="sm"
								color="secondary"
								onClick={this.toggleYearsChartView}
								aria-label={'Chart type'}
								className="light-active-border"
								disabled={moment.utc().diff(startDate, 'years') < 1}
							>
								{showStackedYears ? 'Parallel View' : 'Stacked View'}
							</Button>
						</Row>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Bar data={{ ...barData }} options={options} />
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	};

	buildBottomView = () => {
		const { rightButtonsSelected } = this.state;
		if (rightButtonsSelected === SMELTERS_VIEW) {
			return this.buildSmelterView();
		} else if (rightButtonsSelected === YEARS_VIEW) {
			return this.buildYearsView();
		}
	};

	render() {
		return (
			<DocumentTitle title="SAVANT | Production">
				<div className="content-wrapper">
					<Container fluid>
						<Crumbs type="ni" path={[{ title: 'Production' }]} />
						{this.buildFilterForm()}
						{this.buildMainView()}
						{this.buildBottomView()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	niPseudoGroups,
	niProduction,
	niProductionSmelters,
	niPseudoGroupSmelters,
	niSelectedProdSmelters
}) => {
	return {
		niPseudoGroups,
		niProduction,
		niPseudoGroupSmelters,
		niProductionSmelters,
		niSelectedProdSmelters
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchNiPseudoGroups: (filterData) =>
		dispatch(fetchNiPseudoGroups(filterData)),
	resetNiPseudoGroups: () => dispatch(resetNiPseudoGroups()),
	fetchNiPseudoGroupSmelters: (filterData) =>
		dispatch(fetchNiPseudoGroupSmelters(filterData)),
	resetNiPseudoGroupSmelters: () => dispatch(resetNiPseudoGroupSmelters()),
	fetchNiProductionData: (filterData) =>
		dispatch(fetchNiProductionData(filterData)),
	resetNiProductionData: () => dispatch(resetNiProductionData()),
	fetchNiProductionSmeltersData: (filterData) =>
		dispatch(fetchNiProductionSmeltersData(filterData)),
	resetNiProductionSmeltersData: () =>
		dispatch(resetNiProductionSmeltersData()),
	selectNiProdSmelter: (key) => dispatch(selectNiProdSmelter(key)),
	resetNiProdSmelters: () => dispatch(resetNiProdSmelters())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NiSmeltersProduction);
