import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress,
	UncontrolledTooltip
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga';
import { ma } from 'moving-averages';
import Select from 'react-select';

import {
	getAuthenticatedUserGroups,
	fetchNewestDate,
	resetNewestDate,
	fetchSmelterData,
	resetSmelterData,
	fetchPseudoGroups,
	resetPseudoGroups
} from 'actions';
import {
	fChartOptions,
	aChartOptions,
	aThresholdStyle,
	genChartStyle,
	annotationsStem,
	annotationStem,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover } from 'components';
import { multiSelectStyle } from './multiSelectStyle';
import { smeltersInlineHelpContent } from './smeltersInlineHelpContent';
import { isUserFree, isUserSales } from '../../utils';

import './Smelters.scss';

const config = {
	dateRangeDayLimit: 10,
	smoothingOptions: [
		{
			title: '2 day moving average',
			days: 2
		},
		{
			title: '4 day moving average',
			days: 4
		},
		{
			title: '7 day moving average',
			days: 7
		},
		{
			title: '14 day moving average',
			days: 14
		},
		{
			title: '21 day moving average',
			days: 21
		},
		{
			title: '30 day moving average',
			days: 30
		}
	],
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'Inactive Capacity Index',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined index',
		colour: '#FFA500',
		title: 'Combine multiple regions'
	},
	fakePseudoGroups: [
		{
			name: 'Global (ex. CN)',
			key: 'globalexchina'
		},
		{
			name: 'China',
			key: 'china'
		},
		{
			name: 'Asia and Oceania',
			key: 'asiaoceania'
		},
		{
			name: 'Europe',
			key: 'europe'
		},
		{
			name: 'Africa',
			key: 'africa'
		},
		{
			name: 'North America',
			key: 'namerica'
		},
		{
			name: 'South America',
			key: 'samerica'
		}
	]
};

class Smelters extends Component {
	state = {
		groupType: 'default',
		global: true,
		regions: [],
		countryRegions: [],
		chinaRegions: [],
		categoryRegions: [],
		selectedRegions: [],
		compareCombine: '',
		showDispersion: false,
		dateMin: moment.utc('2016-03-01'),
		dateMax: moment.utc(),
		dateFrom: moment.utc(),
		dateTo: moment.utc(),
		smoothingDays: 7,
		isInitDateRange: false,
		shortDateRange: false,
		showDataWarning: false,
		canDownload: false
	};

	async componentDidMount() {
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});

		this.props.fetchPseudoGroups({ ute: false });
		this.props.fetchNewestDate();
		ReactGA.pageview('/smelters', null, 'Smelter Operations');
	}

	componentDidUpdate(
		{ newestDate: prevNewestDate },
		{ isInitDateRange: prevIsInitDateRange }
	) {
		const { newestDate } = this.props;
		const { isInitDateRange } = this.state;

		//fetchNewestDate requested in ComponentDidMount(), ensure newestDate is received
		if (newestDate === '') {
			return;
		}

		//Date setting
		if (newestDate !== prevNewestDate) {
			this.updateDateHandling({ newestDate });
		}

		//once date range is set, fetch data
		if (isInitDateRange !== prevIsInitDateRange) {
			this.filterData();
		}
	}

	componentWillUnmount() {
		this.props.resetSmelterData();
		// this.props.resetSmelterUpdates();
		this.props.resetNewestDate();
		this.props.resetPseudoGroups();
	}

	async updateDateHandling({ newestDate }) {
		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();

		const maxDate = moment.utc(newestDate);

		let downloadState = {};

		//for enterprise user, savant-premium-plus, savant-premium
		let dateState = {
			dateFrom: maxDate.clone().subtract(1, 'y').subtract(1, 'd'),
			dateTo: maxDate.clone().subtract(1, 'd'),
			dateMax: maxDate.clone().subtract(1, 'd')
		};

		if (isUserSales(authGroups, 'cu')) {
			dateState = {
				...dateState,
				dateTo: maxDate.clone().subtract(4, 'w').subtract(1, 'd'),
				dateMax: maxDate.clone().subtract(4, 'w').subtract(1, 'd')
			};
		} else if (isUserFree(authGroups, 'cu')) {
			dateState = {
				...dateState,
				dateTo: maxDate.clone().subtract(2, 'w').subtract(1, 'd'),
				dateMax: maxDate.clone().subtract(2, 'w').subtract(1, 'd')
			};
		}
		if (!isUserFree(authGroups, 'cu')) {
			downloadState = {
				canDownload: true
			};
		}
		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		await this.setState({ ...rSelected });
	}

	toggleIndex() {
		const { showDispersion } = this.state;
		this.setState({ showDispersion: !showDispersion });
	}

	// Global button click
	async onGlobalBtnClick() {
		const { global, groupType } = this.state;
		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			if (groupType === 'default') {
				newState = { regions: [] };
			} else if (groupType === 'country') {
				newState = { countryRegions: [] };
			} else if (groupType === 'china') {
				newState = { chinaRegions: [] };
			} else if (groupType === 'category') {
				newState = { categoryRegions: [] };
			}
			newState = { ...newState, selectedRegions: [] };
		}

		//toggle global
		newState = { ...newState, global: !global };

		await this.setState(newState);
		this.filterData();
	}

	// region buttons
	async onRegionBtnClick({ prop, val }) {
		//existing regions
		let regions = this.state[prop];
		const oldRegionsCount = regions.length;

		if (regions.includes(val)) {
			regions = regions.filter((item) => item !== val);
		} else {
			// deselect 'globalexchina' button if any button excluding 'china' button is clicked
			if (val !== 'china' && regions.includes('globalexchina')) {
				regions = regions.filter((item) => item !== 'globalexchina');
				//deselect 'non china' buttons if Global (ex. CN) is clicked
			} else if (val === 'globalexchina') {
				regions = regions.filter((item) => item === 'china');
			}
			regions = [...regions, val];
		}
		const sortedRegions = this.sortRegions(regions);

		let newState = {
			[prop]: sortedRegions,
			selectedRegions: sortedRegions,
			global: false
		};

		if (sortedRegions.length <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldRegionsCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}

		await this.setState(newState);
		this.filterData();
	}

	async onSelectRegions(newRegions) {
		const { groupType, countryRegions, chinaRegions, categoryRegions } =
			this.state;

		if (!newRegions) {
			newRegions = [];
		}

		let oldRegionsCount = 0;
		let newState = {};

		if (groupType === 'country') {
			oldRegionsCount = countryRegions.length;
			newState = { ...newState, countryRegions: this.sortRegions(newRegions) };
		} else if (groupType === 'china') {
			oldRegionsCount = chinaRegions.length;
			newState = { ...newState, chinaRegions: this.sortRegions(newRegions) };
		} else if (groupType === 'category') {
			oldRegionsCount = categoryRegions.length;
			newState = { ...newState, categoryRegions: this.sortRegions(newRegions) };
		}

		const sortedRegions = this.sortRegions(
			newRegions.map((region) => region.value)
		);

		newState = {
			...newState,
			global: false,
			selectedRegions: sortedRegions
		};

		if (newRegions.length <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldRegionsCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}

		await this.setState(newState);
		this.filterData();
	}

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { regions, compareCombine } = this.state;
		const { pseudoGroups } = this.props;
		//exclude 'globalexchina' from All
		const defaultGroups = pseudoGroups.filter(
			(g) => g.type === 'default' && g.key !== 'globalexchina'
		);

		const newRegions =
			regions.length < defaultGroups.length
				? defaultGroups.map((g) => g.key)
				: [];
		const newCompareCombine = regions.length > 1 ? compareCombine : 'combine';
		await this.setState({
			regions: newRegions,
			selectedRegions: newRegions,
			compareCombine: newRegions.length ? newCompareCombine : '',
			global: false
		});
		this.filterData();
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate }) {
		//startDate, endDate - moment objects
		await this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange: endDate.diff(startDate, 'days') < config.dateRangeDayLimit
		});
		!this.state.shortDateRange && this.filterData();
	}

	//sort plain array of regions' names or array of region objects
	sortRegions = (regions) => {
		const { pseudoGroups } = this.props;
		const { groupType } = this.state;
		if (!regions.length) {
			return regions;
		}
		const regionOrder = pseudoGroups
			.filter((g) => g.type === groupType)
			.map((g) => g.key);

		return regionOrder.reduce((acc, r) => {
			let filtered = [];
			filtered = regions.filter((elem) => {
				return Object.keys(elem).length && Object.keys(elem).includes('value')
					? elem['value'] === r
					: elem === r;
			});

			if (filtered.length) {
				acc.push(filtered[0]);
			}
			return acc;
		}, []);
	};

	// InputSelect for the smoothing selection or group type
	onInputSelect = async ({ target: { value, name } }) => {
		const { regions, countryRegions, chinaRegions, categoryRegions } =
			this.state;

		if (name === 'smoothingDays') {
			//values inside drop down list are strings
			this.setState({
				[name]: parseInt(value)
			});
			return;
		}

		await this.setState({
			[name]: value
		});
		if (name === 'groupType') {
			let selectedRegions = [];

			if (value === 'default') {
				selectedRegions = [...selectedRegions, ...regions];
			} else if (value === 'country') {
				selectedRegions = [
					...selectedRegions,
					...countryRegions.map((region) => region.value)
				];
			} else if (value === 'china') {
				selectedRegions = [
					...selectedRegions,
					...chinaRegions.map((region) => region.value)
				];
			} else if (value === 'category') {
				selectedRegions = [
					...selectedRegions,
					...categoryRegions.map((region) => region.value)
				];
			}
			const global = selectedRegions.length ? false : true;
			await this.setState({
				selectedRegions: selectedRegions,
				global: global
			});
			this.filterData();
		}
	};

	// Run action creator to fetch smelter data based on filter form state
	filterData() {
		const { global, dateFrom, dateTo, selectedRegions } = this.state;
		if (global || selectedRegions.length > 0) {
			this.props.resetSmelterData();
			this.props.fetchSmelterData({
				regions: global ? 'global' : selectedRegions.toString(),
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
			// this.props.resetSmelterUpdates();
			// this.props.fetchSmelterUpdates({
			// 	regions: global ? 'global' : selectedRegions.toString(),
			// 	dateFrom: dateFrom.format(config.apiDateFormat),
			// 	dateTo: dateTo.format(config.apiDateFormat)
			// });
		} else if (!global && !selectedRegions.length > 0) {
			this.props.resetSmelterData();
			// this.props.resetSmelterUpdates();
		}

		ReactGA.event({
			category: 'smelterIndex',
			action: 'data displayed',
			label: `From: ${dateFrom.format(config.apiDateFormat) || 'nil'} | To: ${
				dateTo.format(config.apiDateFormat) || 'nil'
			}`
		});
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type };
	}

	// Build the card header element for display
	buildCardHeader() {
		const { compareCombine, global, showDispersion } = this.state;

		let title = showDispersion ? 'Activity Dispersion:' : 'Inactive Capacity:';
		if (global) {
			title = `${title} ${config.globalRegion.title}`;
		} else if (compareCombine === 'combine') {
			title = `${title} ${config.combinedRegion.title}`;
		} else if (compareCombine === 'compare') {
			title = `${title} Compare multiple regions`;
		} else {
			title = `${title} One region`;
		}

		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title, tooltip = '', disabled = false }) {
		const { regions } = this.state;

		return (
			<Fragment key={`region${name}`}>
				<Button
					id={id}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ prop: 'regions', val: name })}
					active={regions.includes(name)}
					aria-label={title}
					className="light-active-border"
					disabled={disabled}
				>
					{title}
				</Button>
				{tooltip !== '' && (
					<UncontrolledTooltip
						placement="top"
						delay={{ show: 1500, hide: 500 }}
						target={id}
						dangerouslySetInnerHTML={{ __html: tooltip }}
					/>
				)}
			</Fragment>
		);
	}

	buildCsvDownloadButton() {
		const {
			global,
			shortDateRange,
			regions,
			countryRegions,
			chinaRegions,
			categoryRegions,
			groupType,
			canDownload
		} = this.state;
		const { smelterData } = this.props;

		if (
			(global ||
				(groupType === 'default' && regions.length > 0) ||
				(groupType === 'country' && countryRegions.length > 0) ||
				(groupType === 'china' && chinaRegions.length > 0) ||
				(groupType === 'category' && categoryRegions.length > 0)) &&
			!shortDateRange &&
			smelterData.length !== 0 &&
			canDownload
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => {
						ReactGA.event({
							category: 'smelterIndex',
							action: 'data downloaded',
							label: csvFileName
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const {
			pseudoGroups = [],
			hasAdvancedGroups = false,
			countryGroups,
			chinaGroups,
			categoryGroups,
			newestDate
		} = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			showDispersion,
			global,
			groupType,
			regions,
			countryRegions,
			chinaRegions,
			categoryRegions,
			selectedRegions
		} = this.state;

		const regionToggleHidden = pseudoGroups.length <= 2;
		//max num of selected buttons will be 'all default regions' length-1, since 'globalexchina' buttons exludes other region buttons (except China)
		const regionAllCopy =
			regions.length ===
			pseudoGroups.filter(({ type }) => type === 'default').length - 1
				? 'None'
				: 'All';

		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							{hasAdvancedGroups && (
								<Label for="groupType" className="mb-2 mr-3 align-top">
									<span className="sr-only">Geographical filtering type</span>
									<Input
										id="groupType"
										name="groupType"
										type="select"
										bsSize="sm"
										className="pointered"
										defaultValue={groupType}
										onChange={this.onInputSelect}
									>
										<option value="default">Region filtering</option>
										<option value="country">Country filtering</option>
										<option value="china">Chinese Region filtering</option>
										<option value="category">Smelter type filtering</option>
									</Input>
								</Label>
							)}

							<Button
								id="regionGlobal"
								outline
								size="sm"
								color="secondary"
								active={global}
								aria-label="global"
								className="mb-2 light-active-border"
								onClick={() => this.onGlobalBtnClick()}
							>
								Global
							</Button>

							{groupType === 'default' && pseudoGroups.length === 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{config.fakePseudoGroups.map(({ name, key }) => {
										return this.buildRegionButton({
											id: `region${key}`,
											name: key,
											title: name,
											disabled: true
										});
									})}
								</ButtonGroup>
							)}
							{groupType === 'default' && pseudoGroups.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{pseudoGroups
										.filter(({ type }) => type === 'default')
										.map(({ name, key, tooltip = '' }) => {
											return this.buildRegionButton({
												id: `region${key}`,
												name: key,
												title: name,
												tooltip
											});
										})}
								</ButtonGroup>
							)}

							{groupType === 'default' && (
								<Button
									id="regionAll"
									color="primary"
									size="sm"
									onClick={() => this.onToggleAllRegions()}
									className="mb-2 ml-1"
									style={{ width: '54px' }}
									disabled={global}
									hidden={regionToggleHidden}
								>
									{regionAllCopy}
								</Button>
							)}
							{groupType === 'country' && (
								<Select
									isMulti
									options={countryGroups}
									value={countryRegions}
									onChange={(val) => this.onSelectRegions(val)}
									className="react-select-container d-inline-block mb-2 ml-3"
									classNamePrefix="react-select"
									placeholder="Select countries..."
									styles={multiSelectStyle}
								/>
							)}
							{groupType === 'china' && (
								<Select
									isMulti
									options={chinaGroups}
									value={chinaRegions}
									onChange={(val) => this.onSelectRegions(val)}
									className="react-select-container d-inline-block mb-2 ml-3"
									classNamePrefix="react-select"
									placeholder="Select Chinese regions..."
									styles={multiSelectStyle}
								/>
							)}
							{groupType === 'category' && (
								<Select
									isMulti
									options={categoryGroups}
									value={categoryRegions}
									onChange={(val) => this.onSelectRegions(val)}
									className="react-select-container d-inline-block mb-2 ml-3"
									classNamePrefix="react-select"
									placeholder="Select smelters by type..."
									styles={multiSelectStyle}
								/>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={newestDate}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>
							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={selectedRegions.length <= 1}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={selectedRegions.length <= 1}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							<Button
								id="buttonCapacityDispersion"
								className="mb-3 mr-3 align-bottom"
								outline
								size="sm"
								color="secondary"
								onClick={() => this.toggleIndex()}
								disabled={!global && !selectedRegions.length}
								aria-label="Click to switch between Capacity Index and Dispersion Index"
							>
								{showDispersion ? 'Inactive Capacity' : 'Activity Dispersion'}
							</Button>

							<Label for="smoothingDays" className="mb-3 mr-2 smoothing-select">
								<span className="sr-only">Smoothing options</span>
								<Input
									id="smoothingDays"
									name="smoothingDays"
									type="select"
									bsSize="sm"
									className="pointered smoothingOptions"
									value={this.state.smoothingDays}
									onChange={this.onInputSelect}
								>
									<option value="0">No data smoothing</option>
									{config.smoothingOptions.map(({ title, days }, idx) => {
										return (
											<option key={`smooth${idx}`} value={days}>
												{title}
											</option>
										);
									})}
								</Input>
							</Label>
							{this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			smelterData: { compare = [], combine = [] },
			pseudoGroups
		} = this.props;
		let { dateFrom, dateTo } = this.state;

		// Initialise our csv data
		let csvFileName = `smelter-data-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let csvHeaders = [{ label: 'Date', key: 't' }];
		let csvData = [];

		compare.forEach(({ name: key, data }, i) => {
			const name =
				key !== 'global'
					? pseudoGroups.find((pg) => pg['key'] === key)['name']
					: 'Global';
			data.forEach(({ t, f, a }, j) => {
				// Add the time column value to the csv data if this is the first region iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (i === 0 || !csvData[j]) {
					csvData[j] = {
						t: moment.utc(t).format(config.dspDateFormat)
					};
				}

				// Generate the region-based f and σ column names
				let namefkey = `${name} Inactive Capacity`;
				let nameakey = `${name} Activity Dispersion`;

				// Add the column names to the csv headers if this is the first data iteration
				if (j === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namefkey,
								key: namefkey
							},
							{
								label: nameakey,
								key: nameakey
							}
						]
					];
				}

				// Set the f column on the csv data
				csvData[j][namefkey] = f;
				csvData[j][nameakey] = a;
			});
		});

		if (compare.length > 1) {
			combine.forEach(({ f, a }, k) => {
				// Generate the f column names
				let namefkey = `Combined Inactive Capacity`;
				let nameakey = `Combined Activity Dispersion`;

				// Add the column names to the csv headers if this is the first data iteration
				if (k === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namefkey,
								key: namefkey
							},
							{
								label: nameakey,
								key: nameakey
							}
						]
					];
				}

				// Set the f column on the csv data
				csvData[k][namefkey] = f;
				csvData[k][nameakey] = a;
			});
		}

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	// Apply a moving average smoothing to the data provided
	smoothSmelterData({ days, data }) {
		let fSmoothedData = data.map(({ f }) => parseFloat(f));
		fSmoothedData = ma(fSmoothedData, days);

		let aSmoothedData = data.map(({ a }) => parseFloat(a));
		aSmoothedData = ma(aSmoothedData, days);

		return data.map((item, i) => {
			if (i < days - 1) {
				return { ...item, f: null, a: null };
			}
			return {
				...item,
				f: fSmoothedData[i] && fSmoothedData[i].toFixed(1),
				a: aSmoothedData[i] && aSmoothedData[i].toFixed(1)
			};
		});
	}

	buildChartAnnotations() {
		const { smelterUpdates = [] } = this.props;
		const { dateFrom, dateTo } = this.state;
		const totalDays = dateTo.diff(dateFrom, 'days');

		let annotation = {};
		if (smelterUpdates.length > 0) {
			annotation = {
				...annotationsStem,
				annotations: []
			};

			smelterUpdates.forEach(({ detail, update_date }, idx) => {
				let newAnnotation = {
					...annotationStem,
					id: `anno${idx}`,
					value: new Date(update_date),
					label: {
						...annotationStem.label,
						content: detail
					}
				};

				// Position the labels away from the edges
				const pos = Math.floor(
					(moment.utc(update_date).diff(dateFrom, 'days') / totalDays) * 100
				);
				if (pos <= 13) {
					newAnnotation.label.xAdjust = -((detail.length / 2) * 6.1);
				} else if (pos >= 87) {
					newAnnotation.label.xAdjust = (detail.length / 2) * 6.1;
				}

				annotation.annotations.push(newAnnotation);
			});
		}
		return annotation;
	}

	getChartOptionsWithAnnotations(min = null, max = null) {
		const { showDispersion } = this.state;

		//setting y-axis bounds dynamically for dispersion graph
		const aChartOptionsWithRange =
			min !== null && max !== null
				? {
						...aChartOptions,
						scales: {
							...aChartOptions.scales,
							yAxes: aChartOptions.scales.yAxes.map((ya) => ({
								...ya,
								ticks: { ...ya.ticks, min: min, max: max }
							}))
						}
				  }
				: aChartOptions;

		const fChartOptionsWithRange =
			min === 0 && max === 0
				? {
						...fChartOptions,
						scales: {
							...fChartOptions.scales,
							yAxes: fChartOptions.scales.yAxes.map((ya) => ({
								...ya,
								ticks: { ...ya.ticks, min: 0 }
							}))
						}
				  }
				: fChartOptions;

		const options = showDispersion
			? aChartOptionsWithRange
			: fChartOptionsWithRange;

		return {
			...options,
			annotation: this.buildChartAnnotations()
		};
	}

	getSelectedDetail(selected = []) {
		const { pseudoGroups = [] } = this.props;

		if (selected.length === 1 && selected[0] === 'global') {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (selected.length === 1 && selected[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (selected.length >= 1) {
			return selected.map((s) => pseudoGroups.find((group) => group.key === s));
		}
	}

	getChartData(dataArr) {
		const {
			showDispersion,
			smoothingDays: days,
			global,
			selectedRegions
		} = this.state;

		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));
		//area between threshold 50% line and plot should be filled only when showing one dispersion (combine, global or one region) plot
		const fillPlot = dataArr.length === 1 ? '-1' : false;
		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			// Smooth the data if required
			if (days !== 0) {
				data = this.smoothSmelterData({
					days,
					data
				});
			}

			const detail = details.find((d) => d.key === name);
			//filled point for combine
			const fCombinePointBackgroundColour =
				name === 'combine' ? { pointBackgroundColor: detail.colour } : {};

			//fStyle will be added to existing active capacity style in configuration
			const fStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...fCombinePointBackgroundColour
			};

			//aStyle will be added to existing activity dispersion style in configuration;
			//threshold will be the first dataset in array with index 0
			const aStyle =
				name !== 'combine'
					? { pointBackgroundColor: '#FFFFFF', fill: fillPlot }
					: { label: 'Combined index' };
			let addStyle = {};
			//will be added to existing activity dispersion style when multiple regions are selected
			if (
				showDispersion &&
				name !== 'combine' &&
				!global &&
				selectedRegions.length > 0
			) {
				addStyle = { ...fStyle, ...aStyle };
			} else {
				addStyle = showDispersion ? aStyle : fStyle;
			}

			return {
				data: data.map(({ t, f, a }) => {
					return {
						t: moment.utc(t).format(config.dspDateFormat),
						y: showDispersion ? a : f
					};
				}),
				...genChartStyle(this.getChartType()),
				...addStyle
			};
		});
	}

	getRangeY(dataset) {
		const { showDispersion } = this.state;
		const data = dataset
			.map((ds) => ds.data.map((d) => d.y))
			.reduce((arr, elem) => arr.concat(elem));
		//smoothing adds some nulls at the beginning of array, remove nulls
		const dataWithoutNull = data.filter((d) => d !== null);
		const maxValue = Math.max(...dataWithoutNull);
		const minValue = Math.min(...dataWithoutNull);
		if (!showDispersion) {
			return { minY: minValue, maxY: maxValue };
		}
		//delta = max(max(maxValue,50)-50, 50-min(50,minValue))
		const delta = Math.max(
			Math.ceil(Math.max(maxValue, 50)) - 50,
			50 - Math.floor(Math.min(50, minValue))
		);
		return { minY: 50 - delta, maxY: 50 + delta };
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { smelterData } = this.props;
		const { compareCombine, global, selectedRegions, showDispersion } =
			this.state;

		const isCombined =
			compareCombine === 'combine' && !global && selectedRegions.length > 1;
		let apiData = smelterData.compare;
		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		if (!apiData || !apiData.length) {
			return this.buildLoadingMessage();
		}
		apiData = isCombined
			? [{ name: 'combine', data: smelterData.combine }]
			: apiData;

		const datasets = this.getChartData(apiData);
		const { minY, maxY } = this.getRangeY(datasets);

		//build data for horizontal line at 50% of activity dispersion plot
		if (showDispersion) {
			const threshold = {
				data: smelterData.combine.map((point) => ({
					t: moment.utc(point.t).format(config.dspDateFormat),
					y: 50
				})),
				...aThresholdStyle
			};
			datasets.unshift(threshold);
		}

		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line
									data={{ datasets }}
									options={this.getChartOptionsWithAnnotations(minY, maxY)}
									redraw
								/>
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedRegions } = this.state;

		return (
			<DocumentTitle title="SAVANT | Smelter Operations">
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						<div className="pull-right">{smeltersInlineHelpContent}</div>
						<Crumbs path={[{ title: 'Smelter Operations' }]} />
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedRegions.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							((!global && selectedRegions.length > 0) ||
								(global && !selectedRegions.length)) &&
							this.buildLineChart()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	newestDate,
	pseudoGroups,
	smelterData,
	smelterUpdates
}) => {
	let countryGroups = [];
	let chinaGroups = [];
	let categoryGroups = [];
	let hasAdvancedGroups = false;
	if (pseudoGroups && pseudoGroups.length > 0) {
		countryGroups = pseudoGroups
			.filter((grp) => {
				return grp.type === 'country';
			})
			.map(({ key, name }) => {
				return {
					value: key,
					label: name
				};
			})
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		chinaGroups = pseudoGroups
			.filter((grp) => {
				return grp.type === 'china';
			})
			.map(({ key, name }) => {
				return {
					value: key,
					label: name
				};
			})
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		categoryGroups = pseudoGroups
			.filter((grp) => {
				return grp.type === 'category';
			})
			.map(({ key, name }) => {
				return {
					value: key,
					label: name
				};
			})
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		hasAdvancedGroups =
			countryGroups.length > 0 ||
			chinaGroups.length ||
			categoryGroups.length > 0;
	}

	return {
		newestDate,
		pseudoGroups,
		smelterData,
		smelterUpdates,
		countryGroups,
		chinaGroups,
		categoryGroups,
		hasAdvancedGroups
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchNewestDate: () => dispatch(fetchNewestDate()),
	resetNewestDate: () => dispatch(resetNewestDate()),
	fetchSmelterData: (filterData) => dispatch(fetchSmelterData(filterData)),
	resetSmelterData: () => dispatch(resetSmelterData()),
	fetchPseudoGroups: (filterData) => dispatch(fetchPseudoGroups(filterData)),
	resetPseudoGroups: () => dispatch(resetPseudoGroups())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Smelters));
