import { API } from 'aws-amplify';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import {
	getAuthHeaders,
	FETCH_NI_SMELTER_ALL_MAP_DATA,
	FETCH_NI_SMELTER_ALL_MAP_DATA_ERR,
	FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
	FETCH_NI_SMELTER_DATA,
	FETCH_NI_SMELTER_DATA_ERR,
	FETCH_NI_SMELTER_DATA_FREE,
	FETCH_NI_SMELTER_DATA_FREE_ERR,
	FETCH_NI_SMELTER_LIST,
	FETCH_NI_SMELTER_LIST_ERR,
	FETCH_NI_SMELTER_MAP_DATA,
	FETCH_NI_SMELTER_MAP_DATA_ERR,
	FETCH_NI_NEWEST_DATE,
	FETCH_NI_NEWEST_DATE_ERR,
	FETCH_NI_SMELTER_RECENT,
	FETCH_NI_SMELTER_RECENT_ERR,
	FETCH_NI_SMELTER_RECENT_UPDATES,
	FETCH_NI_SMELTER_RECENT_UPDATES_ERR,
	FETCH_NI_SMELTER_REGIONS,
	FETCH_NI_SMELTER_REGIONS_ERR,
	FETCH_NI_SMELTER_UPDATES,
	FETCH_NI_SMELTER_UPDATES_ERR,
	FETCH_NI_SMELTER_CAPACITY,
	FETCH_NI_SMELTER_CAPACITY_ERR,
	RESET_NI_SMELTER_MAP_DATA,
	RESET_NI_NEWEST_DATE,
	SELECT_NI_SMELTER,
	UNSELECT_NI_SMELTER
} from '../';
import config from '../../config';
import { replacer } from '../../utils';

export const fetchNiSmelterAllMapData = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.niSmelterAllMapData,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_NI_SMELTER_ALL_MAP_DATA,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_NI_SMELTER_ALL_MAP_DATA_ERR,
				payload: err
			})
	);
};

export const fetchNiSmelterAllTimeBySmelterData =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterAllTimeBySmelter, {
				smelterIds
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchNiSmelterData =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterData, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_SMELTER_DATA,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_NI_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchNiSmelterDataFree = () => async (dispatch) => {
	await axios
		.get(config.apiGateway.URL + config.apiEndpoints.niSmelterDataFree)
		.then((response) => {
			dispatch({
				type: FETCH_NI_SMELTER_DATA_FREE,
				payload: response.data
			});
		})
		.catch((err) => {
			dispatch({
				type: FETCH_NI_SMELTER_DATA_FREE_ERR,
				payload: err
			});
		});
};

export const fetchNiSmelterMapData =
	({ regionId, searchDate }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterMapData, {
				regionId,
				searchDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_NI_SMELTER_MAP_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_NI_SMELTER_MAP_DATA_ERR,
					payload: err
				})
		);
	};

export const resetNiSmelterMapData = () => ({
	type: RESET_NI_SMELTER_MAP_DATA
});

export const fetchNiSmelterList = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.niSmelterList, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_NI_SMELTER_LIST,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_NI_SMELTER_LIST_ERR,
				payload: err
			})
	);
};

export const fetchNiSmelterRecent =
	({ count = 4 }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterRecent, {
				count
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_SMELTER_RECENT,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_NI_SMELTER_RECENT_ERR,
					payload: err
				})
		);
	};

export const fetchNiSmelterRecentUpdates = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.niSmelterRecentUpdates,
		{
			headers: await getAuthHeaders()
		}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_NI_SMELTER_RECENT_UPDATES,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_NI_SMELTER_RECENT_UPDATES_ERR,
				payload: err
			})
	);
};

export const fetchNiSmelterRegions = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.niSmelterRegions, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_NI_SMELTER_REGIONS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_NI_SMELTER_REGIONS_ERR,
				payload: err
			})
	);
};

export const fetchNiSmelterUpdates =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterUpdates, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_SMELTER_UPDATES,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_NI_SMELTER_UPDATES_ERR,
					payload: err
				})
		);
	};

export const fetchNiSmelterCapacity =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.niSmelterCapacity, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_NI_SMELTER_CAPACITY,
					payload: response
				});
				if (response.compare && response.compare.length) {
					response.compare.forEach(({ key, data }) => {
						if (data.length === 0) {
							toastr.warning(`No capacity data found for ${key} `);
						}
					});
				} else if (response.combine && response.combine.length === 0) {
					toastr.warning(`No capacity data found for ${regions} `);
				}
			},
			(err) => {
				const message =
					(err.response && err.response.data && err.response.data.message) ||
					err;
				dispatch({
					type: FETCH_NI_SMELTER_CAPACITY_ERR,
					payload: err
				});
				toastr.error(`Get Capacity, ${message} `);
			}
		);
	};

export const fetchNiNewestDate = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.niNewestDate, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_NI_NEWEST_DATE,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_NI_NEWEST_DATE_ERR,
				payload: err
			})
	);
};

export const resetNiNewestDate = () => ({
	type: RESET_NI_NEWEST_DATE
});

export const resetNiSmelterAllTimeBySmelterData = () => ({
	type: FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	payload: {}
});

export const resetNiSmelterData = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_NI_SMELTER_DATA, payload: [] });
	};
};

export const resetNiSmelterUpdates = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_NI_SMELTER_UPDATES, payload: [] });
	};
};

export const resetNiSmelterCapacity = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_NI_SMELTER_CAPACITY, payload: {} });
	};
};

export const selectNiSmelter = (id) => ({
	type: SELECT_NI_SMELTER,
	payload: id
});

export const unselectNiSmelter = (id) => ({
	type: UNSELECT_NI_SMELTER,
	payload: id
});
