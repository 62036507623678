import { SELECT_NI_SMELTER, UNSELECT_NI_SMELTER } from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case SELECT_NI_SMELTER:
			return [...state, action.payload];
		case UNSELECT_NI_SMELTER: {
			return state.filter((item) => item !== action.payload);
		}
		default:
			return state;
	}
};
