import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { BingMapsImageryProvider, BingMapsStyle, Ion } from 'cesium';

import config from '../config';

import earthiLogo from 'images/earthi-rev.png';
import './CesiumMaps.scss';

// Set the Ion token to a real account (trial)
Ion.defaultAccessToken = config.ion.accessToken;

export { Ion };

// Credits
export const earthiCredit = ReactDOMServer.renderToStaticMarkup(
	<a href="https://earthi.space/">
		<img src={earthiLogo} className="credit-earthi-logo" alt="Earth-i" />{' '}
		Earth-i Analytics
	</a>
);

// Initialise a bing imagery provider
export const bingImageryProvider = new BingMapsImageryProvider({
	url: 'https://dev.virtualearth.net',
	key: 'Aj_Z3cRLbLE-uepOrH_x5u93dM8FbqjRvdvPI-Xsg97vqTCMMAAsNLHD0KcP1pWF',
	mapStyle: BingMapsStyle.AERIAL
});
