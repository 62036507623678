import {
	FETCH_STEEL_NEWEST_DATE,
	RESET_STEEL_NEWEST_DATE
} from '../../actions';

export default (state = '', action) => {
	switch (action.type) {
		case FETCH_STEEL_NEWEST_DATE:
			return action.payload;
		case RESET_STEEL_NEWEST_DATE:
			return '';
		default:
			return state;
	}
};
