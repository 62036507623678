import React, { Component } from 'react';
import * as d3Axis from 'd3-axis';
import { scaleTime } from 'd3-scale';
import { select as d3Select, selectAll as d3SelectAll } from 'd3-selection';
import { timeMonth, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';

import ResponsiveWrapper from './ResponsiveWrapper';
import { jsDate } from '../utils';

import './StripPlot.scss';

// Class to render an axis
class Axis extends Component {
	componentDidMount() {
		this.renderAxis();
	}

	componentDidUpdate() {
		this.renderAxis();
	}

	renderAxis() {
		const { orient, scale, tickSizeInner, tickSizeOuter } = this.props;
		const axisType = `axis${orient}`;
		let axis = d3Axis[axisType]().scale(scale);
		axis = axis.tickSizeInner(-tickSizeInner - tickSizeOuter).tickSizeOuter(0);
		axis = axis.ticks(timeMonth, 1).tickFormat((date) => {
			if (timeYear(date) < date) {
				return timeFormat('%b')(date);
			}
			return timeFormat('%Y')(date);
		});
		d3Select(this.axisElement).call(axis);
		d3SelectAll('g.tick text')
			.filter((d) => {
				return timeYear(d) >= d;
			})
			.attr('font-weight', 'bold');
		d3SelectAll('g.tick text')
			.filter((d) => {
				return timeYear(d) < d;
			})
			.attr('color', '#999')
			.style('font-size', '11px');
		d3SelectAll('g.tick line')
			.filter((d) => {
				return timeYear(d) >= d;
			})
			.attr('stroke-width', 2);
		d3SelectAll('g.tick line').attr(
			'transform',
			`translate(0,${tickSizeOuter})`
		);
	}

	render() {
		const { orient, translate } = this.props;

		return (
			<g
				className={`axis axis-${orient.toLowerCase()}`}
				ref={(el) => {
					this.axisElement = el;
				}}
				transform={translate}
			/>
		);
	}
}

// Function to control and configure axes
const Axes = ({ scales, margin, dimensions }) => {
	const { axisHeight, tickHeight, hoverHeight } = dimensions;

	const xProps = {
		orient: 'Bottom',
		scale: scales.xScale,
		translate: `translate(0, ${axisHeight - margin.bottom + hoverHeight})`,
		tickSizeInner: axisHeight - margin.top - margin.bottom,
		tickSizeOuter: tickHeight
	};

	return (
		<g>
			<Axis {...xProps} />
		</g>
	);
};

// Class to render a strip plot - not exported as wrapped in responsive HOC further down
class StripPlot extends Component {
	stripRect({ data, xScale, line: { t, v }, idx, hoverHeight }) {
		const {
			id,
			maxDate,
			plotHeight,
			margin: { top, bottom }
		} = this.props;

		const x1 = xScale(
			idx < data.length - 1
				? new Date(t)
				: data[idx].v === data[idx - 1].v
				? new Date(data[idx - 1].t)
				: new Date(t)
		);
		//if last reading was on maxDate (<1), start with maxDate and finish with (maxDate + 1 day)	else if last reading was earlier (>=1), we will know smelter status on next QC, start with day of last valid reading and finish with maxDate. We want to have block at least one day wide .
		//maxDate moment object in UTC
		const x2 = xScale(
			idx < data.length - 1
				? new Date(data[idx + 1].t)
				: maxDate.diff(data[idx].t, 'days') < 1
				? jsDate(maxDate.clone().add(1, 'd'))
				: jsDate(maxDate)
		);
		const width = x2 - x1;

		if (idx === data.length - 2 && data[idx].v === data[idx + 1].v) {
			return null;
		}

		return (
			<rect
				key={`${id}StripPlotLine${idx}`}
				className={`stripPlotLine${v === '0' ? ' off' : ''}${
					v === '1' ? ' on' : ''
				}`}
				x={x1}
				y={bottom + hoverHeight}
				width={width}
				height={plotHeight - bottom - top}
				textid={`${id}StripPlotText${idx}`}
			/>
		);
	}

	stripText({ data, xScale, line: { t, v }, idx, hoverHeight }) {
		const { id, maxDate, newestDate, plotHeight } = this.props;

		const d1 =
			idx < data.length - 1
				? moment.utc(t)
				: data[idx].v === data[idx - 1].v
				? moment.utc(data[idx - 1].t)
				: moment.utc(t);

		//find max date on the plot considering desired max date
		const maxPlotDate =
			moment.utc(newestDate).diff(maxDate, 'days') > 0
				? maxDate
				: maxDate.clone().subtract(1, 'd');

		//if last reading was on maxDate (<1), label d2 with maxDate, else if it was earlier (>=1), label d2 with maxDate-1 or maxDate (depending on maxDate which could be < newestDate)
		const d2 =
			idx < data.length - 1
				? moment.utc(data[idx + 1].t).subtract(1, 'd')
				: maxDate.diff(data[idx].t, 'days') < 1
				? maxDate
				: maxPlotDate;
		const vd1 = d1.format('DD/MM');
		const vd2 = d2.format('DD/MM');

		const x1 =
			maxDate.diff(d1, 'days') < 150
				? xScale(jsDate(d1.subtract(165, 'days')))
				: xScale(jsDate(d1.add(5, 'days')));

		let vOut = 'unknown';
		if (v === '1') {
			vOut = 'active';
		} else if (v === '0') {
			vOut = 'inactive';
		}
		const label = `${vd1}-${vd2}: ${vOut}`;

		if (idx === data.length - 2 && data[idx].v === data[idx + 1].v) {
			return null;
		}

		return (
			<text
				id={`${id}StripPlotText${idx}`}
				key={`${id}StripPlotText${idx}`}
				className="stripPlotText"
				x={x1}
				y={plotHeight + hoverHeight - 5}
			>
				{label}
			</text>
		);
	}

	componentDidUpdate() {
		const { plotHeight, hoverHeight } = this.props;
		//it is safe to execute the following lines without any condition, since there is no state to be updated
		d3SelectAll('rect')
			.on('mouseover', function () {
				let rect = d3Select(this);
				rect
					.style('height', `${plotHeight + hoverHeight}px`)
					.attr('transform', `translate(0,-${hoverHeight})`);
				d3Select(`text#${rect.attr('textid')}`).style('display', 'block');
			})
			.on('mouseout', function () {
				let rect = d3Select(this);
				rect
					.style('height', `${plotHeight}px`)
					.attr('transform', `translate(0,0)`);
				d3Select(`text#${rect.attr('textid')}`).style('display', 'none');
			});
	}

	render() {
		let {
			minWidth,
			parentWidth,
			plotHeight,
			axisHeight,
			tickHeight,
			hoverHeight,
			margin,
			data,
			maxDate,
			className = ''
		} = this.props;
		const width = Math.max(parentWidth, minWidth);
		if (!data || data.length === 0) {
			return null;
		}
		const xScale = scaleTime()
			.domain([new Date(data[0].t), jsDate(maxDate)])
			.range([margin.left, width - margin.right]);

		return (
			<div className={`stripPlot ${className}`}>
				<svg width={width} height={plotHeight + 32 + hoverHeight}>
					{data.map((line, idx) =>
						this.stripRect({ data, xScale, line, idx, hoverHeight })
					)}
					{data.map((line, idx) =>
						this.stripText({ data, xScale, line, idx, hoverHeight })
					)}
					<Axes
						scales={{ xScale }}
						dimensions={{ width, axisHeight, tickHeight, hoverHeight }}
						margin={margin}
					/>
				</svg>
			</div>
		);
	}
}

export default ResponsiveWrapper(StripPlot);
