const chartStyle = {
	fill: false,
	lineTension: 0.4,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointRadius: 0,
	pointHoverRadius: 3,
	pointBorderWidth: 0
};

let chartOptions = {
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				distribution: 'linear',
				scaleLabel: {
					display: true
					// labelString: 'Time'
				},
				time: {
					displayFormats: {
						week: 'D MMM YY',
						month: 'D MMM YY'
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				scaleLabel: {
					display: true,
					labelString: 'Inactive Capacity'
				},
				ticks: {
					beginAtZero: false
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20,
			filter: (item) => {
				return !item.text.includes('Max') && !item.text.includes('Min');
			}
		}
	}
};

const freeChartStyle = {
	...chartStyle,
	pointHoverRadius: 2.5,
	pointRadius: 2.5,
	pointHitRadius: 2.5
};

export { chartStyle, chartOptions, freeChartStyle };
