import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const PeriodButtonGroup = ({ groupClass, ids, period, disabled, onClick }) => {
	return (
		<ButtonGroup className={groupClass}>
			<Button
				id={ids[0]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={period === 'month'}
				aria-label={'Monthly'}
				className="light-active-border"
				disabled={disabled ? disabled : false}
			>
				Monthly
			</Button>
			<Button
				id={ids[1]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={period === 'quarter'}
				aria-label={'Quarterly'}
				className="light-active-border"
				disabled={disabled ? disabled : false}
			>
				Quarterly
			</Button>
			<Button
				id={ids[2]}
				outline
				size="sm"
				color="secondary"
				onClick={onClick}
				active={period === 'year'}
				aria-label={'Annual'}
				className="light-active-border"
				disabled={disabled ? disabled : false}
			>
				Annual
			</Button>
		</ButtonGroup>
	);
};

export default PeriodButtonGroup;
