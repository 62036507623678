import { FETCH_NEWEST_DATE, RESET_NEWEST_DATE } from '../actions';

export default (state = '', action) => {
	switch (action.type) {
		case FETCH_NEWEST_DATE:
			return action.payload;
		case RESET_NEWEST_DATE:
			return '';
		default:
			return state;
	}
};
